export default {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  SWITCH: 'switch',
  DATETIME: 'datetime',
  NUMBER: 'number',
  LIST: 'list',
  SELECT: 'select',
  MSELECT: 'mselect',
  COLORPICKER: 'colorpicker',
  DIVIDER: 'divider',
  TITLE: 'title',
};
