<template>
  <n-form
    :model="model"
    ref="formRef"
    :rules="rules" class="md:w-2/3 mt-20 mx-auto border border-gray-20 p-5 rounded">
    <h3 class="text-center">{{ isUpdate ? 'Update' : 'Create'}}
      <strong class="theme-color" v-if="isUpdate">{{ formTemplate.title }}</strong>
    Template</h3>
    <n-form-item path="title" label="Title">
      <n-input
        v-model:value="model.title"
        :show-require-mark="true"
        placeholder=""/>
    </n-form-item>
    <n-form-item path="title" label="Form">
      <n-select
        :options="formOptions"
        v-model:value="model.formId"
        :show-require-mark="true"
        placeholder=""
        filterable/>
    </n-form-item>
    <n-form-item path="industries" label="Industry">
      <n-select
        :options="formTemplateIndustriesOptions"
        v-model:value="model.industries"
        :show-require-mark="true"
        placeholder=""
        filterable
        multiple/>
    </n-form-item>
    <n-form-item path="categories" label="Category">
      <n-select
        :options="formTemplateCategoriesOptions"
        v-model:value="model.categories"
        :show-require-mark="true"
        placeholder=""
        filterable
        multiple/>
    </n-form-item>
    <n-form-item path="excerpt" label="Excerpt">
      <n-input
        type="textarea"
        v-model:value="model.excerpt"
        :show-require-mark="true"
        placeholder=""/>
    </n-form-item>
    <n-form-item path="description" label="Description">
      <n-input
        type="textarea"
        v-model:value="model.description"
        :show-require-mark="true"
        placeholder=""/>
    </n-form-item>
    <n-form-item path="thumbnail" label="Thumbnail">
      <n-upload
        :default-file-list="defaultThumbnails"
        :customRequest="customUpload"
        accept="image/*"
        :max="1"
        :on-remove="onThumbnailRemove">
        <n-button>Upload Thumbail</n-button>
      </n-upload>
    </n-form-item>
    <n-form-item path="published" label="Publish this template">
      <n-checkbox
        v-model:checked="model.published"
        :show-require-mark="true"
        label="Published"/>
    </n-form-item>
    <div class="flex justify-center">
      <n-button
        :loading="creatingFormTemplate || updatingFormTemplate"
        size="large"
        @click="submit"
        type="primary">
        {{ isUpdate ? 'Update' : 'Create'}}
      </n-button>
    </div>
  </n-form>
</template>

<script>
import { ref } from 'vue';
import useForm from '@/composables/form/useForm';
import useFormTemplate from '@/composables/form/useFormTemplate';

export default {
  emits: ['created', 'updated'],
  props: {
    formTemplate: {
      type: Object,
      default: null,
    },
    formTemplateIndustries: {
      type: Array,
      default: () => [],
    },
    formTemplateCategories: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const {
      forms,
      loadForms,
    } = useForm();

    const {
      updatingFormTemplate,
      creatingFormTemplate,
      storeFormTemplate,
      updateFormTemplate,
    } = useFormTemplate();

    return {
      updatingFormTemplate,
      creatingFormTemplate,
      storeFormTemplate,
      updateFormTemplate,

      forms,
      loadForms,

      defaultThumbnails: ref([]),
      model: ref({
        id: null,
        title: '',
        formId: '',
        excerpt: '',
        description: '',
        thumbnail: '',
        industries: [],
        categories: [],
        published: 0,
      }),
      rules: ref({
        title: [
          {
            required: true,
            message: 'Title is required',
            trigger: ['input'],
          },
        ],
        formId: [
          {
            required: true,
            message: 'Form is required',
            trigger: ['input'],
          },
        ],
        excerpt: [
          {
            required: true,
            message: 'Excerpt is required',
            trigger: ['input'],
          },
        ],
        description: [
          {
            required: true,
            message: 'Description is required',
            trigger: ['input'],
          },
        ],
        industries: [
          {
            type: 'array',
            min: 1,
            required: true,
            message: 'Assign atleast one industry',
            trigger: ['input'],
          },
        ],
        categories: [
          {
            type: 'array',
            min: 1,
            required: true,
            message: 'Assign atleast one category',
            trigger: ['input'],
          },
        ],
      }),
    };
  },
  mounted() {
    this.loadForms({ params: { page_size: 1000 } });

    if (this.formTemplate) {
      this.model.id = this.formTemplate.id;
      this.model.title = this.formTemplate.title;
      this.model.formId = this.formTemplate.form_id;
      this.model.excerpt = this.formTemplate.excerpt;
      this.model.description = this.formTemplate.description;
      this.model.thumbnail = this.formTemplate.thumbnail?.thumbnail_url || null;
      this.model.industries = this.formTemplate.form_template_industries.map((fti) => fti.id);
      this.model.categories = this.formTemplate.form_template_categories.map((ftc) => ftc.id);
      this.model.published = parseInt(this.formTemplate.published, 10) === 1;
      if (this.model.thumbnail) {
        this.defaultThumbnails.push({
          id: this.formTemplate.thumbnail.id,
          name: this.formTemplate.thumbnail.name,
          status: 'finished',
        });
      }
    }
  },
  methods: {
    customUpload({ file }) {
      this.model.thumbnail = file.file;
    },
    submit() {
      this.$refs.formRef.validate((errors) => {
        if (errors) {
          console.log(errors);
          return;
        }

        const formData = new FormData();
        formData.append('thumbnail', this.model.thumbnail);
        formData.append('title', this.model.title);
        formData.append('form_id', this.model.formId);
        formData.append('excerpt', this.model.excerpt);
        formData.append('description', this.model.description);
        formData.append('industries', this.model.industries.join(','));
        formData.append('categories', this.model.categories.join(','));
        formData.append('published', this.model.published ? 1 : 0);

        if (!this.isUpdate) {
          this.storeFormTemplate({
            data: formData,
          }, (success, response) => {
            if (!success) {
              return;
            }

            this.$emit('created', response);
          });
        } else {
          formData.append('_method', 'put');
          this.updateFormTemplate({
            formTemplateId: this.model.id,
            data: formData,
          }, (success, response) => {
            if (!success) {
              return;
            }

            this.$emit('updated', response);
          });
        }
      });
    },
    onThumbnailRemove() {
      this.model.thumbnail = '';
    },
  },
  computed: {
    isUpdate() {
      return !!this.model.id;
    },
    formOptions() {
      return this.forms.map((f) => ({
        label: f.title,
        value: f.id,
      }));
    },
    formTemplateIndustriesOptions() {
      return this.formTemplateIndustries.map((f) => ({
        label: f.title,
        value: f.id,
      }));
    },
    formTemplateCategoriesOptions() {
      return this.formTemplateCategories.map((f) => ({
        label: f.title,
        value: f.id,
      }));
    },
  },
};
</script>
