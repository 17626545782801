import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import elementTypes from '@/shared/constants/formbuilder/elementTypes';
import formQuizLogicOperators from '@/shared/constants/formQuizLogicOperators';

export default function () {
  const store = useStore();

  // REFS
  const loadingFormStepQuiz = ref(false);
  const loadingFormStepQuizErrMsg = ref('');
  const savingFormStepQuiz = ref(false);
  const savingFormStepQuizErrMsg = ref('');

  // COMPUTED
  const formStepQuiz = computed(() => store.getters['formStepQuiz/formStepQuiz']);
  const formStepItemOptions = computed(() => store.getters['formStepQuiz/formStepItemOptions']);

  // METHODS
  const loadFormStepQuiz = (data, callback) => {
    loadingFormStepQuiz.value = true;

    store.dispatch('formStepQuiz/getFormStepQuiz', data).then((response) => {
      loadingFormStepQuiz.value = false;
      store.dispatch('formStepQuiz/setFormStepQuiz', response.data).then(() => {
        if (callback) {
          callback(true);
        }
      });
    }).catch((error) => {
      if (callback) {
        callback(false);
      }
      loadingFormStepQuiz.value = false;
      loadingFormStepQuizErrMsg.value = error.response.data.meta.error_message;
    });
  };

  const saveFormStepQuiz = (data, callback) => {
    savingFormStepQuiz.value = true;

    store.dispatch('formStepQuiz/saveFormStepQuiz', data).then((response) => {
      savingFormStepQuiz.value = false;
      if (callback) {
        callback(true, response);
      }
    }).catch((error) => {
      console.log(error);
      if (callback) {
        callback(false, error);
      }
      savingFormStepQuiz.value = false;
      savingFormStepQuizErrMsg.value = error.response.data.meta.error_message;
    });
  };

  const addFormStepQuiz = (logic) => store.dispatch('formStepQuiz/addFormStepQuiz', logic);
  const addFormStepQuizCondition = (condition) => store.dispatch('formStepQuiz/addFormStepQuizCondition', condition);
  const updateFormStepQuizTermField = (term) => store.dispatch('formStepQuiz/updateFormStepQuizTermField', term);
  const updateFormStepQuizPoints = (data) => store.dispatch('formStepQuiz/updateFormStepQuizPoints', data);
  const updateFormStepQuizAction = (data) => store.dispatch('formStepQuiz/updateFormStepQuizAction', data);
  const deleteFormStepQuiz = (data) => store.dispatch('formStepQuiz/deleteFormStepQuiz', data);
  const deleteFormStepQuizConditionTerm = (data) => store.dispatch(
    'formStepQuiz/deleteFormStepQuizConditionTerm',
    data,
  );
  const deleteFormStepQuizConditionTermChoice = (data) => store.dispatch(
    'formStepQuiz/deleteFormStepQuizConditionTermChoice',
    data,
  );
  const getFormStepItemTypeOperators = (itemType) => {
    if (!itemType) {
      return [];
    }

    const options = {
      0: {
        label: 'Equal',
        value: formQuizLogicOperators.EQUAL,
      },
      1: {
        label: 'Not Equal',
        value: formQuizLogicOperators.NOT_EQUAL,
      },
      2: {
        label: 'Contains',
        value: formQuizLogicOperators.CONTAINS,
      },
      3: {
        label: 'Not Contains',
        value: formQuizLogicOperators.NOT_CONTAINS,
      },
      4: {
        label: '>',
        value: formQuizLogicOperators.GREATER_THAN,
      },
      5: {
        label: '>=',
        value: formQuizLogicOperators.GREATER_OR_EQUAL,
      },
      6: {
        label: '<',
        value: formQuizLogicOperators.LESS_THAN,
      },
      7: {
        label: '<=',
        value: formQuizLogicOperators.LESS_OR_EQUAL,
      },
    };

    return {
      [elementTypes.SHORT_TEXT]: [0, 1, 2, 3].map((i) => options[i]),
      [elementTypes.LONG_TEXT]: [0, 1, 2, 3].map((i) => options[i]),
      [elementTypes.EMAIL_ADDRESS]: [0, 1, 2, 3].map((i) => options[i]),
      [elementTypes.PHONE_NUMBER]: [0, 1, 2, 3].map((i) => options[i]),
      [elementTypes.SINGLE_SELECT]: [],
      [elementTypes.MULTI_SELECT]: [],
      [elementTypes.DATETIME]: [0, 1, 4, 5, 6, 7].map((i) => options[i]),
      [elementTypes.RATING]: [0, 1, 4, 5, 6, 7].map((i) => options[i]),
    }[itemType];
  };

  return {
    loadingFormStepQuiz,
    loadingFormStepQuizErrMsg,
    savingFormStepQuiz,
    savingFormStepQuizErrMsg,

    formStepQuiz,
    formStepItemOptions,

    loadFormStepQuiz,
    saveFormStepQuiz,
    getFormStepItemTypeOperators,
    addFormStepQuiz,
    addFormStepQuizCondition,
    updateFormStepQuizTermField,
    updateFormStepQuizPoints,
    updateFormStepQuizAction,
    deleteFormStepQuiz,
    deleteFormStepQuizConditionTerm,
    deleteFormStepQuizConditionTermChoice,
  };
}
