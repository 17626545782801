import { computed } from 'vue';
import { useStore } from 'vuex';

export default function () {
  const store = useStore();

  // COMPUTED
  const formTemplateCategories = computed(() => store.getters['formTemplateCategory/formTemplateCategories']);

  // METHODS
  const setFormTemplateCategories = (data) => store.dispatch('formTemplateCategory/setFormTemplateCategories', data);

  const getFormTemplateCategories = (data, callback) => {
    store.dispatch('formTemplateCategory/getFormTemplateCategories', data).then((response) => {
      console.log(response);
      if (callback) {
        callback(true, response);
      }
    }).catch((error) => {
      console.log(error);
      if (callback) {
        callback(false, error);
      }
    });
  };

  return {
    formTemplateCategories,

    getFormTemplateCategories,
    setFormTemplateCategories,
  };
}
