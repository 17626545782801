export default {
  login: 'login',
  register: 'register',
  forgotPassword: {
    sendPasswordLink: 'forgotPassword.sendPasswordLink',
    resetPassword: 'forgotPassword.resetPassword',
  },
  emailVerification: {
    resend: 'resend',
    success: 'success',
    error: 'error',
  },

  dashboard: 'dashboard',

  form: {
    create: 'form.create',
    edit: 'form.edit',
    index: 'form.index',
    integrations: {
      index: 'form.integrations.index',
      create: 'form.integrations.create',
      edit: 'form.integrations.edit',
      select: 'form.integrations.select',
    },
  },

  formresult: {
    index: 'formresult.index',
  },

  formlogic: {
    index: 'formlogic.index',
  },

  formquiz: {
    index: 'formquiz.index',
  },

  formIntegrations: {
    index: 'form.integrations.index',
  },

  user: {
    index: 'user.index',
  },

  plan: {
    upgrade: 'plan.upgrade',
  },

  customer: {
    index: 'customer.index',
  },

  zapier: {
    index: 'zapier.index',
    apiDocs: 'zapier.apidocs',
  },

  templateBuilder: {
    index: 'templates.index',
    create: 'templates.create',
    edit: 'templates.edit',
  },

  formTemplateChooser: {
    index: 'formTemplateChooser.index',
  },
};
