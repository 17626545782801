<template>
  <div class="dashboard-form-leads-cpt">
    <div class="dashboard-form-leads-cpt__header">
      <div class="dashboard-form-leads-cpt__header__content">
        <h3>Recent Leads</h3>
      </div>
    </div>
    <div class="dashboard-form-leads-cpt__body">
      <div class="dashboard-form-leads-cpt__table">
        <n-data-table
          remote
          :loading="loadingFormLeads"
          ref="table"
          :columns="tableColumns"
          :data="tableData"
          :pagination="tablePagination"
          @update:page="handlePageChange"
          @update:page-size="handlePageSizeChange"
        />
        <div v-if="selectedLead">
          <div>
              <div
              v-for="formLeadResponse in selectedLead.formLeadResponses"
              :key="formLeadResponse.id">
              <div>
                {{getControlValue(formLeadResponse.form_step_item.settings.controls, 'label')}}
              </div>
              <div>{{formLeadResponse.response}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-form-leads-cpt__footer">
    </div>
  </div>
</template>

<script>
import { h } from 'vue';
import { NButton } from 'naive-ui';
import dayjs from 'dayjs';
import dayjsRelativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';
// import {
//   OpenOutline,
// } from '@vicons/ionicons5';

// COMPOSABLES
import useFormLead from '@/composables/form/useFormLead';
import useGlobals from '@/composables/useGlobals';

dayjs.extend(dayjsRelativeTime);

export default {
  setup() {
    const {
      routeNames,
    } = useGlobals();

    const {
      loadingFormLeads,
      formLeads,
      formLeadsPagination,
      loadFormLeads,
    } = useFormLead();

    return {
      formLeads,
      formLeadsPagination,
      loadingFormLeads,
      loadFormLeads,

      routeNames,
    };
  },
  mounted() {
    this.loadFormLeads();
  },
  methods: {
    onRowActionClick(action, row) {
      switch (action) {
        case 'view':
          this.$router.push({
            name: this.routeNames.formresult.index,
            params: {
              formId: row.form_id,
              leadId: row.id,
            },
          });
          break;
        default:
      }
    },
    handlePageChange(page) {
      this.loadFormLeads({ params: { page } });
    },
    handlePageSizeChange(pageSize) {
      this.loadFormLeads({ params: { page_size: pageSize } });
    },
    getControlValue(controls, name, defaultValue = '') {
      return _.find(controls, { name })?.value || defaultValue;
    },
  },
  computed: {
    selectedLead() {
      return null;
    },
    tableData() {
      return this.formLeads.map((formLead) => ({
        ...formLead,
        key: formLead.id,
      }));
    },
    tablePagination() {
      return {
        page: this.formLeadsPagination ? this.formLeadsPagination.current_page : 1,
        pageSize: this.formLeadsPagination ? Number(this.formLeadsPagination.per_page) : 10,
        showSizePicker: true,
        pageSizes: [10, 50, 100],
        pageCount: this.formLeadsPagination ? this.formLeadsPagination.last_page : 0,
        itemCount: this.formLeadsPagination ? this.formLeadsPagination.total : 0,
      };
    },
    tableColumns() {
      const self = this;

      return [
        {
          title: 'Form',
          minWidth: 120,
          render(row) {
            return row.form.title;
          },
        },
        {
          title: 'Received At',
          render(row) {
            return h(
              'div',
              {},
              [
                h('div', {}, dayjs(row.created_at).fromNow()),
                h('div', {}, h('small', {}, dayjs(row.created_at).format('D MMM YYYY'))),
              ],
            );
          },
        },
        {
          title: 'Browser',
          render(row) {
            return row.form_visit.browser;
          },
        },
        {
          title: 'Operating System',
          render(row) {
            return row.form_visit.os;
          },
        },
        {
          title: 'Device',
          render(row) {
            return row.form_visit.device_type;
          },
        },
        {
          title: 'Country',
          render(row) {
            return `${row.form_visit.country || ''} (${row.form_visit.country_code})`;
          },
        },
        {
          title: 'Source',
          minWidth: 350,
          render(row) {
            return row.form_visit.source_url;
          },
        },
        {
          title: 'Actions',
          render(row) {
            return h(
              'div',
              { class: 'row-actions' },
              [
                h(
                  NButton,
                  {
                    size: 'small',
                    onClick: () => self.onRowActionClick('view', row),
                    title: 'Preview',
                    style: 'margin-right: 5px;',
                  },
                  {
                    default: () => 'View',
                  },
                ),
              ],
            );
          },
        },
      ];
    },
  },
};
</script>
