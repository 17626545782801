export default {
  SHORT_TEXT: 'short_text',
  LONG_TEXT: 'long_text',
  EMAIL_ADDRESS: 'email_address',
  PHONE_NUMBER: 'phone_number',
  SINGLE_SELECT: 'single_select',
  MULTI_SELECT: 'multi_select',
  DATETIME: 'datetime',
  RATING: 'rating',
  ADDRESS: 'address',
  TEXT_EDITOR: 'text_editor',
};
