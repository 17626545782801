import FormIntegrationApi from '@/api/formIntegration';

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  actions: {
    getFormIntegrations(_, data) {
      return FormIntegrationApi.index(data);
    },
    saveFormIntegration(_, data) {
      return FormIntegrationApi.store(data);
    },
    getFormIntegration(_, data) {
      return FormIntegrationApi.show(data);
    },
    updateFormIntegration(_, data) {
      return FormIntegrationApi.update(data);
    },
  },
  mutations: {
  },
};
