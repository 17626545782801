import lodash from 'lodash';
import FormStepLogicApi from '@/api/form/formStepLogic';

export default {
  namespaced: true,
  state: {
    formStepLogic: null,
  },
  getters: {
    formStepLogic(state) {
      return state.formStepLogic || [];
    },
    formStepItemOptions(state) {
      const options = [];

      if (!state.formStepLogic) {
        return options;
      }

      state.formStepLogic.form_steps.forEach((step) => {
        step.form_step_items.forEach((stepItem) => {
          options.push({
            label: lodash.find(stepItem.config.settings.controls, { name: 'label' }).value,
            value: stepItem.id,
            stepId: step.id,
          });
        });
      });

      return options;
    },
  },
  actions: {
    getFormStepLogic(_, data) {
      return FormStepLogicApi.show(data);
    },
    saveFormStepLogic(_, data) {
      return FormStepLogicApi.store(data);
    },
    setFormStepLogic(context, data) {
      context.commit('setFormStepLogic', data);
    },
    addFormStepLogic(context, data) {
      context.commit('addFormStepLogic', data);
    },
    deleteFormStepLogic(context, data) {
      context.commit('deleteFormStepLogic', data);
    },
    deleteFormStepLogicConditionTerm(context, data) {
      context.commit('deleteFormStepLogicConditionTerm', data);
    },
    deleteFormStepLogicConditionTermChoice(context, data) {
      context.commit('deleteFormStepLogicConditionTermChoice', data);
    },
    addFormStepLogicCondition(context, data) {
      context.commit('addFormStepLogicCondition', data);
    },
    updateFormStepLogicTermField(context, data) {
      context.commit('updateFormStepLogicTermField', data);
    },
    updateFormStepLogicStep(context, data) {
      context.commit('updateFormStepLogicStep', data);
    },
  },
  mutations: {
    setFormStepLogic(state, data) {
      state.formStepLogic = data.result;
    },
    addFormStepLogic(state, data) {
      const formStep = lodash.find(state.formStepLogic.form_steps, { id: data.form_step_id });
      formStep.form_step_logics.push(data);
    },
    deleteFormStepLogic(state, data) {
      const formStep = lodash.find(state.formStepLogic.form_steps, { id: data.logic.form_step_id });
      const formStepLogic = formStep.form_step_logics[data.logicIndex];

      if (formStepLogic.id) {
        formStepLogic.deleted = true;
        return;
      }

      formStep.form_step_logics.splice(data.logicIndex, 1);
    },
    deleteFormStepLogicConditionTerm(state, data) {
      const formStep = lodash.find(state.formStepLogic.form_steps, { id: data.logic.form_step_id });
      const formStepLogic = formStep.form_step_logics[data.logicIndex];

      formStepLogic.action_config.condition.terms.splice(data.termIndex, 1);
    },
    deleteFormStepLogicConditionTermChoice(state, data) {
      const formStep = lodash.find(state.formStepLogic.form_steps, { id: data.logic.form_step_id });
      const formStepLogic = formStep.form_step_logics[data.logicIndex];

      formStepLogic.action_config.condition.terms[data.termIndex].choices
        .splice(data.termChoiceIndex, 1);
    },
    updateFormStepLogicStep(state, data) {
      const formStep = lodash.find(state.formStepLogic.form_steps, { id: data.logic.form_step_id });
      const formStepLogic = formStep.form_step_logics[data.logicIndex];
      formStepLogic.action_config.jump_to_step = data.formStepId;
    },
    addFormStepLogicCondition(state, data) {
      const formStep = lodash.find(state.formStepLogic.form_steps, { id: data.logic.form_step_id });
      const formStepLogic = formStep.form_step_logics[data.logicIndex];
      formStepLogic.action_config.condition.terms.push(data.term);
    },
    updateFormStepLogicTermField(state, data) {
      const formStep = lodash.find(state.formStepLogic.form_steps, { id: data.logic.form_step_id });
      const formStepLogic = formStep.form_step_logics[data.logicIndex];
      const term = formStepLogic.action_config.condition.terms[data.termIndex];

      if (data.fieldName === 'logicOperator') {
        formStepLogic.action_config.condition.terms.forEach((t) => {
          // eslint-disable-next-line no-param-reassign
          t[data.fieldName] = data.fieldValue;
        });

        return;
      }

      term.value = null;
      term.choices = [];
      term[data.fieldName] = data.fieldValue;
    },
  },
};
