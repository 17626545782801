export default {
  free: {
    slug: 'free',
    price: 0,
  },
  paddlePlus: {
    slug: 'paddle_plus',
    paddleId: process.env.VUE_APP_PADDLE_PLUS_PLAN_ID,
    price: 49,
  },
  paddleBusiness: {
    slug: 'paddle_business',
    paddleId: process.env.VUE_APP_PADDLE_BUSINESS_PLAN_ID,
    price: 99,
  },
};
