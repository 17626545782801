<template>
  <div class="email-verification-error-view">
    <n-result
      status="error"
      title="Error"
      description="Unable to verify your email, try to resend again.">
      <template #footer>
        <n-button
          @click="$router.push({name: routeNames.emailVerification.resend})">
          Resend Verification
        </n-button>
      </template>
    </n-result>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['routeNames']),
  },
};
</script>
