<template>
  <div class="form-detail-layout">
    <div class="form-detail-layout__header">
      <n-breadcrumb>
        <n-breadcrumb-item @click="$router.push({name: routeNames.form.index})">
          <n-icon><arrow-back-outline /></n-icon> Back To Forms
        </n-breadcrumb-item>
      </n-breadcrumb>
      <div class="form-detail-layout__header__actions">
        <n-button
          type="primary"
          @click="goToBuilderPage"
          :ghost="routeNames.form.create !== currentRouteName">
          Builder
        </n-button>
        <n-button
          type="primary"
          :ghost="routeNames.formlogic.index !== currentRouteName"
          @click="goToLogicPage"
          v-if="enableLogic">
          Logic
        </n-button>
        <n-button
          type="primary"
          :ghost="routeNames.formquiz.index !== currentRouteName"
          @click="goToQuizPage"
          v-if="enableQuiz">
          Quiz
        </n-button>
        <n-button
          type="primary"
          :ghost="routeNames.formresult.index !== currentRouteName"
          @click="goToResponsesPage">
          Responses
        </n-button>
        <n-button
          type="primary"
          @click="goToIntegrationsPage"
          :ghost="routeNames.formIntegrations.index !== currentRouteName">
          Integrate
        </n-button>
        <n-button type="primary" @click="showEmbedModal" ghost>
          Embed
        </n-button>
      </div>
    </div>
    <div class="form-detail-layout__body">
      <router-view></router-view>
    </div>
    <!-- MODALS -->
    <embed-form-modal
      :show="embedModel.show"
      :form-id="embedModel.formId"
      @show="onEmbedModalShowChange"/>
  </div>
</template>

<script>
import { ref } from 'vue';
import _ from 'lodash';
import {
  ArrowBackOutline,
} from '@vicons/ionicons5';

// COMPOSABLES
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';

// COMPONENTS
import useGlobals from '@/composables/useGlobals';
import useAuth from '@/composables/useAuth';

// MODALS
import EmbedFormModal from '@/components/Form/EmbedFormModal.vue';

export default {
  setup() {
    const { routeNames } = useGlobals();
    const { formBuilder, focusEntity } = useFormBuilder();

    const {
      hasConditionalLogicFeature,
      hasQuizFeature,
      toggleUpgradePlanModal,
    } = useAuth();

    return {
      formBuilder,
      focusEntity,

      routeNames,

      hasConditionalLogicFeature,
      hasQuizFeature,
      toggleUpgradePlanModal,
    };
  },
  data() {
    return {
      rightsidebarTab: 'appearance',

      embedModel: ref({
        show: false,
        formId: '',
      }),
    };
  },
  components: {
    ArrowBackOutline,
    EmbedFormModal,
  },
  methods: {
    goToIntegrationsPage() {
      this.$router.push({
        name: this.routeNames.form.integrations.index,
        params: { id: this.$route.params.formId },
      });
    },
    goToBuilderPage() {
      this.$router.push({
        name: this.routeNames.form.edit,
        params: { id: this.$route.params.formId },
      });
    },
    goToResponsesPage() {
      this.$router.push({
        name: this.routeNames.formresult.index,
        params: { id: this.$route.params.formId },
      });
    },
    goToLogicPage() {
      if (!this.hasConditionalLogicFeature()) {
        this.toggleUpgradePlanModal({ show: true });

        return;
      }

      this.$router.push({
        name: this.routeNames.formlogic.index,
        params: { id: this.$route.params.formId },
      });
    },
    goToQuizPage() {
      if (!this.hasQuizFeature()) {
        this.toggleUpgradePlanModal({ show: true });

        return;
      }

      this.$router.push({
        name: this.routeNames.formquiz.index,
        params: { formId: this.$route.params.formId },
      });
    },
    showEmbedModal() {
      this.embedModel.formId = this.$route.params.formId;
      this.embedModel.show = true;
    },
    onEmbedModalShowChange(value) {
      this.embedModel.show = value;
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    enableQuiz() {
      if (!this.formBuilder) {
        return false;
      }

      return _.find(this.formBuilder.general.settings.controls, { name: 'enable_quiz' })?.value;
    },
    enableLogic() {
      if (!this.formBuilder) {
        return false;
      }

      return _.find(this.formBuilder.general.settings.controls, { name: 'enable_conditional_logic' })?.value;
    },
  },
};
</script>
