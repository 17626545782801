import dashboardApi from '@/api/dashboard';

export default {
  namespaced: true,
  state: {
    overview: null,
  },
  getters: {
    overview(state) {
      return state.overview;
    },
  },
  actions: {
    getOverview(context, data) {
      return dashboardApi.getOverview(data);
    },
    setOverview(context, data) {
      context.commit('setOverview', data);
    },
  },
  mutations: {
    setOverview(state, data) {
      state.overview = data;
    },
  },
};
