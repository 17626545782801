<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <div
      class="formbuilder__preview"
      :style="{'--previewThemeColor': getThemeControlValue('theme', '#46c3c3')}">
      <div class="formbuilder__preview__main">
        <div class="formbuilder__preview_body">
          <form-step
            v-for="(step, index) in sortedSteps"
            :key="step.id"
            :step="step"
            :step-index="index"/>
          <div v-for="ending in endings" :key="ending.id">
            <form-thankyou-message
              :endings-control="endingsControl"
              :endings-control-item="ending"
              v-if="getEndingControl(ending, 'action').value === 'thankyou_message'"
            />
          </div>
        </div>
      </div>
    </div>
  </n-config-provider>
</template>

<script>
import _ from 'lodash';

// COMPOSABLES
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';

// COMPONENTS
import FormStep from '@/components/FormBuilder/Preview/FormStep/FormStep.vue';
import FormThankyouMessage from './FormThankyouMessage/FormThankyouMessage.vue';

// CONSTANTS
import fonts from '@/shared/constants/fonts';

export default {
  setup() {
    const {
      formBuilder,
      getThemeControlValue,
    } = useFormBuilder();

    return {
      formBuilder,
      getThemeControlValue,
    };
  },
  components: {
    FormStep,
    FormThankyouMessage,
  },
  mounted() {
    this.applyThemeStyles();
  },
  methods: {
    applyThemeStyles() {
      const themeFont = fonts[this.getThemeControlValue('font', 'lato')];
      this.applyThemeFont(this.getThemeControlValue('font', 'lato'), themeFont);

      const themeColor = this.getThemeControlValue('fontColor');

      const style = `
        .formbuilder__content__body {
          background: ${this.getThemeControlValue('canvasBgColor')};
          height: 100%;
        }

        .formbuilder__preview__step {
          background: ${this.getThemeControlValue('formBgColor')};
          border-width: ${this.getThemeControlValue('formBorderWidth')}px;
          border-style: ${this.getThemeControlValue('formBorderStyle')};
          border-color: ${this.getThemeControlValue('formBorderColor')};
          border-radius: ${this.getThemeControlValue('formBorderRadius')}px;
        }

        .formbuilder__preview__step .ql-editor h1,
        .formbuilder__preview__step .ql-editor h2,
        .formbuilder__preview__step .ql-editor h3 {
          font-weight: 700;
        }

        .formbuilder__preview__step .ql-editor h4,
        .formbuilder__preview__step .ql-editor h5,
        .formbuilder__preview__step .ql-editor h6 {
          font-weight: 400;
        }

        .formbuilder__preview__step .ql-editor * {
          font-family: ${themeFont.name};
        }

        .formbuilder__preview__step .ql-editor img {
          display: inline-block;
        }

        .formbuilder__preview__step .n-input__placeholder,
        .formbuilder__preview__step .n-base-selection-placeholder  {
          font-family: ${themeFont.name};
        }

        .formbuilder__preview__step-item {
          background: ${this.getThemeControlValue('formBgColor')};
        }

        .formbuilder__preview__step-item__item .n-form-item-label {
          font-family: ${themeFont.name};
          color: ${themeColor};
        }

        .formbuilder__preview__step-item__item__desc {
          font-family: ${themeFont.name};
          color: ${themeColor};
        }

        .multi-select-step-item .n-checkbox__label,
        .single-select-step-item .n-radio__label {
          font-family: ${themeFont.name};
          color: ${themeColor};
        }

        .formbuilder__preview .n-form-item-label {
          font-size: 17px;
          font-weight: 700;
        }

        ${this.getStepNavBtnStyles('.formbuilder__preview__step__navigation__back', 'stepNavBack')}
        ${this.getStepNavBtnStyles('.formbuilder__preview__step__navigation__continue', 'stepNavContinue')}
        ${this.getStepNavBtnStyles('.formbuilder__preview__step__navigation__submit', 'stepNavSubmit')}
        ${this.getThankyouMsgStyles('.formbuilder__preview__thankyou-msg')}
      `;

      const styleTagExisting = document.querySelector('#form-preview-styles');
      if (styleTagExisting) {
        styleTagExisting.remove();
      }

      const styleTag = document.createElement('style');
      styleTag.innerHTML = style;
      styleTag.setAttribute('id', 'form-preview-styles');
      document.head.appendChild(styleTag);
    },
    getStepNavBtnStyles(wrapper, fieldPrefix) {
      return `
        ${wrapper} .n-button  {
          background: ${this.getThemeControlValue(`${fieldPrefix}BtnBgColor`)};
          color: ${this.getThemeControlValue(`${fieldPrefix}BtnTextColor`)};
          border-radius: ${this.getThemeControlValue(`${fieldPrefix}BtnBorderRadius`)}px;
        }
        ${wrapper} .n-button__border {
          border-width: ${this.getThemeControlValue(`${fieldPrefix}BtnBorderWidth`)}px;
          border-style: ${this.getThemeControlValue(`${fieldPrefix}BtnBorderStyle`)};
          border-color: ${this.getThemeControlValue(`${fieldPrefix}BtnBorderColor`)};
          border-radius: ${this.getThemeControlValue(`${fieldPrefix}BtnBorderRadius`)}px;
        }

        ${wrapper} .n-button:hover .n-button__border {
          border-color: ${this.getThemeControlValue(`${fieldPrefix}BtnHoverBorderColor`)};
        }
        ${wrapper} .n-button:hover {
          background: ${this.getThemeControlValue(`${fieldPrefix}BtnHoverBgColor`)};
          color: ${this.getThemeControlValue(`${fieldPrefix}BtnHoverTextColor`)};
        }
      `;
    },
    getContinueBtnStyles() {
      return `
        .formbuilder__preview__step__navigation__continue .n-button  {
          background: ${this.getThemeControlValue('stepNavContinueBtnBgColor')};
          color: ${this.getThemeControlValue('stepNavContinueBtnTextColor')};
        }
        .formbuilder__preview__step__navigation__continue .n-button__border {
          border-width: ${this.getThemeControlValue('stepNavContinueBtnBorderWidth')}px;
          border-style: ${this.getThemeControlValue('stepNavContinueBtnBorderStyle')};
          border-color: ${this.getThemeControlValue('stepNavContinueBtnBorderColor')};
          border-radius: ${this.getThemeControlValue('stepNavContinueBtnBorderRadius')}px;
        }

        .formbuilder__preview__step__navigation__continue .n-button:hover .n-button__border {
          border-color: ${this.getThemeControlValue('stepNavContinueBtnHoverBorderColor')};
        }
        .formbuilder__preview__step__navigation__continue .n-button:hover {
          background: ${this.getThemeControlValue('stepNavContinueBtnHoverBgColor')};
          color: ${this.getThemeControlValue('stepNavContinueBtnHoverTextColor')};
        }
      `;
    },
    getThankyouMsgStyles(wrapper) {
      return `
        ${wrapper} .ql-editor * {
          color: ${this.getThemeControlValue('thankyouMsgTextColor')};
        }
      `;
    },
    applyThemeFont(name, font) {
      const alreadyInserted = document.querySelector(`#font-${name}`);
      if (alreadyInserted) {
        return;
      }

      const newlink = document.createElement('link');
      newlink.setAttribute('id', `font-${name}`);
      newlink.setAttribute('href', font.link);
      newlink.setAttribute('rel', 'stylesheet');
      document.head.appendChild(newlink);
    },
    getEndingControl(ending, name) {
      return _.find(ending.controls, { name });
    },
  },
  computed: {
    endingsControl() {
      return _.find(
        this.formBuilder.general.settings.controls,
        { name: 'endings' },
      );
    },
    endings() {
      const items = this.endingsControl?.items || [];

      return _.sortBy(items, (e) => e.order);
    },
    theme() {
      return this.formBuilder.theme;
    },
    sortedSteps() {
      return _.sortBy(this.formBuilder.steps, (s) => s.order);
    },
    themeOverrides() {
      const themeColor = this.getThemeControlValue('theme', '#46c3c3');

      return {
        common: {
          primaryColor: themeColor,
          primaryColorHover: themeColor,
          primaryColorPressed: themeColor,
        },
      };
    },
  },
  watch: {
    theme: {
      handler() {
        this.applyThemeStyles();
      },
      deep: true,
    },
  },
};
</script>
