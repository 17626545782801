<template>
  <div class="form-webhook-cpt">
    <div class="form-webhook-cpt__header">
      <div class="container">
        <div class="breadcrumb" @click="goToIntegrationsPage">
          <n-icon><arrow-back-outline /></n-icon> Back to Integrations
        </div>
      </div>
    </div>
    <div class="form-webhook-cpt__body">
      <n-form
        :model="model"
        ref="formWebhookRef"
        :rules="rules" class="md:w-1/2 mx-auto p-5 rounded">
        <n-form-item path="title" label="Title">
          <n-input
            v-model:value="model.title"
            :show-require-mark="true"
            placeholder=""/>
        </n-form-item>
        <n-form-item path="url" label="Webhook URL">
          <n-input
            v-model:value="model.url"
            :show-require-mark="true"
            placeholder=""/>
        </n-form-item>
        <n-form-item label="Active">
          <n-switch
            v-model:value="model.active"
            placeholder=""/>
        </n-form-item>
        <n-collapse>
          <n-collapse-item title="Advance Options" name="advancedOptions">
            <n-form-item path="format" label="Format">
              <n-select
                v-model:value="model.format"
                :show-require-mark="true"
                placeholder=""
                :options="mimeOptions"
                :filterable="true"/>
            </n-form-item>
            <n-form-item path="method" label="Method">
              <n-select
                v-model:value="model.method"
                :show-require-mark="true"
                :clearable="true"
                placeholder=""
                :options="methodOptions"
                :filterable="true"/>
            </n-form-item>
            <!-- FIELD MAPPING -->
            <div class="form-webhook-cpt__fieldmappings">
              <n-divider></n-divider>
              <h3><strong>Field Mapping</strong></h3><br>
              <p>Use field mapping to match thirdparty fields
                if they don't match your form field names.</p><br>
              <div class="form-webhook-cpt__fieldmapping-items">
                <div class="form-webhook-cpt__fieldmapping-item">
                  <div><strong>From Field Name</strong></div>
                  <div><strong>To Field Name</strong></div>
                </div>
                <div
                  class="form-webhook-cpt__fieldmapping-item"
                  v-for="fieldMap in model.fieldsMap" :key="fieldMap.from_field_name">
                  <div>
                    # {{fieldMap.title}} ({{fieldMap.from_field_name}})
                  </div>
                  <div>
                    <n-form-item>
                      <n-input
                        v-model:value="fieldMap.to_field_name"
                        placeholder=""/>
                    </n-form-item>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-webhook-cpt__custom-headers">
              <n-divider></n-divider>
              <h3><strong>Custom Headers</strong></h3><br>
              <p>Send your own custom headers.</p><br>
              <div class="form-webhook-cpt__custom-header-items">
                <div class="form-webhook-cpt__custom-header-item">
                  <div><strong>Header Name</strong></div>
                  <div><strong>Header Value</strong></div>
                  <div></div>
                </div>
                <div
                  class="form-webhook-cpt__custom-header-item"
                  v-for="(header, headerIndex) in model.headers" :key="headerIndex">
                  <div>
                    <n-form-item>
                      <n-input
                        v-model:value="header.name"
                        placeholder=""/>
                    </n-form-item>
                  </div>
                  <div>
                    <n-form-item>
                      <n-input
                        v-model:value="header.value"
                        placeholder=""/>
                    </n-form-item>
                  </div>
                  <div>
                    <n-button
                      @click="() => deleteHeader(headerIndex)"
                      type="error">
                      Delete
                    </n-button>
                  </div>
                </div>
              </div>
              <n-button
                class="mt-3"
                @click="addHeader"
                type="primary"
                ghost>
                Add Header
              </n-button>
            </div>
            <div class="form-webhook-cpt__secret">
              <n-divider></n-divider>
              <h3><strong>Webhook Security</strong></h3><br>
              <p>
                Use Webhook secret key &amp;
                value to verify that webhook is actually sent from leadgen.</p><br>
              <n-form-item path="secret.name" label="Secret Key">
                <n-input
                  v-model:value="model.secret.name"
                  placeholder=""/>
              </n-form-item>
              <n-form-item path="secret.value" label="Secret Value">
                <n-input
                  v-model:value="model.secret.value"
                  placeholder=""/>
              </n-form-item>
            </div>
          </n-collapse-item>
        </n-collapse>
        <div class="flex justify-center">
          <n-button
            :loading="saving"
            size="large"
            @click="submit"
            type="primary">
            {{ isEditMode ? 'Update' : 'Create' }} Webhook
          </n-button>
        </div>
      </n-form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { mapGetters } from 'vuex';
import {
  ArrowBackOutline,
} from '@vicons/ionicons5';
import { useMessage } from 'naive-ui';

// COMPOSABLES
import useForm from '@/composables/form/useForm';
import useFormIntegration from '@/composables/form/useFormIntegration';

// CONSTANTS
import httpVerbs from '@/shared/constants/httpVerbs';
import mimes from '@/shared/constants/mimes';
import formIntegrationTypes from '@/shared/constants/formIntegrationTypes';

export default {
  props: {
    formIntegration: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup() {
    const modelRef = ref({
      title: null,
      url: null,
      active: true,
      format: mimes.JSON,
      method: httpVerbs.POST,
      secret: {
        name: '',
        value: '',
      },
      headers: [],
      fieldsMap: [],
    });

    const {
      getFieldMapping,
    } = useForm();

    const {
      saveFormIntegration,
    } = useFormIntegration();

    return {
      message: useMessage(),

      getFieldMapping,

      saveFormIntegration,

      saving: ref(false),
      model: modelRef,
      rules: {
        title: [
          {
            type: 'string',
            required: true,
            trigger: ['input'],
            message: 'This field is required',
          },
        ],
        url: [
          {
            type: 'string',
            required: true,
            trigger: ['input'],
            message: 'This field is required',
          },
          {
            type: 'url',
            trigger: ['input'],
            message: 'Please enter valid URL',
          },
        ],
        format: [
          {
            required: true,
            trigger: ['input'],
            message: 'This field is required',
          },
        ],
        method: [
          {
            type: 'string',
            required: true,
            trigger: ['input'],
            message: 'This field is required',
          },
        ],
      },
    };
  },
  components: {
    ArrowBackOutline,
  },
  mounted() {
    if (this.isEditMode) {
      this.initializeModel();

      return;
    }

    this.getFieldMapping({
      formId: this.$route.params.formId,
    }, (success, response) => {
      if (!success) {
        return;
      }

      this.model.fieldsMap = response.data.result;
      this.addHeader();
    });
  },
  methods: {
    initializeModel() {
      this.model.title = this.formIntegration.title;
      this.model.active = this.formIntegration.active === 1;
      this.model.url = this.formIntegration.url;
      this.model.format = this.formIntegration.format;
      this.model.method = this.formIntegration.method;
      this.model.headers = this.formIntegration.headers || [];
      this.model.secret = this.formIntegration.secret || this.model.secret;
      this.model.fieldsMap = this.formIntegration.fields_map;
    },
    goToIntegrationsPage() {
      this.$router.push({
        name: this.routeNames.form.integrations.index,
        params: { id: this.$route.params.formId },
      });
    },
    addHeader() {
      this.model.headers.push({
        name: '',
        value: '',
      });
    },
    deleteHeader(headerIndex) {
      this.model.headers = this.model.headers
        .filter((h, i) => (i !== headerIndex));
    },
    submit(e) {
      e.preventDefault();

      this.$refs.formWebhookRef.validate((errors) => {
        if (errors) {
          return;
        }

        this.save();
      });
    },
    save() {
      const data = {
        title: this.model.title,
        url: this.model.url,
        format: this.model.format,
        method: this.model.method,
        headers: this.model.headers,
        secret: this.model.secret,
        fields_map: this.model.fieldsMap,
        active: this.model.active ? 1 : 0,
      };

      this.$refs.formWebhookRef.validate((errors) => {
        if (errors) {
          console.log(errors);
          return;
        }

        this.saving = true;
        this.saveFormIntegration(
          {
            formId: this.$route.params.formId,
            data: {
              type: formIntegrationTypes.webhook,
              payload: data,
            },
            editMode: this.isEditMode,
            integrationId: this.formIntegration?.id,
          },
          (success, response) => {
            this.saving = false;
            if (!success) {
              this.message.error(response.data.meta.error_message || 'Something went wrong!');
              return;
            }

            if (this.isEditMode) {
              this.message.success('Webhook updated successfully!');

              return;
            }

            this.message.success('Webhook created successfully!');

            this.$router.push({
              name: this.routeNames.form.integrations.index,
              params: {
                formId: this.$route.params.formId,
              },
            });
          },
        );
      });
    },
  },
  computed: {
    ...mapGetters([
      'routeNames',
    ]),
    methodOptions() {
      return Object.keys(httpVerbs).map((verb) => ({
        label: verb,
        value: verb,
      }));
    },
    mimeOptions() {
      return Object.keys(mimes).map((mime) => ({
        label: mime,
        value: mimes[mime],
      }));
    },
    isEditMode() {
      return !!this.formIntegration?.id;
    },
  },
};
</script>
