import axios from '@/api/axios';

const signup = (data) => axios.post('/signup', data);

const resendEmailVerification = (data) => axios.post('/email/verification-notification', data.data);

export default {
  signup,
  resendEmailVerification,
};
