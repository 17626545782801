<template>
  <div class="text-editor-step-item" @click="$emit('focused')">
    <quill-editor
      :style="{color: getControlValue('text_color')}"
      :content="getControlValue('content')"
      v-on:focused="onFocus($event)"
      @textChanged="onChange"
      :ref="refId"/>
  </div>
</template>

<script>
import { ref } from 'vue';
import _ from 'lodash';
import QuillEditor from '@/components/QuillEditor/QuillEditor.vue';
// import focusedEntityTypes from '@/shared/constants/formbuilder/focusedEntityTypes';

export default {
  emits: ['focused'],
  props: {
    stepItem: {
      type: Object,
      required: true,
    },
  },
  components: {
    QuillEditor,
  },
  setup() {
    const randId = parseInt((Math.random() * 100000).toFixed(5), 10);

    return {
      refId: ref(`quill-editor-ref-${randId}`),
    };
  },
  methods: {
    getControl(name) {
      return _.find(this.stepItem.settings.controls, { name });
    },
    getControlValue(name, defaultValue = '') {
      return _.find(this.stepItem.settings.controls, { name })?.value || defaultValue;
    },
    onChange() {
      this.editorContent = this.$refs[this.refId].editor.root.innerHTML;

      this.$store.dispatch('formBuilder/updateFocusedEntityControl', {
        panel: 'settingsPanel',
        control: this.getControl('content'),
        value: this.editorContent,
        field: 'value',
      });
    },
    onFocus(e) {
      e.stopPropagation();
      this.$emit('focused', e);
    },
  },
};
</script>
