<template>
  <div class="reset-password-view mt-20">
    <n-card v-if="showSuccessMsg" style="max-width:500px;margin-left: auto;margin-right:auto">
      <n-result
        status="success"
        title="Success"
        description="Please password updated successfully!">
        <template #footer>
          <n-button @click="$router.push({name: routeNames.login})">Log In</n-button>
        </template>
      </n-result>
    </n-card>
    <n-form
      v-else
      :model="model"
      ref="formRef"
      :rules="rules"
      class="reset-password-form">
      <div class="reset-password-form__header">
        <h3>Reset Password</h3>
      </div>
      <n-form-item path="email" label="Email">
        <n-input
          v-model:value="model.email"
          type="email"
          :show-require-mark="true"
          placeholder=""/>
      </n-form-item>
      <n-form-item path="password" label="New Password">
        <n-input
          v-model:value="model.password"
          type="password"
          :show-require-mark="true"
          show-password-on="click"
          :clearable="true"
          placeholder=""/>
      </n-form-item>
      <div class="flex justify-center">
        <n-button
          :loading="sending"
          size="large"
          @click="submit"
          type="primary">
          Send
        </n-button>
      </div>
      <div class="reset-password-form__footer">
        <p>
          <router-link
            :to="{name: routeNames.forgotPassword.sendPasswordLink}"
            class="text-theme text-sm ml-1">Resend Password Link</router-link>
        </p>
      </div>
    </n-form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useMessage } from 'naive-ui';
import { mapGetters } from 'vuex';

export default {
  setup() {
    const formRef = ref(null);
    const message = useMessage();
    const modelRef = ref({
      email: null,
      password: null,
      token: (new URL(window.location.href).searchParams.get('token')),
    });

    return {
      message,
      formRef,
      model: modelRef,
      sending: ref(false),
      showSuccessMsg: ref(false),
      rules: {
        email: [
          {
            required: true,
            trigger: ['input'],
            message: 'Email is required',
          },
          {
            type: 'email',
            trigger: ['input'],
            message: 'Email address is invalid',
          },
        ],
        password: [
          {
            required: true,
            message: 'Password is required',
            trigger: ['input'],
          },
        ],
      },
    };
  },
  methods: {
    submit(e) {
      e.preventDefault();
      this.$refs.formRef.validate((errors) => {
        if (errors) {
          return;
        }

        this.send();
      });
    },
    send() {
      this.sending = true;
      this.$store.dispatch('auth/resetPassword', {
        data: {
          email: this.model.email,
          password: this.model.password,
          password_confirmation: this.model.password,
          token: this.model.token,
        },
      }).then(() => {
        this.sending = false;
        this.showSuccessMsg = true;
      }).catch((error) => {
        this.sending = false;
        this.message.error(
          error.response.data.meta.error_message || 'Something went wrong!',
        );
      });
    },
  },
  computed: {
    ...mapGetters([
      'routeNames',
      'errorCodes',
    ]),
  },
};
</script>
