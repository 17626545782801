<template>
  <div class="form-result-cpt">
    <!-- INBOX VIEW -->
    <div class="form-result-cpt__inbox" v-if="view === 'inbox'">
      <div class="form-result-cpt__inbox__header">
        <div  class="form-result-cpt__inbox__header__filters">
          <n-input type="text" placeholder="Search">
            <template #suffix>
              <n-icon>
                <search-outline />
              </n-icon>
            </template>
          </n-input>
        </div>
        <div  class="form-result-cpt__inbox__header__actions">
          <div class="form-result-cpt__inbox__header__actions__exports">
            <span>Export</span>
            <n-button @click="() => exportLead('csv')">
              CSV
            </n-button>
            <n-button @click="() => exportLead('xlsx')">
              XLSX
            </n-button>
          </div>
          <n-button @click="updateView('table')">
            <template #icon>
              <n-icon>
                <browsers-outline />
              </n-icon>
            </template>
            TABLE VIEW
          </n-button>
        </div>
      </div>
      <div class="form-result-cpt__inbox__body">
        <div class="form-result-cpt__inbox__list">
          <n-data-table
            remote
            :loading="loadingFormLeads"
            ref="table"
            :columns="tableColumns"
            :data="tableData"
            :pagination="tablePagination"
            @update:page="handlePageChange"
            @update:page-size="handlePageSizeChange"
            :row-class-name="(row) => row.id === selectedLead?.id ? 'selected-row' : ''"
          />
        </div>
        <div class="form-result-cpt__inbox__list-detail">
          <n-card title="Lead Details">
            <n-skeleton v-if="loadingFormLeads" text width="60%" />
            <n-skeleton v-if="loadingFormLeads" text :repeat="6" />
            <div v-else-if="selectedLead && !selectedLead.blocked">
              <!-- LEAD RESPONSES -->
              <div>
                <div v-if="selectedLead.quiz_score !== null">
                  <h2 class="theme-color"><strong>Quiz Score</strong></h2>
                  <div>
                    {{ selectedLead.quiz_score }} Points
                  </div><br>
                </div>
                <!-- Response -->
                <h2 class="theme-color"><strong>Response</strong></h2><br>
                <div
                  class="form-result-cpt__inbox__list-items"
                  v-for="formStep in form.form_steps"
                  :key="formStep.id">
                  <div
                    class="form-result-cpt__inbox__list-item"
                    v-for="formStepItem in filterStepItems(formStep.form_step_items)"
                    :key="formStepItem.id">
                    <div class="form-result-cpt__inbox__list-item__title">
                      <strong>
                        {{
                          getControlValue(
                            formStepItem.config.settings.controls,
                            'label'
                          )
                        }}
                      </strong>
                    </div>
                    <div
                      class="form-result-cpt__inbox__list-item__response"
                      v-html="getFormStepItemResponse(selectedLead, formStepItem)">
                    </div>
                  </div>
                </div>
              </div>
              <!-- HIDDEN FIELDS -->
              <div v-if="!selectedLead.blocked">
                <h2 class="theme-color"><strong>Hidden Fields</strong></h2><br>
                <div
                  class="form-result-cpt__inbox__list-items"
                  v-for="formHiddenField in form.form_hidden_fields"
                  :key="formHiddenField.id">
                  <div class="form-result-cpt__inbox__list-item">
                    <div class="form-result-cpt__inbox__list-item__title">
                      <strong>
                        {{
                          formHiddenField.name
                        }}
                      </strong>
                    </div>
                    <div
                      class="form-result-cpt__inbox__list-item__response"
                      v-html="getFormHiddenFieldResponse(selectedLead, formHiddenField)">
                    </div>
                  </div>
                </div>
              </div>
              <!-- META DATA -->
              <div>
                <h2 class="theme-color"><strong>Meta Data</strong></h2><br>
                <div
                  class="form-result-cpt__inbox__list-meta-items"
                  v-for="metaField in metaFields"
                  :key="metaField.id">
                  <div class="form-result-cpt__inbox__list-meta-item">
                    <div class="form-result-cpt__inbox__list-meta-item__title">
                      <strong>{{metaField.title}}</strong>
                    </div>
                    <div class="form-result-cpt__inbox__list-meta-item__response">
                      {{metaField.value}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="selectedLead?.blocked">
              <n-button
                type="warning" @click="() => toggleUpgradePlanModal({ show: true })"
                >Upgrade plan to see this lead
              </n-button>
            </div>
          </n-card>
        </div>
      </div>
    </div>
    <!-- TABLE VIEW -->
    <div class="form-result-cpt__table" v-if="view === 'table'">
      <div class="form-result-cpt__table__header">
        <div  class="form-result-cpt__table__header__filters">
          <n-input type="text" placeholder="Search">
            <template #suffix>
              <n-icon>
                <search-outline />
              </n-icon>
            </template>
          </n-input>
        </div>
        <div  class="form-result-cpt__table__header__actions">
          <div class="form-result-cpt__table__header__actions__exports">
            <span>Export</span>
            <n-button @click="() => exportLead('csv')">
              CSV
            </n-button>
            <n-button @click="() => exportLead('xlsx')">
              XLSX
            </n-button>
          </div>
          <n-button @click="updateView('inbox')">
            <template #icon>
              <n-icon>
                <browsers-outline />
              </n-icon>
            </template>
            INBOX VIEW
          </n-button>
        </div>
      </div>
      <n-data-table
        remote
        :loading="loadingFormLeads"
        ref="table"
        :columns="tableColumns"
        :data="tableData"
        :pagination="tablePagination"
        @update:page="handlePageChange"
        @update:page-size="handlePageSizeChange"
        :max-height="850"
        :scroll-x="4000"
        virtual-scroll
      />
    </div>
  </div>
</template>

<script>
import { h, ref } from 'vue';
import { NButton } from 'naive-ui';
import dayjs from 'dayjs';
import dayjsRelativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';
import {
  BrowsersOutline,
  SearchOutline,
} from '@vicons/ionicons5';

// COMPOSABLES
import useFormLead from '@/composables/form/useFormLead';
import useGlobals from '@/composables/useGlobals';
import useAuth from '@/composables/useAuth';

dayjs.extend(dayjsRelativeTime);

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      routeNames,
    } = useGlobals();

    const {
      loadingFormLeads,
      formLeads,
      formLeadsPagination,
      loadFormLeads,
    } = useFormLead();

    const {
      authJwtToken,
      toggleUpgradePlanModal,
      hasExportLeadFeature,
    } = useAuth();

    return {
      authJwtToken,
      toggleUpgradePlanModal,
      hasExportLeadFeature,

      view: ref('inbox'),
      selectedLead: ref(null),

      formLeads,
      formLeadsPagination,
      loadingFormLeads,
      loadFormLeads,

      routeNames,
    };
  },
  components: {
    BrowsersOutline,
    SearchOutline,
  },
  methods: {
    filterStepItems(items) {
      return items.filter((item) => !item.config.visual);
    },
    exportLead(format) {
      if (!this.hasExportLeadFeature()) {
        this.toggleUpgradePlanModal({ show: true });

        return;
      }

      window.open(`${process.env.VUE_APP_API_URL}/form-leads/${this.formId}/export/${format}?token=${this.authJwtToken}`);
    },
    updateView(view) {
      this.view = view;
    },
    onRowActionClick(action, row) {
      switch (action) {
        case 'view':
          this.selectedLead = row;
          break;
        default:
      }
    },
    handlePageChange(page) {
      this.loadFormLeads({ params: { formId: this.formId, page } });
    },
    handlePageSizeChange(pageSize) {
      this.loadFormLeads({ params: { formId: this.formId, page_size: pageSize } });
    },
    getControlValue(controls, name, defaultValue = '') {
      return _.find(controls, { name })?.value || defaultValue;
    },
    getFormStepItemResponse(formLead, formStepItem) {
      return _.find(
        formLead.form_lead_responses,
        { form_step_item_id: formStepItem.id },
      )?.response_html || 'Nil';
    },
    getFormHiddenFieldResponse(formLead, formHiddenField) {
      const formHiddenFieldResponse = _.find(
        formLead.form_hidden_field_responses,
        { form_hidden_field_id: formHiddenField.id },
      );

      if (!formHiddenFieldResponse) {
        return 'Nil';
      }

      return formHiddenFieldResponse.response;
    },
  },
  mounted() {
    this.loadFormLeads({
      params: {
        formId: this.formId,
      },
    }, (success) => {
      if (success) {
        this.selectedLead = _.find(this.tableData, { id: this.routeLeadId });

        if (this.selectedLead) {
          return;
        }

        this.selectedLead = this.tableData.length > 0 ? this.tableData[0] : null;
      }
    });
  },
  computed: {
    formId() {
      return this.form.id;
    },
    routeLeadId() {
      return this.$route.params.leadId;
    },
    metaFields() {
      const metaFields = [];

      if (!this.selectedLead || this.selectedLead.blocked) {
        return metaFields;
      }

      metaFields.push({
        id: 'ip',
        title: 'IP Address',
        value: this.selectedLead.form_visit.ip,
      });
      metaFields.push({
        id: 'browser',
        title: 'Browser',
        value: this.selectedLead.form_visit.browser,
      });
      metaFields.push({
        id: 'device',
        title: 'Device',
        value: this.selectedLead.form_visit.device_type,
      });
      metaFields.push({
        id: 'os',
        title: 'OS',
        value: this.selectedLead.form_visit.os,
      });
      metaFields.push({
        id: 'country',
        title: 'Country',
        value: `${this.selectedLead.form_visit.country || ''} (${this.selectedLead.form_visit.country_code})`,
      });
      metaFields.push({
        id: 'source',
        title: 'Source',
        value: this.selectedLead.form_visit.source_url,
      });

      return metaFields;
    },
    tableData() {
      return this.formLeads.map((formLead) => ({
        ...formLead,
        key: formLead.id,
      }));
    },
    tablePagination() {
      return {
        page: this.formLeadsPagination ? this.formLeadsPagination.current_page : 1,
        pageSize: this.formLeadsPagination ? Number(this.formLeadsPagination.per_page) : 10,
        showSizePicker: true,
        pageSizes: [10, 50, 100],
        pageCount: this.formLeadsPagination ? this.formLeadsPagination.last_page : 0,
        itemCount: this.formLeadsPagination ? this.formLeadsPagination.total : 0,
      };
    },
    tableColumns() {
      const self = this;

      const columns = [
        {
          type: 'selection',
        },
        {
          title: 'Received At',
          render(row) {
            return dayjs(row.created_at).fromNow();
          },
        },
      ];

      if (this.form.enable_quiz === 1) {
        columns.push({
          title: 'Quiz Score',
          render(row) {
            return row.quiz_score !== null ? `${row.quiz_score} Points` : row.quiz_score;
          },
        });
      }

      if (!this.selectedLead) {
        return columns;
      }

      if (this.view === 'table') {
        (this.form.form_steps || []).forEach((fs) => {
          (this.filterStepItems(fs.form_step_items) || []).forEach((fsi) => {
            columns.push({
              title: this.getControlValue(
                fsi.config.settings.controls,
                'label',
              ),
              render(row) {
                return self.getFormStepItemResponse(row, fsi);
              },
            });
          });
        });

        (this.form.form_hidden_fields || []).forEach((fhf) => {
          columns.push({
            title: `${fhf.name} (Hidden Field)`,
            render(row) {
              return self.getFormHiddenFieldResponse(row, fhf);
            },
          });
        });

        this.metaFields.forEach((mf) => {
          columns.push({
            title: mf.title,
            render() {
              return mf.value;
            },
          });
        });

        return columns;
      }

      columns.push({
        title: 'Actions',
        render(row) {
          return h(
            'div',
            { class: 'row-actions' },
            [
              h(
                NButton,
                {
                  size: 'small',
                  onClick: () => self.onRowActionClick('view', row),
                  title: 'Preview',
                  style: 'margin-right: 5px;',
                },
                {
                  default: () => 'View',
                },
              ),
            ],
          );
        },
      });

      return columns;
    },
  },
};
</script>
