import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export default function () {
  const store = useStore();

  // REFS
  const loadingFormLeads = ref(false);
  const loadingFormLeadsErrMsg = ref('');

  // COMPUTED
  const formLeads = computed(() => store.getters['formLead/formLeads']);
  const formLeadsPagination = computed(() => store.getters['formLead/formLeadsPagination']);

  // METHODS
  const loadFormLeads = (data, callback) => {
    loadingFormLeads.value = true;

    store.dispatch('formLead/getFormLeads', data).then((response) => {
      loadingFormLeads.value = false;
      store.dispatch('formLead/setFormLeads', response.data).then(() => {
        if (callback) {
          callback(true);
        }
      });
    }).catch((error) => {
      if (callback) {
        callback(false);
      }
      loadingFormLeads.value = false;
      loadingFormLeadsErrMsg.value = error.response.meta.error_message;
    });
  };

  return {
    loadingFormLeads,
    formLeads,
    formLeadsPagination,

    loadFormLeads,
  };
}
