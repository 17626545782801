<template>
  <div class="form-integration-edit-cpt">
    <form-webhook
      :form-integration="formIntegration"
      v-if="integrationTypes.webhook === integrationType && formIntegration"/>
    <div class="form-integration-edit-cpt__loading" v-else>
      <n-spin size="large"></n-spin>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import formIntegrationTypes from '@/shared/constants/formIntegrationTypes';
import useFormIntegration from '@/composables/form/useFormIntegration';
import FormWebhook from './FormWebhook/FormWebhook.vue';

export default {
  setup() {
    const { getFormIntegration } = useFormIntegration();

    return {
      getFormIntegration,
      formIntegration: ref(null),
    };
  },
  components: {
    FormWebhook,
  },
  mounted() {
    this.getFormIntegration({
      formId: this.$route.params.formId,
      integrationId: this.$route.params.integrationId,
      data: {
        integrationId: this.$route.params.integrationId,
        integrationType: this.$route.params.type,
      },
    }, (success, response) => {
      if (!success) {
        return;
      }

      this.formIntegration = response.data.result;
    });
  },
  computed: {
    integrationType() {
      return this.$route.params.type;
    },
    integrationTypes() {
      return formIntegrationTypes;
    },
  },
};
</script>
