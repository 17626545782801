import { createRouter, createWebHistory } from 'vue-router';

// Layouts
import FrontLayout from '@/layouts/FrontLayout.vue';
import DashLayout from '@/layouts/DashLayout.vue';
import FormDetailLayout from '../layouts/FormDetailLayout.vue';

// VIEWS
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import Dashboard from '@/views/Dashboard.vue';
import EmailVerificationSuccess from '@/views/EmailVerification/EmailVerificationSuccess.vue';
import EmailVerificationError from '@/views/EmailVerification/EmailVerificationError.vue';
import ResendVerification from '@/views/EmailVerification/ResendVerification.vue';
import SendPasswordLink from '@/views/ForgotPassword/SendPasswordLink.vue';
import ResetPassword from '@/views/ForgotPassword/ResetPassword.vue';
import Zapier from '@/views/Zapier/Zapier.vue';
import ZapierApi from '@/views/Zapier/Api.vue';

import FormCreate from '@/views/Form/FormCreate.vue';
import FormEdit from '@/views/Form/FormEdit.vue';
import FormIntegrations from '@/views/Form/FormIntegrations.vue';
import FormList from '@/views/Form/FormList/FormList.vue';
import FormResult from '@/views/Form/FormResult/FormResult.vue';
import FormLogic from '@/views/Form/FormLogic/FormLogic.vue';
import FormQuiz from '@/views/Form/FormQuiz/FormQuiz.vue';
import Plan from '@/views/Plan.vue';
import FormTemplateChooser from '@/views/Form/FormTemplateChooser.vue';

// ADMIN VIEWS
import Customer from '@/views/Admin/Customer/Customer.vue';
import TemplateBuilder from '@/views/Admin/TemplateBuilder/TemplateBuilder.vue';
import TemplateBuilderCreate from '@/views/Admin/TemplateBuilder/Create.vue';
import TemplateBuilderEdit from '@/views/Admin/TemplateBuilder/Edit.vue';

// COMPONENTS
import FormIntegrationList from '@/components/FormIntegrations/FormIntegrationList.vue';
import FormIntegrationCreate from '@/components/FormIntegrations/FormIntegrationCreate.vue';
import FormIntegrationEdit from '@/components/FormIntegrations/FormIntegrationEdit.vue';
import FormIntegrationSelect from '@/components/FormIntegrations/FormIntegrationSelect.vue';

// STORE
import store from '@/store';
import routeNames from '../shared/constants/routeNames';

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/zapier/api-docs',
    name: routeNames.zapier.apiDocs,
    component: ZapierApi,
    meta: {
      showWhenLoggedIn: true,
    },
  },
  {
    path: '/builder/create',
    name: routeNames.form.create,
    component: FormCreate,
    meta: {
      private: true,
    },
  },
  {
    path: '/builder/:id/edit',
    name: routeNames.form.edit,
    component: FormEdit,
    meta: {
      private: true,
    },
  },
  {
    path: '/form/:formId/',
    component: FormDetailLayout,
    meta: {
      private: true,
    },
    children: [
      {
        path: 'results/:leadId?',
        name: routeNames.formresult.index,
        component: FormResult,
      },
      {
        path: 'logic',
        name: routeNames.formlogic.index,
        component: FormLogic,
      },
      {
        path: 'quiz',
        name: routeNames.formquiz.index,
        component: FormQuiz,
      },
      {
        path: 'integrations/',
        name: routeNames.form.integrations,
        component: FormIntegrations,
        children: [
          {
            path: '',
            name: routeNames.form.integrations.index,
            component: FormIntegrationList,
          },
          {
            path: ':type/create',
            name: routeNames.form.integrations.create,
            component: FormIntegrationCreate,
          },
          {
            path: ':type/edit/:integrationId',
            name: routeNames.form.integrations.edit,
            component: FormIntegrationEdit,
          },
          {
            path: 'select',
            name: routeNames.form.integrations.select,
            component: FormIntegrationSelect,
          },
        ],
      },
    ],
  },
  {
    path: '/form-template-chooser',
    name: routeNames.formTemplateChooser.index,
    component: FormTemplateChooser,
    meta: {
      private: false,
      showWhenLoggedIn: true,
    },
  },
  {
    path: '/',
    component: FrontLayout,
    meta: {
      private: false,
    },
    children: [
      {
        path: '/login',
        name: routeNames.login,
        component: Login,
      },
      {
        path: '/register',
        name: routeNames.register,
        component: Register,
      },
      {
        path: '/email-verification-success',
        name: routeNames.emailVerification.success,
        component: EmailVerificationSuccess,
        meta: {
          showWhenLoggedIn: true,
        },
      },
      {
        path: '/email-verification-error',
        name: routeNames.emailVerification.error,
        component: EmailVerificationError,
        meta: {
          showWhenLoggedIn: true,
        },
      },
      {
        path: '/email-resend-verification',
        name: routeNames.emailVerification.resend,
        component: ResendVerification,
        meta: {
          showWhenLoggedIn: true,
        },
      },
      {
        path: '/forgot-password/send-password-link',
        name: routeNames.forgotPassword.sendPasswordLink,
        component: SendPasswordLink,
        meta: {
          showWhenLoggedIn: true,
        },
      },
      {
        path: '/forgot-password/reset-password',
        name: routeNames.forgotPassword.resetPassword,
        component: ResetPassword,
        meta: {
          showWhenLoggedIn: true,
        },
      },
    ],
  },
  {
    path: '/dashboard',
    component: DashLayout,
    meta: {
      private: true,
    },
    children: [
      {
        path: '',
        name: routeNames.dashboard,
        component: Dashboard,
      },
      {
        path: '/forms',
        name: routeNames.form.index,
        component: FormList,
      },
      {
        path: '/plan',
        name: routeNames.plan.upgrade,
        component: Plan,
      },
      {
        path: '/customers',
        name: routeNames.customer.index,
        component: Customer,
      },
      {
        path: '/zapier',
        name: routeNames.zapier.index,
        component: Zapier,
        meta: {
          private: true,
        },
      },
      {
        path: '/template-builder',
        name: routeNames.templateBuilder.index,
        component: TemplateBuilder,
        meta: {
          private: true,
        },
      },
      {
        path: '/template-builder-create',
        name: routeNames.templateBuilder.create,
        component: TemplateBuilderCreate,
        meta: {
          private: true,
        },
      },
      {
        path: '/template-builder/:id/edit',
        name: routeNames.templateBuilder.edit,
        component: TemplateBuilderEdit,
        meta: {
          private: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters['auth/isLoggedIn'];

  if (!isLoggedIn) {
    store.dispatch('auth/logout');
  }

  setTimeout(() => {
    if (to.matched.some((record) => record.meta.private)) {
      console.log('private route');
      // private routes
      if (!isLoggedIn) {
        next({
          path: '/login',
        });

        return;
      }

      next();
    } else {
      console.log('public route');
      // public routes
      if (!isLoggedIn) {
        next();

        return;
      }

      if (to.matched.some((record) => record.meta.showWhenLoggedIn)) {
        next();

        return;
      }

      next({
        path: '/dashboard',
      });
    }
  }, 0);
});

export default router;
