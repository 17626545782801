<template>
  <n-modal class="clone-form-modal" :show="true">
    <n-card
      style="width: 600px"
      :title="title || 'Clone Form'"
      :bordered="false"
      size="huge"
      role="dialog"
      aria-modal="true"
    >
      <n-form :model="cloneFormModel" ref="cloneFormRef" :rules="rules">
        <n-form-item path="title" label="Title">
          <n-input
            size="small"
            :value="cloneFormModel.name"
            @update:value="(v) => $emit('updateFormField', {name: 'title', value: v})"
            @keydown.enter.prevent />
        </n-form-item>
      </n-form>
      <template #footer>
        <div class="clone-form-modal__actions">
          <n-button @click="$emit('cancelCloneForm')">Cancel</n-button>
          <n-button
            type="primary"
            @click="cloneForm"
            :loading="cloneFormModel.cloning">{{cloneBtnText || 'Clone'}}</n-button>
        </div>
      </template>
    </n-card>
  </n-modal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    cloneBtnText: {
      type: String,
      default: '',
    },
    cloneFormModel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        title: [
          {
            required: true,
            trigger: ['input'],
            message: 'This field is required',
          },
        ],
      },
    };
  },
  methods: {
    cloneForm() {
      this.$refs.cloneFormRef.validate((errors) => {
        if (errors) {
          return;
        }

        this.$emit('cloneForm');
      });
    },
  },
};
</script>
