<template>
  <n-layout>
    <n-layout class="h-full" has-sider>
      <n-layout-sider
        bordered
        show-trigger
        collapse-mode="width"
        :collapsed-width="64"
        :width="240"
        :native-scrollbar="false"
        :inverted="inverted"
      >
        <div class="text-center" style="padding: 15px 0">
          CoreForm <small style="font-size:10px">{{ appVersion }}</small>
        </div>
        <n-menu
          class="dashboard-layout__user-side-menu"
          :inverted="false"
          :collapsed-width="64"
          :collapsed-icon-size="22"
          :options="userSideMenuOptions"
        />
        <n-menu
          :inverted="inverted"
          :collapsed-width="64"
          :collapsed-icon-size="22"
          :options="sideMenuOptions"
        />
      </n-layout-sider>
      <n-layout style="min-height: 100vh">
        <router-view></router-view>
      </n-layout>
    </n-layout>
  </n-layout>
</template>

<script>
import { h, ref } from 'vue';
import { NIcon } from 'naive-ui';
import {
  GridOutline,
  ListCircleOutline,
  LogOutOutline,
  PeopleOutline,
  PaperPlaneOutline,
  GitMergeOutline,
} from '@vicons/ionicons5';
import { RouterLink } from 'vue-router';

// COMPOSABLES
import useGlobals from '@/composables/useGlobals';
import useAuth from '@/composables/useAuth';

function renderIcon(icon) {
  return () => h(NIcon, null, { default: () => h(icon) });
}

export default {
  setup() {
    const { routeNames } = useGlobals();
    const { authUser, logout, isAdmin } = useAuth();

    return {
      routeNames,
      authUser,
      logout,
      isAdmin,

      inverted: ref(true),
    };
  },
  methods: {
    logoutUser() {
      this.logout(() => {
        this.$router.push({
          name: this.routeNames.login,
        });
      });
    },
  },
  computed: {
    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
    userSideMenuOptions() {
      const self = this;

      if (!self.authUser) {
        return [];
      }

      return [{
        label: () => `${self.authUser.user.firstName} (${self.authUser.user.email})`,
        key: 'authUser',
        children: [
          {
            label: () => h(
              'div',
              {
                onClick: () => self.logoutUser(),
              },
              { default: () => 'Logout' },
            ),
            key: 'logout',
            icon: renderIcon(LogOutOutline),
          },
        ],
      }];
    },
    sideMenuOptions() {
      const self = this;

      const options = [
        {
          label: () => h(
            RouterLink,
            {
              to: {
                name: self.routeNames.dashboard,
              },
            },
            { default: () => 'Dashboard' },
          ),
          key: self.routeNames.dashboard,
          icon: renderIcon(GridOutline),
        },
        {
          label: () => h(
            RouterLink,
            {
              to: {
                name: self.routeNames.form.index,
              },
            },
            { default: () => 'Forms' },
          ),
          key: self.routeNames.form.index,
          icon: renderIcon(ListCircleOutline),
        },
        {
          label: () => h(
            RouterLink,
            {
              to: {
                name: self.routeNames.zapier.index,
              },
            },
            { default: () => 'Zapier' },
          ),
          key: self.routeNames.zapier.index,
          icon: renderIcon(GitMergeOutline),
        },
      ];

      if (!this.isAdmin) {
        options.push({
          label: () => h(
            RouterLink,
            {
              to: {
                name: self.routeNames.plan.upgrade,
              },
            },
            { default: () => 'Plan' },
          ),
          key: self.routeNames.plan.upgrade,
          icon: renderIcon(PaperPlaneOutline),
        });
      }

      if (this.isAdmin) {
        options.push({
          label: () => h(
            RouterLink,
            {
              to: {
                name: self.routeNames.customer.index,
              },
            },
            { default: () => 'Customers' },
          ),
          key: self.routeNames.customer.index,
          icon: renderIcon(PeopleOutline),
        });
      }

      if (this.isAdmin) {
        options.push({
          label: () => h(
            RouterLink,
            {
              to: {
                name: self.routeNames.templateBuilder.index,
              },
            },
            { default: () => 'Templates' },
          ),
          key: self.routeNames.templateBuilder.index,
          icon: renderIcon(PeopleOutline),
        });
      }

      return options;
    },
  },
};
</script>
