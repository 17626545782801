<template>
  <div>
    <div
    :class="{
      'quill-editor-wrapper': true,
      'preview-mode': preview
    }"
    @click="$emit('focused', $event);">
      <!-- EDITOR HEADER -->
      <div class="quill-editor-wrapper__header">
        <div>
        </div>
        <div class="quill-editor-wrapper__header__actions">
          <!-- <div
            class="quill-editor-wrapper__header__action"
            title="Edit" @click="() => togglePreview(false)"
            v-if="preview">
            <i class="fas fa-edit"></i>
          </div> -->
          <div
            class="quill-editor-wrapper__header__action"
            title="Preview"
            @click="togglePreview($event, true)"
            v-if="!preview">
            <i class="fas fa-eye"></i>
          </div>
        </div>
      </div>
      <!-- EDITOR BODY -->
      <div class="quill-editor-wrapper__body">
        <!-- PREVIEW MODE -->
        <div
          :class="{
            'ql-container': true,
            'ql-snow': true,
          }"
          v-if="preview"
          @click="togglePreview($event, false)">
          <div
            :class="{
              'quill-editor-preview': true,
              'ql-editor': true,
            }"
            v-html="content">
          </div>
        </div>
        <!-- EDITOR MODE -->
        <div>
          <div
            :class="{'quill-editor-mode-wrapper': true, hide: preview}">
            <div :id="`quill-editor-${id}`">
              <div v-html="content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import Quill from 'quill';
import QuillResizeImage from 'quill-resize-image';
import ImageUploader from 'quill-image-uploader';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.snow.css';
import 'quill-image-uploader/dist/quill.imageUploader.min.css';
import useMedia from '@/composables/useMedia';
import mediaSource from '@/shared/constants/mediaSource';

// BEGIN allow image alignment styles
const ImageFormatAttributesList = [
  'alt',
  'height',
  'width',
  'style',
];

const BaseImageFormat = Quill.import('formats/image');
class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        // eslint-disable-next-line no-param-reassign
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);
// END allow image alignment styles

Quill.register('modules/resize', QuillResizeImage);
Quill.register('modules/imageUploader', ImageUploader);

export default {
  emits: ['textChanged', 'focused'],
  props: {
    content: {
      type: String,
      required: false,
      default: '',
    },
    showPreview: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const { uploadMedia } = useMedia();

    return {
      uploadMedia,

      id: ref(null),
      preview: ref(false),
      editor: ref(null),
    };
  },
  mounted() {
    this.id = uuidv4();
    this.preview = this.showPreview;

    setTimeout(() => {
      this.initQuillEditor();
    }, 500);
  },
  methods: {
    initQuillEditor() {
      const self = this;
      this.editor = new Quill(`#quill-editor-${this.id}`, {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['link', 'image'],
            [{ align: [] }],
          ],
          resize: {
            locale: {},
          },
          imageUploader: {
            upload: (file) => {
              const uploadData = {
                file,
                source: mediaSource.FORM_BUILDER_TEXT_EDITOR,
              };
              return new Promise((resolve, reject) => {
                const fileSize = parseFloat((file.size / (1000 * 1000)).toFixed(2));
                if (fileSize > 0.6) {
                  reject(new Error('File size can\'t be more than 1MB'));

                  // eslint-disable-next-line no-alert
                  alert('File size can\'t be more than 1MB');

                  return;
                }

                const formData = new FormData();
                formData.append('file', uploadData.file);
                formData.append('source', uploadData.source);
                self.uploadMedia({
                  data: formData,
                }, (success, response) => {
                  if (!success) {
                    return;
                  }

                  resolve(response.data.result.thumbnail_url);
                });
              });
            },
          },
        },
      });

      this.editor.on('text-change', () => {
        this.$emit('textChanged');
      });
    },
    togglePreview(e, hide) {
      this.preview = hide;
    },
  },
};
</script>
