export default {
  FORM_SUBMISSION_LIMIT: {
    slug: 'form_submission_limit',
    props: {
      LIMIT: 'limit',
    },
  },
  FORM_LIMIT: {
    slug: 'form_limit',
    props: {
      LIMIT: 'limit',
    },
  },
  WEBHOOK_INTEGRATION: {
    slug: 'webhook_integration',
  },
  EXPORT_LEADS: {
    slug: 'export_leads',
  },
  CONDITIONAL_LOGIC: {
    slug: 'conditional_logic',
  },
  QUIZ: {
    slug: 'quiz',
  },
};
