import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export default function () {
  const store = useStore();

  // REFS
  const loadingCustomers = ref(false);
  const loadingCustomersErrMsg = ref('');
  const changingDefaultPlan = ref(false);

  // COMPUTED
  const customers = computed(() => store.getters['adminCustomer/customers']);
  const customersPagination = computed(() => store.getters['adminCustomer/customersPagination']);

  // METHODS
  const loadCustomers = (data) => {
    loadingCustomers.value = true;

    store.dispatch('adminCustomer/getCustomers', data).then((response) => {
      loadingCustomers.value = false;
      store.dispatch('adminCustomer/setCustomers', response.data);
    }).catch((error) => {
      loadingCustomers.value = false;
      loadingCustomersErrMsg.value = error.response.meta.error_message;
    });
  };

  const changeDefaultPlan = (data, callback) => {
    changingDefaultPlan.value = true;
    store.dispatch('adminCustomer/changeDefaultPlan', data).then((response) => {
      changingDefaultPlan.value = false;
      if (callback) {
        callback(true, response);
      }
    }).catch((error) => {
      console.log(error);
      if (callback) {
        callback(true, error);
      }
      changingDefaultPlan.value = false;
    });
  };

  return {
    loadingCustomers,
    customers,
    customersPagination,
    changingDefaultPlan,

    loadCustomers,
    changeDefaultPlan,
  };
}
