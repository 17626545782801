<template>
  <div class="resend-verification-view mt-20">
    <div class="container">
      <div class="resend-verification-view__content">
        <n-card v-if="showSuccessMsg" style="max-width:500px;margin-left: auto;margin-right:auto">
          <n-result
            status="success"
            title="Success"
            description="Please check your email inbox for verification link">
            <template #footer>
              <n-button @click="$router.push({name: routeNames.login})">Log In</n-button>
            </template>
          </n-result>
        </n-card>
        <n-form
          v-else
          :model="model"
          ref="emailVerificationFormRef"
          :rules="rules" class="resend-verification-form">
          <h3 class="text-center">Resend Email Verification Link</h3><br>
          <n-form-item path="email" label="Email">
            <n-input
              v-model:value="model.email"
              type="email"
              :show-require-mark="true"
              placeholder=""/>
          </n-form-item>
          <div class="flex justify-center">
            <n-button
              size="large"
              @click="submit"
              type="primary">
              Send
            </n-button>
          </div>
          <div>
            <p class="text-center">
              <span class="text-gray-400 text-sm">Don't have an account?</span>
              <router-link
                :to="routeNames.register" class="text-theme text-sm ml-1">Sign up</router-link>
            </p>
          </div>
        </n-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useMessage } from 'naive-ui';
import { mapGetters } from 'vuex';

export default {
  setup() {
    const formRef = ref(null);
    const rPasswordFormItemRef = ref(null);
    const message = useMessage();
    const modelRef = ref({
      email: null,
      password: null,
    });

    return {
      message,
      formRef,
      rPasswordFormItemRef,
      model: modelRef,
      showSuccessMsg: ref(false),
      rules: {
        email: [
          {
            required: true,
            trigger: ['input'],
            message: 'Email is required',
          },
          {
            type: 'email',
            trigger: ['input'],
            message: 'Email address is invalid',
          },
        ],
      },
    };
  },
  methods: {
    submit(e) {
      e.preventDefault();
      this.$refs.emailVerificationFormRef.validate((errors) => {
        if (errors) {
          return;
        }

        this.$store.dispatch(
          'auth/resendEmailVerification',
          { data: { email: this.model.email } },
        ).then(() => {
          this.showSuccessMsg = true;
        }).catch((error) => {
          this.message.error(
            error.response.data.meta.error_message || 'Something went wrong!',
          );
        });
      });
    },
  },
  computed: {
    ...mapGetters([
      'routeNames',
    ]),
  },
};
</script>
