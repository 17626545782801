import FormApi from '@/api/form';

export default {
  namespaced: true,
  state: {
    forms: [],
    formsPagination: null,
  },
  getters: {
    forms(state) {
      return state.forms || [];
    },
    formsPagination(state) {
      return state.formsPagination;
    },
  },
  actions: {
    getForms(_, data) {
      return FormApi.index(data);
    },
    getForm(_, data) {
      return FormApi.show(data);
    },
    setForms(context, data) {
      context.commit('setForms', data);
    },
    deleteForm(context, data) {
      return FormApi.destroy(data.formId);
    },
    cloneForm(context, data) {
      return FormApi.clone(data.formId, { title: data.title });
    },
    getFieldMapping(context, data) {
      return FormApi.getFieldMapping(data.formId, data.data);
    },
  },
  mutations: {
    setForms(state, data) {
      state.forms = data.result;
      state.formsPagination = data.pagination;
    },
  },
};
