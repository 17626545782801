import FormLeadApi from '@/api/formLead';

export default {
  namespaced: true,
  state: {
    formLeads: [],
    formLeadsPagination: null,
  },
  getters: {
    formLeads(state) {
      return state.formLeads || [];
    },
    formLeadsPagination(state) {
      return state.formLeadsPagination;
    },
  },
  actions: {
    getFormLeads(_, data) {
      return FormLeadApi.index(data);
    },
    setFormLeads(context, data) {
      context.commit('setFormLeads', data);
    },
  },
  mutations: {
    setFormLeads(state, data) {
      state.formLeads = data.result;
      state.formLeadsPagination = data.pagination;
    },
  },
};
