<!-- eslint-disable max-len -->
<template>
  <div class="form-logic-cpt">
    <p class="text-sm mb-4 text-yellow-700"><strong>Note:</strong> If Step has more than one action then first Action which evaluates to true will be executed, following actions will be ignored.</p>
    <n-form :model="formModel" :rules="formRules" ref="formStepLogicsFormRef">
      <div class="form-logic-cpt__steps">
        <div
          class="form-logic-cpt__step"
          v-for="(step, stepIndex) in formStepLogic.form_steps" :key="step.id">
          <n-card :title="`Step ${stepIndex + 1} Logic`">
            <div
              :class="{'form-logic-cpt__step__logic': true, deleted: logic.deleted }"
              v-for="(logic, logicIndex) in step.form_step_logics"
              :key="logicIndex">
              <!-- ACTION -->
              <div class="form-logic-cpt__step__action">
                <div><strong>Action</strong></div>
                <n-form-item :show-label="false" :path="getPath(step, logic, `.action`, { logicIndex })">
                  <n-select
                    :value="logic.action"
                    :options="actionOptions"/>
                </n-form-item>
              </div>
              <!-- STEP TO JUMP -->
              <div class="form-logic-cpt__step__jump">
                <div><strong>Choose Step</strong></div>
                <n-form-item
                :show-label="false"
                :path="getPath(step, logic, `.action_config.jump_to_step`, { logicIndex })">
                  <n-select
                    @update:value="chooseStep($event, { logic, logicIndex })"
                    :value="logic.action_config.jump_to_step"
                    :options="getStepOptions(step, stepIndex)"/>
                </n-form-item>
              </div>
              <!-- CONDITION -->
              <div class="form-logic-cpt__step__condition">
                <div><strong>Condition</strong></div>
                <!-- CONDITION TERMS -->
                <div
                  class="form-logic-cpt__step__condition__term"
                  v-for="(term, termIndex) in logic.action_config.condition.terms"
                  :key="termIndex">
                  <div class="form-logic-cpt__step__condition__term__body">
                    <!-- SELECT QUESTION -->
                    <div class="form-logic-cpt__step__condition__term__if">
                      <div><strong>If</strong></div>
                      <div>
                        <n-form-item
                          :show-label="false"
                          :path="getPath(
                            step,
                            logic,
                            `.action_config.condition.term_${termIndex}.formStepItemId`,
                            { logicIndex }
                          )">
                          <n-select
                            :value="term.formStepItemId"
                            :options="getFormStepItemOptions(step)"
                            @update:value="onSelectCondtionTermStepItem(
                              $event, { logic, termIndex, logicIndex }
                            )">
                          </n-select>
                      </n-form-item>
                      </div>
                    </div>
                    <!-- SELECT Operator For non choice type question -->
                    <div
                      class="form-logic-cpt__step__condition__term__operator"
                      v-if="
                        term.formStepItemId &&
                        !isChoiceTypeStepItem(getStepItem(step, term.formStepItemId)) &&
                        getFormStepItemTypeOperators(
                          getStepItem(step, term.formStepItemId).form_step_item_type.name
                        ).length > 0">
                      <!-- INPUT OPERATOR-->
                      <div>
                        <n-form-item :show-label="false" :path="getPath(
                            step,
                            logic,
                            `.action_config.condition.term_${termIndex}.operator`,
                            { logicIndex }
                          )">
                          <n-select
                            placeholder="Choose Operator"
                            :value="term.operator"
                            :options="getFormStepItemTypeOperators(
                              getStepItem(step, term.formStepItemId).form_step_item_type.name
                            )"
                            @update:value="onSelectCondtionTermOperator(
                              $event, { logic, termIndex, logicIndex }
                            )">
                          </n-select>
                        </n-form-item>
                      </div>
                      <!-- INPUT VALUE -->
                      <div v-if="isRatingTypeStepItem(getStepItem(step, term.formStepItemId))">
                        <n-form-item :show-label="false" :path="getPath(
                            step,
                            logic,
                            `.action_config.condition.term_${termIndex}.value`,
                            { logicIndex }
                          )">
                          <n-input-number
                            :min="0"
                            :max="5"
                            placeholder="Input Value"
                            :value="term.value"
                            @update:value="onSelectCondtionTermValue(
                              $event, { logic, termIndex, logicIndex }
                            )">
                          </n-input-number>
                        </n-form-item>
                      </div>
                      <div
                        v-else-if="isDatetimeTypeStepItem(getStepItem(step, term.formStepItemId))">
                        <n-form-item :show-label="false" :path="getPath(
                            step,
                            logic,
                            `.action_config.condition.term_${termIndex}.value`,
                            { logicIndex }
                          )">
                            <n-date-picker
                              :value="term.value"
                              type="datetime"
                              @update:value="onSelectCondtionTermValue(
                                $event, { logic, termIndex, logicIndex, type: 'datetime' }
                              )"
                              clearable />
                          </n-form-item>
                      </div>
                      <div v-else>
                        <n-form-item :show-label="false" :path="getPath(
                            step,
                            logic,
                            `.action_config.condition.term_${termIndex}.value`,
                            { logicIndex }
                          )">
                          <n-input
                            placeholder="Input Value"
                            :value="term.value"
                            @update:value="onSelectCondtionTermValue(
                              $event, { logic, termIndex, logicIndex }
                            )"></n-input>
                          </n-form-item>
                      </div>
                    </div>
                    <!-- SELECT Operator For choice type question -->
                    <div v-else-if="
                      term.formStepItemId &&
                      isChoiceTypeStepItem(getStepItem(step, term.formStepItemId))">
                      <!-- Term choices -->
                      <div
                        class="form-logic-cpt__step__condition__term__choice"
                        v-for="(termChoice, termChoiceIndex) in term.choices"
                        :key="termChoiceIndex">
                        <div>
                          <div><strong>Choice selected is</strong></div>
                          <div>
                            <n-form-item :show-label="false" :path="getPath(
                              step,
                              logic,
                        `.action_config.condition.term_${termIndex}.choice_${termChoiceIndex}.value`,
                              { logicIndex }
                            )">
                              <n-select
                                :value="termChoice.value"
                                :options="getChoices(getStepItem(step, term.formStepItemId))"
                                @update:value="onSelectCondtionTermChoiceValue(
                                  $event, { termChoiceIndex, term, logic, termIndex, logicIndex }
                              )">
                              </n-select>
                            </n-form-item>
                          </div>
                        </div>
                        <div v-if="getStepItem(
                          step,
                          term.formStepItemId
                        ).form_step_item_type.name === 'multi_select'">
                          <n-form-item :show-label="false" :path="getPath(
                            step,
                            logic,
               `.action_config.condition.term_${termIndex}.choice_${termChoiceIndex}.logicOperator`,
                            { logicIndex }
                          )">
                            <n-select
                              :value="termChoice.logicOperator"
                              :options="logicalOperatorOptions"
                              @update:value="onSelectCondtionTermChoiceLogicOperator(
                                $event,
                                { termChoiceIndex, term, logic, termIndex, logicIndex }
                              )">
                            </n-select>
                          </n-form-item>
                        </div>
                        <div v-if="term.choices.length > 1">
                          <div
                            class="form-logic-cpt__step__condition__term__choice__delete">
                            <i
                              class="fas fa-trash theme-color"
                              @click="() => deleteConditionTermChoice({ logic, termIndex, logicIndex, termChoiceIndex })"></i>
                          </div>
                        </div>
                      </div>
                      <n-button
                        v-if="canAddTermChoice(step, term)"
                        type="default"
                        @click="addTermChoice({
                          term,
                          logic,
                          termIndex,
                          logicIndex,
                          termRef: `step_${step.id}_logic_${logic.id}_term_${termIndex}`
                        })"
                      >Add Choice</n-button>
                      <!-- RENDER ERROR -->
                      <n-form-item :show-label="false" :path="getPath(
                        step,
                        logic,
                        `.action_config.condition.term_${termIndex}.choices`,
                        { logicIndex }
                      )">
                        <n-select
                          style="display:none"
                          :ref="`step_${step.id}_logic_${logic.id}_term_${termIndex}`"
                          :value="transformTermChoices(term.choices)"
                          :options="transformTermChoices(term.choices)"/>
                      </n-form-item>
                    </div>
                    <div
                      class="form-logic-cpt__step__condition__term__delete">
                      <i
                        class="fas fa-trash theme-color"
                        @click="() => deleteConditionTerm({ logic, termIndex, logicIndex })"></i>
                    </div>
                  </div>
                  <div class="form-logic-cpt__step__condition__term__footer">
                    <!-- LOGIC OPERATOR -->
                    <div
                      class="form-logic-cpt__step__condition__term__logic-operator"
                      v-if="termIndex < logic.action_config.condition.terms.length - 1">
                        <n-form-item :show-label="false" :path="getPath(
                          step,
                          logic,
                          `.action_config.condition.term_${termIndex}.logicOperator`,
                          { logicIndex }
                        )">
                        <n-select
                          :value="term.logicOperator"
                          :options="logicalOperatorOptions"
                          @update:value="onSelectCondtionTermLogicOperator(
                            $event, { logic, termIndex, logicIndex }
                          )">
                        </n-select>
                      </n-form-item>
                    </div>
                  </div>
                </div>
                <n-button
                  type="default"
                  @click="addCondition(logic, logicIndex)">Add Condition</n-button>
              </div>
              <div
                class="form-logic-cpt__step__delete"
                v-if="filteredFormStepLogics(step.form_step_logics).length > 1">
                <i
                  class="fas fa-trash theme-color"
                  @click="() => deleteLogic({ logic, logicIndex })"></i>
              </div>
            </div>
            <div class="form-logic-cpt__step__add-action">
              <n-button type="primary" @click="addAction(step)">Add Action</n-button>
            </div>
          </n-card>
        </div>
      </div>
      <div class="form-logic-cpt__footer">
        <n-button
          :loading="savingFormStepLogic"
          type="primary"
          @click="saveLogic()">Save Logic</n-button>
      </div>
    </n-form>
  </div>
</template>

<script>
import { ref } from 'vue';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import { useMessage } from 'naive-ui';
import _ from 'lodash';
import useFormStepLogic from '@/composables/form/useFormStepLogic';
import formLogicActions from '@/shared/constants/formLogicActions';

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      loadingFormStepLogic,
      savingFormStepLogic,
      formStepLogic,
      formStepItemOptions,

      loadFormStepLogic,
      saveFormStepLogic,
      getFormStepItemTypeOperators,
      addFormStepLogic,
      addFormStepLogicCondition,
      updateFormStepLogicTermField,
      updateFormStepLogicStep,
      deleteFormStepLogic,
      deleteFormStepLogicConditionTerm,
      deleteFormStepLogicConditionTermChoice,
    } = useFormStepLogic();

    return {
      message: useMessage(),
      hasValidationErrors: ref(false),
      loadingFormStepLogic,
      savingFormStepLogic,
      formStepLogic,

      loadFormStepLogic,
      saveFormStepLogic,
      formStepItemOptions,
      getFormStepItemTypeOperators,
      addFormStepLogic,
      addFormStepLogicCondition,
      updateFormStepLogicTermField,
      updateFormStepLogicStep,
      deleteFormStepLogic,
      deleteFormStepLogicConditionTerm,
      deleteFormStepLogicConditionTermChoice,
    };
  },
  mounted() {
    dayjs.extend(dayjsUtc);
    dayjs.extend(dayjsTimezone);

    this.loadFormStepLogic({
      formId: this.form.id,
    });
  },
  methods: {
    transformTermChoices(choices) {
      return choices.map((c, cIndex) => ({ label: cIndex, value: cIndex }));
    },
    deleteLogic({ logic, logicIndex }) {
      this.deleteFormStepLogic({
        logic,
        logicIndex,
      });
    },
    deleteConditionTerm({ logic, termIndex, logicIndex }) {
      this.deleteFormStepLogicConditionTerm({
        logic,
        termIndex,
        logicIndex,
      });
    },
    deleteConditionTermChoice({
      logic, termIndex, logicIndex, termChoiceIndex,
    }) {
      this.deleteFormStepLogicConditionTermChoice({
        logic,
        termIndex,
        logicIndex,
        termChoiceIndex,
      });
    },
    filteredFormStepLogics(logics) {
      return logics.filter((l) => !l.deleted);
    },
    saveLogic() {
      this.hasValidationErrors = false;
      this.$refs.formStepLogicsFormRef.validate((errors) => {
        if (errors) {
          this.message.error('Please fill all the required fields.', { duration: 3000 });
          console.log(errors);
          this.hasValidationErrors = true;
          return;
        }

        const data = {
          formId: this.form.id,
          data: {
            form_steps: this.formStepLogic.form_steps
              .map((s) => ({ id: s.id, form_step_logics: s.form_step_logics })),
          },
        };

        this.saveFormStepLogic(data, (success, response) => {
          if (!success) {
            console.log(response.response);
            this.message.error('Something went wrong, unable to save logic!', { duration: 3000 });
            return;
          }

          this.message.success('Logic saved successfully.', { duration: 3000 });

          this.loadFormStepLogic({
            formId: this.form.id,
          });
        });
      });
    },
    getStepOptions(step, stepIndex) {
      const options = [];

      this.form.form_steps.forEach((s, i) => {
        if (s.id === step.id) {
          return;
        }

        if (i === stepIndex + 1) {
          options.push({
            label: 'Next Step',
            value: 'next',
          });
        } else {
          options.push({
            label: `Step ${i + 1}`,
            value: s.id,
          });
        }
      });

      if (stepIndex === this.form.form_steps.length - 1) {
        options.push({
          label: 'Next (Submit)',
          value: 'next',
        });
      }

      if (stepIndex < this.form.form_steps.length - 1) {
        options.push({
          label: 'End (Submit)',
          value: 'end',
        });
      }

      return options;
    },
    getFormStepItemOptions(step) {
      return this.formStepItemOptions.filter((o) => o.stepId === step.id);
    },
    addCondition(logic, logicIndex) {
      const defaultTerm = {
        formStepItemId: null,
        logicOperator: this.logicalOperatorOptions[0].value,
      };

      this.addFormStepLogicCondition({
        logic,
        logicIndex,
        term: defaultTerm,
      });
    },
    addAction(step) {
      this.addFormStepLogic({
        action: this.actionOptions[0].value,
        action_config: {
          condition: {
            terms: [],
          },
          jump_to_step: null,
        },
        form_step_id: step.id,
      });
    },
    canAddTermChoice(step, term) {
      const stepItem = this.getStepItem(step, term.formStepItemId);

      if (stepItem.form_step_item_type.name === 'single_select') {
        return term.choices.length === 0;
      }

      return true;
    },
    addTermChoice({
      term, logic, termIndex, logicIndex, termRef,
    }) {
      const choices = _.cloneDeep(term.choices || []);
      choices.push({
        logicOperator: this.logicalOperatorOptions[0].value,
        value: null,
      });
      this.updateFormStepLogicTermField({
        fieldName: 'choices',
        fieldValue: choices,
        termIndex,
        logicIndex,
        logic,
      });
      setTimeout(() => {
        this.$refs[termRef].focus();
        this.$refs[termRef].blur();
      }, 300);
    },
    getStepItem(step, stepItemId) {
      return _.find(step.form_step_items, { id: stepItemId });
    },
    isSingleTypeStepItem(item) {
      return item.form_step_item_type.name === 'single_select';
    },
    isChoiceTypeStepItem(item) {
      return item.form_step_item_type.name === 'single_select'
        || item.form_step_item_type.name === 'multi_select';
    },
    isRatingTypeStepItem(item) {
      return item.form_step_item_type.name === 'rating';
    },
    isDatetimeTypeStepItem(item) {
      return item.form_step_item_type.name === 'datetime';
    },
    getChoices(stepItem) {
      const choices = _.find(stepItem.config.settings.controls, { name: 'choices' });

      return choices.items.map((item) => {
        const labelControl = _.find(item.controls, { name: 'label' });

        return {
          label: labelControl.value,
          value: item.id,
          logicOperator: this.logicalOperatorOptions[0].value,
        };
      });
    },
    onSelectCondtionTermStepItem(value, { logic, termIndex, logicIndex }) {
      this.updateFormStepLogicTermField({
        fieldName: 'formStepItemId',
        fieldValue: value,
        termIndex,
        logicIndex,
        logic,
      });
    },
    onSelectCondtionTermOperator(value, { logic, termIndex, logicIndex }) {
      this.updateFormStepLogicTermField({
        fieldName: 'operator',
        fieldValue: value,
        termIndex,
        logicIndex,
        logic,
      });
    },
    onSelectCondtionTermLogicOperator(value, { logic, termIndex, logicIndex }) {
      this.updateFormStepLogicTermField({
        fieldName: 'logicOperator',
        fieldValue: value,
        termIndex,
        logicIndex,
        logic,
      });
    },
    onSelectCondtionTermValue(
      value,
      {
        logic, termIndex, logicIndex,
      },
    ) {
      this.updateFormStepLogicTermField({
        fieldName: 'value',
        fieldValue: value,
        termIndex,
        logicIndex,
        logic,
      });
    },
    onSelectCondtionTermChoiceValue(
      value,
      {
        termChoiceIndex, term, logic, termIndex, logicIndex,
      },
    ) {
      const choices = _.cloneDeep(term.choices);
      choices[termChoiceIndex].value = value;
      this.updateFormStepLogicTermField({
        fieldName: 'choices',
        fieldValue: choices,
        termIndex,
        logicIndex,
        logic,
      });
    },
    onSelectCondtionTermChoiceLogicOperator(
      value,
      {
        term, logic, termIndex, logicIndex,
      },
    ) {
      const choices = _.cloneDeep(term.choices);
      choices.forEach((c) => {
        // eslint-disable-next-line no-param-reassign
        c.logicOperator = value;
      });
      this.updateFormStepLogicTermField({
        fieldName: 'choices',
        fieldValue: choices,
        termIndex,
        logicIndex,
        logic,
      });
    },
    chooseStep(value, { logic, logicIndex }) {
      this.updateFormStepLogicStep({
        logic,
        logicIndex,
        formStepId: value,
      });
    },
    getPath(step, logic, append, options = null) {
      const logicId = options ? logic.id || options.logicIndex : logic.id;

      return `formStep_${step.id}.formStepLogic_${logicId}${append}`;
    },
  },
  computed: {
    logicalOperatorOptions() {
      return [
        {
          label: 'AND',
          value: 'AND',
        },
        {
          label: 'OR',
          value: 'OR',
        },
      ];
    },
    actionOptions() {
      const options = [];
      Object.keys(formLogicActions).forEach((k) => {
        options.push({
          label: k,
          value: formLogicActions[k],
        });
      });

      return options;
    },
    formModel() {
      return {};
    },
    formRules() {
      const rules = {};

      if (!this.formStepLogic?.form_steps) {
        return rules;
      }

      this.formStepLogic.form_steps.forEach((formStep) => {
        rules[`formStep_${formStep.id}`] = {};

        formStep.form_step_logics.forEach((formStepLogic, fslIndex) => {
          const terms = (formStepLogic.action_config?.condition?.terms || []).map((t, tIndex) => {
            const formStepItem = this.getStepItem(formStep, t.formStepItemId);
            let isNumberTypeValue = false;

            if (
              formStepItem
              && (
                this.isDatetimeTypeStepItem(formStepItem)
                || this.isRatingTypeStepItem(formStepItem)
              )
            ) {
              isNumberTypeValue = true;
            }
            const termRules = {
              formStepItemId: [
                {
                  required: true,
                  message: 'This field is required',
                  trigger: ['input'],
                  transform() {
                    const { formStepItemId } = formStepLogic
                      .action_config.condition.terms[tIndex];

                    return formStepItemId;
                  },
                },
              ],
              logicOperator: [
                {
                  required: true,
                  message: 'This field is required',
                  trigger: ['input', 'blur'],
                  transform() {
                    const { logicOperator } = formStepLogic
                      .action_config.condition.terms[tIndex];

                    return logicOperator;
                  },
                },
              ],
            };

            if (formStepItem && this.isChoiceTypeStepItem(formStepItem)) {
              if (this.isSingleTypeStepItem(formStepItem)) {
                termRules.choices = [
                  {
                    type: 'array',
                    required: true,
                    len: 1,
                    message: 'Please add choice',
                    trigger: ['change', 'blur'],
                    transform() {
                      const { choices } = formStepLogic
                        .action_config.condition.terms[tIndex];

                      return choices;
                    },
                  },
                ];
              } else {
                termRules.choices = [
                  {
                    type: 'array',
                    required: true,
                    min: 1,
                    message: 'Please add one or more choices',
                    trigger: ['change', 'blur'],
                    transform() {
                      const { choices } = formStepLogic
                        .action_config.condition.terms[tIndex];

                      return choices;
                    },
                  },
                ];
              }
              t.choices.forEach((tc, tcIndex) => {
                termRules[`choice_${tcIndex}`] = {
                  value: [{
                    type: 'number',
                    required: true,
                    message: 'This field is required',
                    trigger: ['input'],
                    transform() {
                      const { value } = formStepLogic
                        .action_config.condition.terms[tIndex].choices[tcIndex];

                      return value;
                    },
                  }],
                  logicOperator: [{
                    required: true,
                    message: 'This field is required',
                    trigger: ['input'],
                    transform() {
                      const { logicOperator } = formStepLogic
                        .action_config.condition.terms[tIndex].choices[tcIndex];

                      return logicOperator;
                    },
                  }],
                };
              });
            } else {
              termRules.operator = [
                {
                  required: true,
                  message: 'This field is required',
                  trigger: ['input', 'blur'],
                  transform() {
                    const { operator } = formStepLogic
                      .action_config.condition.terms[tIndex];

                    return operator;
                  },
                },
              ];

              termRules.value = [
                {
                  type: isNumberTypeValue ? 'number' : 'string',
                  required: true,
                  message: 'This field is required',
                  trigger: ['input', 'blur'],
                  transform() {
                    const { value } = formStepLogic
                      .action_config.condition.terms[tIndex];

                    return value;
                  },
                },
              ];
            }

            return termRules;
          }) || [];

          const conditionRules = {};
          terms.forEach((t, tIndex) => {
            conditionRules[`term_${tIndex}`] = t;
          });

          rules[`formStep_${formStep.id}`][`formStepLogic_${formStepLogic.id || fslIndex}`] = {
            action: [
              {
                required: true,
                message: 'This field is required',
                trigger: ['input'],
                transform() {
                  return formStepLogic.action;
                },
              },
            ],
            action_config: {
              jump_to_step: [
                {
                  required: true,
                  message: 'This field is required',
                  trigger: ['input'],
                  transform() {
                    return formStepLogic.action_config.jump_to_step;
                  },
                },
              ],
              condition: conditionRules,
            },
          };
        });
      });

      return rules;
    },
  },
};
</script>
