import { computed } from 'vue';
import { useStore } from 'vuex';

// CONSTANTS
import roles from '@/shared/constants/roles';

export default function () {
  // Store.
  const store = useStore();

  // Computed.
  const features = computed(() => store.getters.features);
  const authJwtToken = computed(() => store.getters['auth/token']);
  const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);
  const authUser = computed(() => store.getters['auth/authUser']);
  const authUserPlan = computed(() => authUser.value?.user.plan);
  const authUserSubscription = computed(() => authUser.value?.user.plan.subscription);
  const authUserRole = computed(() => authUser.value?.user.role);
  const isAdmin = computed(() => authUserRole.value?.name === roles.ADMIN);
  const isCustomer = computed(() => authUserRole.value?.name === roles.CUSTOMER);
  const upgradePlanModal = computed(() => store.getters['auth/upgradePlanModal']);

  // Methods
  const logout = (callback) => {
    store.dispatch('auth/logout')
      .then(() => {
        if (callback) {
          callback();
        }
      });
  };

  const refreshToken = (callback) => {
    store.dispatch('auth/refreshToken')
      .then((response) => {
        store.dispatch('auth/loginSuccess', response.data.result.token)
          .then(() => {
            if (callback) {
              callback(true, response);
            }
          });
      }).catch((error) => {
        console.log(error);
        if (callback) {
          callback(false, error.response);
        }
      });
  };

  /**
   * @param {*} feature
   * @param {*} propQuery {
   *   relation: '|| or &&',
   *   terms: [
   *     {
   *        'name': 'limit',
   *        'operator': '>|>=|<|<=|===',
   *        'value': 10,
   *     }
   *   ]
   * }
   */
  const hasPlanFeature = (featureSlug, propQuery = null) => {
    if (authUser.value?.user?.plan?.slug === 'ultimate') {
      return true;
    }

    let hasFeature = false;

    if (!authUser.value?.user?.plan?.plan_features) {
      return hasFeature;
    }

    authUser.value.user.plan.plan_features.forEach((pf) => {
      if (pf.feature.slug !== featureSlug) {
        return;
      }

      hasFeature = true;

      if (
        !pf.plan_feature_properties
        || pf.plan_feature_properties.length === 0
        || !propQuery
      ) {
        return;
      }

      const termResults = [];
      propQuery.terms.forEach((pqt) => {
        let result = false;
        pf.plan_feature_properties.forEach((pfp) => {
          if (pqt.name !== pfp.feature_property.name) {
            return;
          }

          switch (pqt.operator) {
            case '===':
              result = pqt.value === pfp.value;
              break;
            case '!==':
              result = pqt.value !== pfp.value;
              break;
            case '>':
              result = pqt.value > pfp.value;
              break;
            case '>=':
              result = pqt.value >= pfp.value;
              break;
            case '<':
              result = pqt.value < pfp.value;
              break;
            case '<=':
              result = pqt.value <= pfp.value;
              break;
            default:
              result = false;
          }
        });

        termResults.push(result);
      });

      const passesPropQuery = (propQuery.relation || '&&') === '&&'
        ? termResults.indexOf(false) <= -1
        : termResults.indexOf(true) >= 0;

      hasFeature = hasFeature && passesPropQuery;
    });

    return hasFeature;
  };

  const toggleUpgradePlanModal = (data) => {
    store.dispatch('auth/toggleUpgradePlanModal', data);
  };

  const hasFormLimitFeature = (formsCreatedCount) => {
    const propQuery = {
      terms: [
        {
          name: features.value.FORM_LIMIT.props.LIMIT,
          operator: '<=',
          value: formsCreatedCount,
        },
      ],
    };

    return hasPlanFeature(features.value.FORM_LIMIT.slug, propQuery);
  };

  const hasWebhookFeature = () => hasPlanFeature(features.value.WEBHOOK_INTEGRATION.slug);

  const hasExportLeadFeature = () => hasPlanFeature(features.value.EXPORT_LEADS.slug);

  const hasConditionalLogicFeature = () => hasPlanFeature(features.value.CONDITIONAL_LOGIC.slug);

  const hasQuizFeature = () => hasPlanFeature(features.value.QUIZ.slug);

  return {
    authJwtToken,
    isAdmin,
    isCustomer,
    isLoggedIn,
    authUser,
    authUserPlan,
    authUserSubscription,
    authUserRole,
    upgradePlanModal,

    logout,
    refreshToken,
    hasPlanFeature,
    toggleUpgradePlanModal,
    hasFormLimitFeature,
    hasWebhookFeature,
    hasExportLeadFeature,
    hasConditionalLogicFeature,
    hasQuizFeature,
  };
}
