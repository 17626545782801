<template>
  <div class="dashboard-view">
    <div class="dashboard-view__header"></div>
    <div class="dashboard-view__body">
      <div class="dashboard-view__overview">
        <div class="dashboard-view__overview__header">
          Overview
        </div>
        <div class="dashboard-view__overview__body">
          <div class="dashboard-view__widgets1">
            <analytics-widget />
          </div>
          <div class="dashboard-view__widgets2">
            <form-leads-widget />
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-view__footer"></div>
  </div>
</template>

<script>
// COMPONENTS
import FormLeadsWidget from '@/components/Dashboard/Widgets/FormLeads/FormLeads.vue';
import AnalyticsWidget from '@/components/Dashboard/Widgets/Analytics/Analytics.vue';

export default {
  components: {
    FormLeadsWidget,
    AnalyticsWidget,
  },
};
</script>
