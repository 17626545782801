<template>
  <!-- FOCUSED ENTITY -->
  <div class="formbuilder__settings-panel__focused">
    <focused-entity-element
      :element="currentElement"
      panel="settingsPanel"
      v-if="isFocusedEntityElementType" />
  </div>
</template>

<script>
import { ref } from 'vue';

// COMPONENTS
import FocusedEntityElement from '@/components/FormBuilder/FocusedEntity/Types/FocusedEntityElement.vue';

// COMPOSABLES
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';

// CONSTANTS
import focusedEntityTypes from '@/shared/constants/formbuilder/focusedEntityTypes';

export default {
  setup() {
    const { formBuilder, getStepItem } = useFormBuilder();

    return {
      formBuilder,
      getStepItem,
      focusedEntityTypes: ref(focusedEntityTypes),
    };
  },
  components: {
    FocusedEntityElement,
  },
  computed: {
    focusedEntity() {
      return this.$store.getters['formBuilder/focusedEntity']('settingsPanel');
    },
    currentElement() {
      if (this.focusedEntity.type === focusedEntityTypes.STEP_ITEM) {
        return this.getStepItem(this.focusedEntity.value);
      }

      if (this.focusedEntity.type === focusedEntityTypes.STEP_NAVIGATION) {
        return this.formBuilder.stepNavigation;
      }

      if (this.focusedEntity.type === focusedEntityTypes.FORM_GENERAL) {
        return this.formBuilder.general;
      }

      return null;
    },
    isFocusedEntityElementType() {
      return [
        focusedEntityTypes.STEP_ITEM,
        focusedEntityTypes.STEP_NAVIGATION,
        focusedEntityTypes.FORM_GENERAL,
      ].indexOf(this.focusedEntity.type) >= 0;
    },
  },
};
</script>
