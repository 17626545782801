<template>
  <div
    :class="{
      formbuilder__preview__step: true,
      empty: step.items.length === 0,
      dropping: dropping
    }"
    @dragover="(e) => onDragOver(e)"
    @dragleave="() => onDragLeave()"
    @drop="onDrop($event)">
    <!-- HEADER -->
    <div class="formbuilder__preview__step__header" v-if="step.items.length > 0">
      <div class="formbuilder__preview__step__header__title">
        Step {{ stepIndex + 1 }}
      </div>
      <div class="formbuilder__preview__step__header__actions">
        <div
          class="formbuilder__preview__step__header__action"
          title="Delete Step" v-if="formBuilder.steps.length > 1" @click="() => deleteStep(step)">
          <i class="fas fa-trash"></i>
        </div>
        <div
          class="formbuilder__preview__step__header__action"
          title="Duplicate Step"
          @click="() => duplicateStep(step)">
          <i class="fas fa-copy"></i>
        </div>
        <div
          class="formbuilder__preview__step__header__action"
          title="Add Step Before"
          @click="() => addStep(step, true)">
          <i class="fas fa-plus"></i>
        </div>
      </div>
    </div>
    <!-- PROGRESS BAR TOP -->
    <div class="formbuilder__preview__step__progress top-position"
      @click="onProgressBarFocus"
      v-if="isProgressBarOnTop() && isProgressBarActive()">
      <FormProgressBar :percentage="progress"/>
    </div>
    <!-- EMPTY STEP -->
    <div v-if="step.items.length === 0">Drag element here</div>
    <!-- STEP ITEMS -->
    <div v-else>
      <form-step-item
        :step="step"
        :step-item="item"
        v-for="item in items"
        :key="item.id">
      </form-step-item>
    </div>
    <!-- NAVIGATION -->
    <form-step-navigation :step="step" v-if="step.items.length > 0" />
    <!-- FOOTER -->
    <div class="formbuilder__preview__step__footer" v-if="step.items.length > 0">
      <div class="formbuilder__preview__step__footer__title">
      </div>
      <div class="formbuilder__preview__step__footer__actions">
        <div
          class="formbuilder__preview__step__footer__action"
          title="Add Step After"
          @click="() => addStep(step, false)">
          <i class="fas fa-plus"></i>
        </div>
      </div>
    </div>
    <!-- PROGRESS BAR BOTTOM -->
    <div class="formbuilder__preview__step__progress bottom-position"
      @click="onProgressBarFocus"
      v-if="!isProgressBarOnTop() && isProgressBarActive()">
      <FormProgressBar :percentage="progress"/>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import _ from 'lodash';

// COMPOSABLES
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';

// COMPONENTS
import FormStepItem from '@/components/FormBuilder/Preview/FormStepItem/FormStepItem.vue';
import FormStepNavigation from '@/components/FormBuilder/Preview/FormStep/FormStepNavigation.vue';
import FormProgressBar from '@/components/FormBuilder/Preview/FormProgressBar.vue';

// CONSTANTS
import focusedEntityTypes from '@/shared/constants/formbuilder/focusedEntityTypes';

export default {
  props: {
    stepIndex: {
      type: Number,
      required: true,
    },
    step: {
      type: Object,
      required: true,
    },
  },
  components: {
    FormStepItem,
    FormStepNavigation,
    FormProgressBar,
  },
  setup() {
    const {
      formBuilder,
      addStepItem,
      getThemeControlValue,
      focusEntity,
    } = useFormBuilder();
    const dropping = ref(false);

    return {
      formBuilder,
      addStepItem,
      getThemeControlValue,
      focusEntity,

      dropping,
    };
  },
  methods: {
    addStep(atStep, before) {
      this.$store.dispatch('formBuilder/addStep', {
        id: this.maxStepId + 1,
        atStep,
        before,
      });
    },
    deleteStep(step) {
      this.$store.dispatch('formBuilder/deleteStep', {
        stepId: step.id,
      });
    },
    duplicateStep(step) {
      this.$store.dispatch('formBuilder/duplicateStep', {
        id: this.maxStepId + 1,
        maxStepItemId: this.maxStepItemId,
        stepId: step.id,
      });
    },
    onDragOver(e) {
      e.preventDefault();

      this.dropping = true;
    },
    onDragLeave() {
      this.dropping = false;
    },
    onDrop(e) {
      this.dropping = false;

      if (this.step.items.length > 0) {
        return;
      }

      const element = JSON.parse(e.dataTransfer.getData('element'));
      this.addStepItem(
        this.step,
        element,
        this.lastItemOrder + 1,
      );
    },
    onProgressBarFocus(e) {
      e.stopPropagation();

      this.focusEntity({
        title: 'Settings',
        type: focusedEntityTypes.FORM_GENERAL,
        value: null,
        section: 'progress_bar',
      });
    },
    isProgressBarActive() {
      const active = _.find(
        this.formBuilder.general.settings.controls,
        { name: 'progress_bar_active' },
      ).value;

      return active && this.step.items.length > 0;
    },
    isProgressBarOnTop() {
      return _.find(
        this.formBuilder.general.settings.controls,
        { name: 'progress_bar_position' },
      ).value === 'top';
    },
  },
  computed: {
    progress() {
      return (((this.stepIndex) / this.sortedSteps.length) * 100).toFixed(2);
    },
    lastItemOrder() {
      if (this.step.items.length === 0) {
        return 0;
      }

      return this.step.items[this.step.items.length - 1].order + 1;
    },
    items() {
      return _.sortBy(this.step.items, (item) => item.order);
    },
    maxStepId() {
      return this.$store.getters['formBuilder/maxStepId'];
    },
    maxStepItemId() {
      return this.$store.getters['formBuilder/maxStepItemId'];
    },
    sortedSteps() {
      return _.sortBy(this.formBuilder.steps, (s) => s.order);
    },
  },
};
</script>
