<!-- eslint-disable max-len -->
<template>
  <div class="form-quiz-cpt">
    <n-form :model="formModel" :rules="formRules" ref="formStepQuizzesFormRef">
      <div class="form-quiz-cpt__steps">
        <div
          class="form-quiz-cpt__step"
          v-for="(step, stepIndex) in formStepQuiz.form_steps" :key="step.id">
          <n-card :title="`Step ${stepIndex + 1} Quiz`">
            <div
              :class="{'form-quiz-cpt__step__quiz': true, deleted: quiz.deleted }"
              v-for="(quiz, quizIndex) in step.form_step_quizzes"
              :key="quizIndex">
              <!-- ACTION -->
              <div class="form-quiz-cpt__step__action">
                <div><strong>Action</strong></div>
                <n-form-item :show-label="false" :path="getPath(step, quiz, `.action`, { quizIndex })">
                  <n-select
                    :value="quiz.action"
                    :options="actionOptions"
                    @update:value="onSelectAction($event, { quiz, quizIndex })"/>
                </n-form-item>
              </div>
              <!-- POINTS -->
              <div class="form-quiz-cpt__step__points">
                <div><strong>Points</strong></div>
                <n-form-item
                :show-label="false"
                :path="getPath(step, quiz, `.action_config.points`, { quizIndex })">
                  <n-input-number
                    placeholder="Add Points"
                    :value="quiz.action_config.points"
                    @update:value="addPoints($event, { quiz, quizIndex })">
                  </n-input-number>
                </n-form-item>
              </div>
              <!-- CONDITION -->
              <div class="form-quiz-cpt__step__condition">
                <div><strong>Condition</strong></div>
                <!-- CONDITION TERMS -->
                <div
                  class="form-quiz-cpt__step__condition__term"
                  v-for="(term, termIndex) in quiz.action_config.condition.terms"
                  :key="termIndex">
                  <div class="form-quiz-cpt__step__condition__term__body">
                    <!-- SELECT QUESTION -->
                    <div class="form-quiz-cpt__step__condition__term__if">
                      <div><strong>If</strong></div>
                      <div>
                        <n-form-item
                          :show-label="false"
                          :path="getPath(
                            step,
                            quiz,
                            `.action_config.condition.term_${termIndex}.formStepItemId`,
                            { quizIndex }
                          )">
                          <n-select
                            :value="term.formStepItemId"
                            :options="getFormStepItemOptions(step)"
                            @update:value="onSelectCondtionTermStepItem(
                              $event, { quiz, termIndex, quizIndex }
                            )">
                          </n-select>
                      </n-form-item>
                      </div>
                    </div>
                    <!-- SELECT Operator For non choice type question -->
                    <div
                      class="form-quiz-cpt__step__condition__term__operator"
                      v-if="
                        term.formStepItemId &&
                        !isChoiceTypeStepItem(getStepItem(step, term.formStepItemId)) &&
                        getFormStepItemTypeOperators(
                          getStepItem(step, term.formStepItemId).form_step_item_type.name
                        ).length > 0">
                      <!-- INPUT OPERATOR-->
                      <div>
                        <n-form-item :show-label="false" :path="getPath(
                            step,
                            quiz,
                            `.action_config.condition.term_${termIndex}.operator`,
                            { quizIndex }
                          )">
                          <n-select
                            placeholder="Choose Operator"
                            :value="term.operator"
                            :options="getFormStepItemTypeOperators(
                              getStepItem(step, term.formStepItemId).form_step_item_type.name
                            )"
                            @update:value="onSelectCondtionTermOperator(
                              $event, { quiz, termIndex, quizIndex }
                            )">
                          </n-select>
                        </n-form-item>
                      </div>
                      <!-- INPUT VALUE -->
                      <div v-if="isRatingTypeStepItem(getStepItem(step, term.formStepItemId))">
                        <n-form-item :show-label="false" :path="getPath(
                            step,
                            quiz,
                            `.action_config.condition.term_${termIndex}.value`,
                            { quizIndex }
                          )">
                          <n-input-number
                            :min="0"
                            :max="5"
                            placeholder="Input Value"
                            :value="term.value"
                            @update:value="onSelectCondtionTermValue(
                              $event, { quiz, termIndex, quizIndex }
                            )">
                          </n-input-number>
                        </n-form-item>
                      </div>
                      <div
                        v-else-if="isDatetimeTypeStepItem(getStepItem(step, term.formStepItemId))">
                        <n-form-item :show-label="false" :path="getPath(
                            step,
                            quiz,
                            `.action_config.condition.term_${termIndex}.value`,
                            { quizIndex }
                          )">
                            <n-date-picker
                              :value="term.value"
                              type="datetime"
                              @update:value="onSelectCondtionTermValue(
                                $event, { quiz, termIndex, quizIndex, type: 'datetime' }
                              )"
                              clearable />
                          </n-form-item>
                      </div>
                      <div v-else>
                        <n-form-item :show-label="false" :path="getPath(
                            step,
                            quiz,
                            `.action_config.condition.term_${termIndex}.value`,
                            { quizIndex }
                          )">
                          <n-input
                            placeholder="Input Value"
                            :value="term.value"
                            @update:value="onSelectCondtionTermValue(
                              $event, { quiz, termIndex, quizIndex }
                            )"></n-input>
                          </n-form-item>
                      </div>
                    </div>
                    <!-- SELECT Operator For choice type question -->
                    <div v-else-if="
                      term.formStepItemId &&
                      isChoiceTypeStepItem(getStepItem(step, term.formStepItemId))">
                      <!-- Term choices -->
                      <div
                        class="form-quiz-cpt__step__condition__term__choice"
                        v-for="(termChoice, termChoiceIndex) in term.choices"
                        :key="termChoiceIndex">
                        <div>
                          <div><strong>Choice selected is</strong></div>
                          <div>
                            <n-form-item :show-label="false" :path="getPath(
                              step,
                              quiz,
                        `.action_config.condition.term_${termIndex}.choice_${termChoiceIndex}.value`,
                              { quizIndex }
                            )">
                              <n-select
                                :value="termChoice.value"
                                :options="getChoices(getStepItem(step, term.formStepItemId))"
                                @update:value="onSelectCondtionTermChoiceValue(
                                  $event, { termChoiceIndex, term, quiz, termIndex, quizIndex }
                              )">
                              </n-select>
                            </n-form-item>
                          </div>
                        </div>
                        <div v-if="getStepItem(
                          step,
                          term.formStepItemId
                        ).form_step_item_type.name === 'multi_select'">
                          <n-form-item :show-label="false" :path="getPath(
                            step,
                            quiz,
               `.action_config.condition.term_${termIndex}.choice_${termChoiceIndex}.logicOperator`,
                            { quizIndex }
                          )">
                            <n-select
                              :value="termChoice.logicOperator"
                              :options="logicalOperatorOptions"
                              @update:value="onSelectCondtionTermChoiceLogicOperator(
                                $event,
                                { termChoiceIndex, term, quiz, termIndex, quizIndex }
                              )">
                            </n-select>
                          </n-form-item>
                        </div>
                        <div v-if="term.choices.length > 1">
                          <div
                            class="form-quiz-cpt__step__condition__term__choice__delete">
                            <i
                              class="fas fa-trash theme-color"
                              @click="() => deleteConditionTermChoice({ quiz, termIndex, quizIndex, termChoiceIndex })"></i>
                          </div>
                        </div>
                      </div>
                      <n-button
                        v-if="canAddTermChoice(step, term)"
                        type="default"
                        @click="addTermChoice({
                          term,
                          quiz,
                          logic,
                          termIndex,
                          quizIndex,
                          termRef: `step_${step.id}_quiz_${quiz.id}_term_${termIndex}`
                        })"
                      >Add Choice</n-button>
                      <!-- RENDER ERROR -->
                      <n-form-item :show-label="false" :path="getPath(
                        step,
                        quiz,
                        `.action_config.condition.term_${termIndex}.choices`,
                        { quizIndex }
                      )">
                        <n-select
                          style="display:none"
                          :ref="`step_${step.id}_quiz_${quiz.id}_term_${termIndex}`"
                          :value="transformTermChoices(term.choices)"
                          :options="transformTermChoices(term.choices)"/>
                      </n-form-item>
                    </div>
                    <div
                      class="form-quiz-cpt__step__condition__term__delete">
                      <i
                        class="fas fa-trash theme-color"
                        @click="() => deleteConditionTerm({ quiz, termIndex, quizIndex })"></i>
                    </div>
                  </div>
                  <div class="form-quiz-cpt__step__condition__term__footer">
                    <!-- QUIZ OPERATOR -->
                    <div
                      class="form-quiz-cpt__step__condition__term__logic-operator"
                      v-if="termIndex < quiz.action_config.condition.terms.length - 1">
                        <n-form-item :show-label="false" :path="getPath(
                          step,
                          quiz,
                          `.action_config.condition.term_${termIndex}.logicOperator`,
                          { quizIndex }
                        )">
                        <n-select
                          :value="term.logicOperator"
                          :options="logicalOperatorOptions"
                          @update:value="onSelectCondtionTermLogicOperator(
                            $event, { quiz, termIndex, quizIndex }
                          )">
                        </n-select>
                      </n-form-item>
                    </div>
                  </div>
                </div>
                <n-button
                  type="default"
                  @click="addCondition(quiz, quizIndex)">Add Condition</n-button>
              </div>
              <div
                class="form-quiz-cpt__step__delete"
                v-if="filteredFormStepQuizs(step.form_step_quizzes).length > 1">
                <i
                  class="fas fa-trash theme-color"
                  @click="() => deleteLogic({ quiz, quizIndex })"></i>
              </div>
            </div>
            <div class="form-quiz-cpt__step__add-action">
              <n-button type="primary" @click="addAction(step)">Add Action</n-button>
            </div>
          </n-card>
        </div>
      </div>
      <div class="form-quiz-cpt__footer">
        <n-button
          :loading="savingFormStepQuiz"
          type="primary"
          @click="saveLogic()">Save Quiz</n-button>
      </div>
    </n-form>
  </div>
</template>

<script>
import { ref } from 'vue';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import { useMessage } from 'naive-ui';
import _ from 'lodash';
import useFormStepQuiz from '@/composables/form/useFormStepQuiz';
import formQuizActions from '@/shared/constants/form/formQuizActions';

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      loadingFormStepQuiz,
      savingFormStepQuiz,
      formStepQuiz,
      formStepItemOptions,

      loadFormStepQuiz,
      saveFormStepQuiz,
      getFormStepItemTypeOperators,
      addFormStepQuiz,
      addFormStepQuizCondition,
      updateFormStepQuizTermField,
      updateFormStepQuizPoints,
      updateFormStepQuizAction,
      deleteFormStepQuiz,
      deleteFormStepQuizConditionTerm,
      deleteFormStepQuizConditionTermChoice,
    } = useFormStepQuiz();

    return {
      message: useMessage(),
      hasValidationErrors: ref(false),
      loadingFormStepQuiz,
      savingFormStepQuiz,
      formStepQuiz,

      loadFormStepQuiz,
      saveFormStepQuiz,
      formStepItemOptions,
      getFormStepItemTypeOperators,
      addFormStepQuiz,
      addFormStepQuizCondition,
      updateFormStepQuizTermField,
      updateFormStepQuizPoints,
      updateFormStepQuizAction,
      deleteFormStepQuiz,
      deleteFormStepQuizConditionTerm,
      deleteFormStepQuizConditionTermChoice,
    };
  },
  mounted() {
    dayjs.extend(dayjsUtc);
    dayjs.extend(dayjsTimezone);

    this.loadFormStepQuiz({
      formId: this.form.id,
    });
  },
  methods: {
    transformTermChoices(choices) {
      return choices.map((c, cIndex) => ({ label: cIndex, value: cIndex }));
    },
    deleteLogic({ quiz, quizIndex }) {
      this.deleteFormStepQuiz({
        quiz,
        quizIndex,
      });
    },
    deleteConditionTerm({ quiz, termIndex, quizIndex }) {
      this.deleteFormStepQuizConditionTerm({
        quiz,
        termIndex,
        quizIndex,
      });
    },
    deleteConditionTermChoice({
      quiz, termIndex, quizIndex, termChoiceIndex,
    }) {
      this.deleteFormStepQuizConditionTermChoice({
        quiz,
        termIndex,
        quizIndex,
        termChoiceIndex,
      });
    },
    filteredFormStepQuizs(logics) {
      return logics.filter((l) => !l.deleted);
    },
    saveLogic() {
      this.hasValidationErrors = false;
      this.$refs.formStepQuizzesFormRef.validate((errors) => {
        if (errors) {
          this.message.error('Please fill all the required fields.', { duration: 3000 });
          console.log(errors);
          this.hasValidationErrors = true;
          return;
        }

        const data = {
          formId: this.form.id,
          data: {
            form_steps: this.formStepQuiz.form_steps
              .map((s) => ({ id: s.id, form_step_quizzes: s.form_step_quizzes })),
          },
        };

        this.saveFormStepQuiz(data, (success, response) => {
          if (!success) {
            console.log(response.response);
            this.message.error('Something went wrong, unable to save quiz!', { duration: 3000 });
            return;
          }

          this.message.success('Quiz saved successfully.', { duration: 3000 });

          this.loadFormStepQuiz({
            formId: this.form.id,
          });
        });
      });
    },
    getStepOptions(step, stepIndex) {
      const options = [];

      this.form.form_steps.forEach((s, i) => {
        if (s.id === step.id) {
          return;
        }

        if (i === stepIndex + 1) {
          options.push({
            label: 'Next Step',
            value: 'next',
          });
        } else {
          options.push({
            label: `Step ${i + 1}`,
            value: s.id,
          });
        }
      });

      if (stepIndex === this.form.form_steps.length - 1) {
        options.push({
          label: 'Next (Submit)',
          value: 'next',
        });
      }

      if (stepIndex < this.form.form_steps.length - 1) {
        options.push({
          label: 'End (Submit)',
          value: 'end',
        });
      }

      return options;
    },
    getFormStepItemOptions(step) {
      return this.formStepItemOptions.filter((o) => o.stepId === step.id);
    },
    addCondition(quiz, quizIndex) {
      const defaultTerm = {
        formStepItemId: null,
        logicOperator: this.logicalOperatorOptions[0].value,
      };

      this.addFormStepQuizCondition({
        quiz,
        quizIndex,
        term: defaultTerm,
      });
    },
    addAction(step) {
      this.addFormStepQuiz({
        action: this.actionOptions[0].value,
        action_config: {
          condition: {
            terms: [],
          },
          points: null,
        },
        form_step_id: step.id,
      });
    },
    canAddTermChoice(step, term) {
      const stepItem = this.getStepItem(step, term.formStepItemId);

      if (stepItem.form_step_item_type.name === 'single_select') {
        return term.choices.length === 0;
      }

      return true;
    },
    addTermChoice({
      term, quiz, termIndex, quizIndex, termRef,
    }) {
      const choices = _.cloneDeep(term.choices || []);
      choices.push({
        logicOperator: this.logicalOperatorOptions[0].value,
        value: null,
      });
      this.updateFormStepQuizTermField({
        fieldName: 'choices',
        fieldValue: choices,
        termIndex,
        quizIndex,
        quiz,
      });
      setTimeout(() => {
        this.$refs[termRef].focus();
        this.$refs[termRef].blur();
      }, 300);
    },
    getStepItem(step, stepItemId) {
      return _.find(step.form_step_items, { id: stepItemId });
    },
    isSingleTypeStepItem(item) {
      return item.form_step_item_type.name === 'single_select';
    },
    isChoiceTypeStepItem(item) {
      return item.form_step_item_type.name === 'single_select'
        || item.form_step_item_type.name === 'multi_select';
    },
    isRatingTypeStepItem(item) {
      return item.form_step_item_type.name === 'rating';
    },
    isDatetimeTypeStepItem(item) {
      return item.form_step_item_type.name === 'datetime';
    },
    getChoices(stepItem) {
      const choices = _.find(stepItem.config.settings.controls, { name: 'choices' });

      return choices.items.map((item) => {
        const labelControl = _.find(item.controls, { name: 'label' });

        return {
          label: labelControl.value,
          value: item.id,
          logicOperator: this.logicalOperatorOptions[0].value,
        };
      });
    },
    onSelectCondtionTermStepItem(value, { quiz, termIndex, quizIndex }) {
      this.updateFormStepQuizTermField({
        fieldName: 'formStepItemId',
        fieldValue: value,
        termIndex,
        quizIndex,
        quiz,
      });
    },
    onSelectCondtionTermOperator(value, { quiz, termIndex, quizIndex }) {
      this.updateFormStepQuizTermField({
        fieldName: 'operator',
        fieldValue: value,
        termIndex,
        quizIndex,
        quiz,
      });
    },
    onSelectCondtionTermLogicOperator(value, { quiz, termIndex, quizIndex }) {
      this.updateFormStepQuizTermField({
        fieldName: 'logicOperator',
        fieldValue: value,
        termIndex,
        quizIndex,
        quiz,
      });
    },
    onSelectCondtionTermValue(
      value,
      {
        quiz, termIndex, quizIndex,
      },
    ) {
      this.updateFormStepQuizTermField({
        fieldName: 'value',
        fieldValue: value,
        termIndex,
        quizIndex,
        quiz,
      });
    },
    onSelectCondtionTermChoiceValue(
      value,
      {
        termChoiceIndex, term, quiz, termIndex, quizIndex,
      },
    ) {
      const choices = _.cloneDeep(term.choices);
      choices[termChoiceIndex].value = value;
      this.updateFormStepQuizTermField({
        fieldName: 'choices',
        fieldValue: choices,
        termIndex,
        quizIndex,
        quiz,
      });
    },
    onSelectCondtionTermChoiceLogicOperator(
      value,
      {
        term, quiz, termIndex, quizIndex,
      },
    ) {
      const choices = _.cloneDeep(term.choices);
      choices.forEach((c) => {
        // eslint-disable-next-line no-param-reassign
        c.logicOperator = value;
      });
      this.updateFormStepQuizTermField({
        fieldName: 'choices',
        fieldValue: choices,
        termIndex,
        quizIndex,
        quiz,
      });
    },
    addPoints(value, { quiz, quizIndex }) {
      this.updateFormStepQuizPoints({
        quiz,
        quizIndex,
        points: value,
      });
    },
    onSelectAction(value, { quiz, quizIndex }) {
      this.updateFormStepQuizAction({
        quiz,
        quizIndex,
        action: value,
      });
    },
    getPath(step, quiz, append, options = null) {
      const logicId = options ? quiz.id || options.quizIndex : quiz.id;

      return `formStep_${step.id}.formStepQuiz_${logicId}${append}`;
    },
  },
  computed: {
    formQuizActions() {
      return formQuizActions;
    },
    logicalOperatorOptions() {
      return [
        {
          label: 'AND',
          value: 'AND',
        },
        {
          label: 'OR',
          value: 'OR',
        },
      ];
    },
    actionOptions() {
      const options = [];
      Object.keys(formQuizActions).forEach((k) => {
        options.push({
          label: k,
          value: formQuizActions[k],
        });
      });

      return options;
    },
    formModel() {
      return {};
    },
    formRules() {
      const rules = {};

      if (!this.formStepQuiz?.form_steps) {
        return rules;
      }

      this.formStepQuiz.form_steps.forEach((formStep) => {
        rules[`formStep_${formStep.id}`] = {};

        formStep.form_step_quizzes.forEach((formStepQuiz, fslIndex) => {
          const terms = (formStepQuiz.action_config?.condition?.terms || []).map((t, tIndex) => {
            const formStepItem = this.getStepItem(formStep, t.formStepItemId);
            let isNumberTypeValue = false;

            if (
              formStepItem
              && (
                this.isDatetimeTypeStepItem(formStepItem)
                || this.isRatingTypeStepItem(formStepItem)
              )
            ) {
              isNumberTypeValue = true;
            }
            const termRules = {
              formStepItemId: [
                {
                  required: true,
                  message: 'This field is required',
                  trigger: ['input'],
                  transform() {
                    const { formStepItemId } = formStepQuiz
                      .action_config.condition.terms[tIndex];

                    return formStepItemId;
                  },
                },
              ],
              logicOperator: [
                {
                  required: true,
                  message: 'This field is required',
                  trigger: ['input', 'blur'],
                  transform() {
                    const { logicOperator } = formStepQuiz
                      .action_config.condition.terms[tIndex];

                    return logicOperator;
                  },
                },
              ],
            };

            if (formStepItem && this.isChoiceTypeStepItem(formStepItem)) {
              if (this.isSingleTypeStepItem(formStepItem)) {
                termRules.choices = [
                  {
                    type: 'array',
                    required: true,
                    len: 1,
                    message: 'Please add choice',
                    trigger: ['blur'],
                    transform() {
                      const { choices } = formStepQuiz
                        .action_config.condition.terms[tIndex];

                      return choices;
                    },
                  },
                ];
              } else {
                termRules.choices = [
                  {
                    type: 'array',
                    required: true,
                    min: 1,
                    message: 'Please add one or more choices',
                    trigger: ['blur'],
                    transform() {
                      const { choices } = formStepQuiz
                        .action_config.condition.terms[tIndex];

                      return choices;
                    },
                  },
                ];
              }
              t.choices.forEach((tc, tcIndex) => {
                termRules[`choice_${tcIndex}`] = {
                  value: [{
                    type: 'number',
                    required: true,
                    message: 'This field is required',
                    trigger: ['input'],
                    transform() {
                      const { value } = formStepQuiz
                        .action_config.condition.terms[tIndex].choices[tcIndex];

                      return value;
                    },
                  }],
                  logicOperator: [{
                    required: true,
                    message: 'This field is required',
                    trigger: ['input'],
                    transform() {
                      const { logicOperator } = formStepQuiz
                        .action_config.condition.terms[tIndex].choices[tcIndex];

                      return logicOperator;
                    },
                  }],
                };
              });
            } else {
              termRules.operator = [
                {
                  required: true,
                  message: 'This field is required',
                  trigger: ['input', 'blur'],
                  transform() {
                    const { operator } = formStepQuiz
                      .action_config.condition.terms[tIndex];

                    return operator;
                  },
                },
              ];

              termRules.value = [
                {
                  type: isNumberTypeValue ? 'number' : 'string',
                  required: true,
                  message: 'This field is required',
                  trigger: ['input', 'blur'],
                  transform() {
                    const { value } = formStepQuiz
                      .action_config.condition.terms[tIndex];

                    return value;
                  },
                },
              ];
            }

            return termRules;
          }) || [];

          const conditionRules = {};
          terms.forEach((t, tIndex) => {
            conditionRules[`term_${tIndex}`] = t;
          });

          rules[`formStep_${formStep.id}`][`formStepQuiz_${formStepQuiz.id || fslIndex}`] = {
            action: [
              {
                required: true,
                message: 'This field is required',
                trigger: ['input'],
                transform() {
                  return formStepQuiz.action;
                },
              },
            ],
            action_config: {
              points: [
                {
                  type: 'number',
                  required: true,
                  message: 'This field is required',
                  trigger: ['input'],
                  transform() {
                    return formStepQuiz.action_config.points;
                  },
                },
              ],
              condition: conditionRules,
            },
          };
        });
      });

      return rules;
    },
  },
};
</script>
