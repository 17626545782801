<template>
  <div>
    <n-progress
      type="line"
      :show-indicator="false"
      :indicator-placement="showIndicator() ? 'inside' : 'outside'"
      status="success"
      :percentage="percentage"
      :color="getColor()"
      :rail-color="getRailColor()"
    />
  </div>
</template>

<script>
import _ from 'lodash';
// COMPOSABLES
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';

export default {
  props: {
    percentage: {
      type: Number,
    },
  },
  setup() {
    const { formBuilder } = useFormBuilder();

    return {
      formBuilder,
    };
  },
  methods: {
    getColor() {
      return _.find(
        this.formBuilder.general.settings.controls,
        { name: 'progress_bar_color' },
      ).value;
    },
    getRailColor() {
      return _.find(
        this.formBuilder.general.settings.controls,
        { name: 'progress_bar_rail_color' },
      ).value;
    },
    showIndicator() {
      return _.find(
        this.formBuilder.general.settings.controls,
        { name: 'progress_bar_indicators' },
      ).value;
    },
  },
};
</script>
