<template>
  <div class="form-edit-view">
    <div class="form-edit-view__loader" v-if="!formBuilder">
        <n-spin size="large" />
    </div>
    <form-builder v-else />
  </div>
</template>

<script>
// COMPONENTS
import FormBuilder from '@/components/FormBuilder/Builder.vue';

// COMPOSABLES
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';

export default {
  setup() {
    const {
      loadFormBuilderState,
      formBuilder,
    } = useFormBuilder();

    return {
      formBuilder,
      loadFormBuilderState,
    };
  },
  components: {
    FormBuilder,
  },
  mounted() {
    this.loadFormBuilderState(this.$route.params.id);
  },
};
</script>
