import axios from '@/api/axios';

const index = (data = {}) => axios
  .get(`/form-integrations/${data.formId}`, { params: data?.params || {} });

const store = (data = {}) => axios
  .post(`/form-integrations/${data.formId}`, data.data || {}, { params: data?.params || {} });

const show = (data = {}) => axios
  .post(`/form-integrations/${data.formId}/show/${data.integrationId}`, data.data || {}, { params: data?.params || {} });

const update = (data = {}) => axios
  .put(`/form-integrations/${data.formId}/update/${data.integrationId}`, data.data || {}, { params: data?.params || {} });

export default {
  index,
  store,
  show,
  update,
};
