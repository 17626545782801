<template>
  <div
    :id="id"
    :class="{
      'focused': isFocused,
      'formbuilder__preview__step-item': true,
      [`drop-${dropPosition}`]: dropping,
      [stepItem.type]: true
    }"
    @dragover="(e) => onDragOver(e)()"
    @dragleave="(e) => onDragLeave(e)()"
    @drop="(e) => onDrop(e)()"
    @click="(e) => onFocus(e)"
    >
    <!-- CONTENT -->
    <div class="formbuilder__preview__step-item__content">
      <!-- SHORT TEXT -->
      <div
        class="formbuilder__preview__step-item__item"
        v-if="elementTypes.SHORT_TEXT === stepItem.type">
        <n-form-item
          :show-require-mark="getControlValue('required', false)"
          :label="getControlValue('label')">
          <div
            class="formbuilder__preview__step-item__item__desc"
            v-if="getControlValue('description', '').length > 0">
            {{getControlValue('description')}}
          </div>
          <n-input
            type="text"
            :value="getControlValue('default')"
            :placeholder="getControlValue('placeholder')" />
        </n-form-item>
      </div>
      <!-- LONG TEXT -->
      <div
        class="formbuilder__preview__step-item__item"
        v-if="elementTypes.LONG_TEXT === stepItem.type">
        <n-form-item
          :show-require-mark="getControlValue('required', false)"
          :label="getControlValue('label')">
          <div
            class="formbuilder__preview__step-item__item__desc"
            v-if="getControlValue('description', '').length > 0">
            {{getControlValue('description')}}
          </div>
          <n-input
            type="textarea"
            :value="getControlValue('default')"
            :placeholder="getControlValue('placeholder')"/>
        </n-form-item>
      </div>
      <!-- EMAIL ADDRESS -->
      <div
        class="formbuilder__preview__step-item__item"
        v-if="elementTypes.EMAIL_ADDRESS === stepItem.type">
        <n-form-item
          :show-require-mark="getControlValue('required', false)"
          :label="getControlValue('label')">
          <div
            class="formbuilder__preview__step-item__item__desc"
            v-if="getControlValue('description', '').length > 0">
            {{getControlValue('description')}}
          </div>
          <n-input
            type="text"
            :value="getControlValue('default')"
            :placeholder="getControlValue('placeholder')" />
        </n-form-item>
      </div>
      <!-- PHONE NUMBER -->
      <div
        class="formbuilder__preview__step-item__item"
         v-if="elementTypes.PHONE_NUMBER === stepItem.type">
        <phone-number-item :step-item="stepItem" />
      </div>
      <!-- RATING -->
      <div
        class="formbuilder__preview__step-item__item"
        v-if="elementTypes.RATING === stepItem.type">
        <n-form-item
          :show-require-mark="getControlValue('required', false)"
          :label="getControlValue('label')">
          <div
            class="formbuilder__preview__step-item__item__desc"
            v-if="getControlValue('description', '').length > 0">
            {{getControlValue('description')}}
          </div>
          <n-rate :value="getControlValue('default', 0)" />
        </n-form-item>
      </div>
      <!-- SINGLE SELECT -->
      <div
        class="formbuilder__preview__step-item__item"
        v-if="elementTypes.SINGLE_SELECT === stepItem.type">
        <single-select-item :step-item="stepItem" />
      </div>
       <!-- MULTI SELECT -->
      <div
        class="formbuilder__preview__step-item__item"
        v-if="elementTypes.MULTI_SELECT === stepItem.type">
        <multi-select-item :step-item="stepItem" />
      </div>
      <!-- Text Editor -->
      <div
        class="formbuilder__preview__step-item__item"
        v-if="elementTypes.TEXT_EDITOR === stepItem.type">
        <text-editor-item :step-item="stepItem" @focused="(e) => onFocus(e)"/>
      </div>
       <!-- DATE TIME -->
      <div
        class="formbuilder__preview__step-item__item"
        v-if="elementTypes.DATETIME === stepItem.type">
        <n-form-item
          :show-require-mark="getControlValue('required', false)"
          :label="getControlValue('label')">
          <div
            class="formbuilder__preview__step-item__item__desc"
            v-if="getControlValue('description', '').length > 0">
            {{getControlValue('description')}}
          </div>
          <n-date-picker
            :value="getControlValue('default')"
            type="datetime"
            clearable />
        </n-form-item>
      </div>
      <!-- MULTI SELECT -->
      <div
        class="formbuilder__preview__step-item__item"
        v-if="elementTypes.ADDRESS === stepItem.type">
        <address-item :step-item="stepItem" />
      </div>
    </div>
    <!-- FOOTER -->
    <div class="formbuilder__preview__step-item__footer">
      <div class="formbuilder__preview__step-item__footer__actions">
        <div class="formbuilder__preview__step-item__footer__action">
          <i class="fas fa-copy" @click.stop="() => duplicateStepItem(step.id, stepItem.id)"></i>
        </div>
        <div class="formbuilder__preview__step-item__footer__action" v-if="step.items.length > 1">
          <i class="fas fa-trash" @click.stop="() => deleteStepItem(step.id, stepItem.id)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

// COMPOSABLES
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';

// CONSTANTS
import elementTypes from '@/shared/constants/formbuilder/elementTypes';
import focusedEntityTypes from '@/shared/constants/formbuilder/focusedEntityTypes';

// COMPONENTS
import AddressItem from './Types/Address.vue';
import SingleSelectItem from './Types/SingleSelect.vue';
import MultiSelectItem from './Types/MultiSelect.vue';
import PhoneNumberItem from './Types/PhoneNumber.vue';
import TextEditorItem from './Types/TextEditor.vue';

export default {
  props: {
    step: {
      type: Object,
      required: true,
    },
    stepItem: {
      type: Object,
      required: true,
    },
  },
  components: {
    SingleSelectItem,
    MultiSelectItem,
    PhoneNumberItem,
    AddressItem,
    TextEditorItem,
  },
  setup() {
    const {
      settingsPanel,
      formBuilder,
      addStepItemOverItem,
      deleteStepItem,
      duplicateStepItem,
      focusEntity,
    } = useFormBuilder();

    return {
      settingsPanel,
      formBuilder,
      addStepItemOverItem,
      deleteStepItem,
      duplicateStepItem,
      focusEntity,
    };
  },
  data() {
    return {
      dropPosition: '',
      dropping: false,
      prevDragOverMouseOffsetY: -1,
    };
  },
  computed: {
    focusedEntity() {
      return this.settingsPanel.focusedEntity;
    },
    isFocused() {
      if (!this.focusedEntity) {
        return false;
      }

      return this.focusedEntity.type === 'stepItem' && this.focusedEntity.value === this.stepItem.id;
    },
    id() {
      return `formbuilder__preview__step-item${this.stepItem.id}`;
    },
    elementTypes() {
      return elementTypes;
    },
    maxStepItemId() {
      return this.$store.getters['formBuilder/maxStepItemId'];
    },
  },
  methods: {
    onDragOver(e) {
      return _.debounce(() => {
        this.dropping = true;

        const elementTopY = document
          .getElementById(this.id)
          .getBoundingClientRect()
          .top;
        const elementBottomY = document
          .getElementById(this.id)
          .getBoundingClientRect()
          .bottom;
        const elementHeight = (elementBottomY - elementTopY) / 2;

        if ((elementTopY + (elementHeight / 2)) >= e.clientY) {
          this.dropPosition = 'top';
        } else {
          this.dropPosition = 'bottom';
        }
      }, 70);
    },
    onDragLeave() {
      return _.debounce(() => {
        this.dropping = false;
      }, 80);
    },
    onDrop(e) {
      const element = JSON.parse(e.dataTransfer.getData('element'));

      return _.debounce(() => {
        this.dropping = false;
        this.addStepItemOverItem(this.step, element, this.stepItem, this.dropPosition);
      }, 100);
    },
    onFocus(e) {
      e.stopPropagation();
      this.focusEntity({
        title: this.stepItem.title,
        type: focusedEntityTypes.STEP_ITEM,
        value: this.stepItem.id,
      });
    },
    getControl(name) {
      return _.find(this.stepItem.settings.controls, { name });
    },
    getControlValue(name, defaultValue = '') {
      return _.find(this.stepItem.settings.controls, { name })?.value || defaultValue;
    },
  },
};
</script>
