import axios from '@/api/axios';

const sendPasswordLink = (data) => axios
  .post('/forgot-password/send-password-link', data.data);

const resetPassword = (data) => axios
  .post('/forgot-password/reset-password', data.data);

export default {
  sendPasswordLink,
  resetPassword,
};
