<template>
  <n-modal class="delete-form-modal" :show="true">
    <n-card
      style="width: 600px"
      title="Delete Form"
      :bordered="false"
      size="huge"
      role="dialog"
      aria-modal="true"
    >
      Are you sure to delete <strong>{{deleteFormModel.selected.title}}</strong> form?
      <template #footer>
        <div class="delete-form-modal__actions">
          <n-button @click="$emit('cancelDeleteForm')">Cancel</n-button>
          <n-button
            type="primary"
            @click="$emit('deleteForm')"
            :loading="deleteFormModel.deleting">Delete</n-button>
        </div>
      </template>
    </n-card>
  </n-modal>
</template>

<script>
export default {
  props: {
    deleteFormModel: {
      type: Object,
      required: true,
    },
  },
};
</script>
