<template>
  <!-- ELEMENTS -->
  <div class="formbuilder__elements active">
    <div class="formbuilder__elements__header">
        Elements
    </div>
    <div class="formbuilder__elements__content">
      <div
        draggable="true"
        @dragstart="onDragStart($event, element)"
        class="formbuilder__element"
        v-for="element in formBuilder.elements" :key="element.type">
        <div class="formbuilder__element__icon">
          <i :class="element.fa5Icon"></i>
        </div>
        <div class="formbuilder__element__title">
          {{element.title}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

// COMPOSABLES
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';

// CONSTANTS
import focusedEntityTypes from '@/shared/constants/formbuilder/focusedEntityTypes';

export default {
  setup() {
    const { formBuilder } = useFormBuilder();

    return {
      formBuilder,
      focusedEntityTypes: ref(focusedEntityTypes),
    };
  },
  methods: {
    onDragStart(e, element) {
      e.dataTransfer.setData('element', JSON.stringify(element));
    },
  },
  computed: {
  },
};
</script>
