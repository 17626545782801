import lodash from 'lodash';
import FormStepQuizApi from '@/api/form/formStepQuiz';

export default {
  namespaced: true,
  state: {
    formStepQuiz: null,
  },
  getters: {
    formStepQuiz(state) {
      return state.formStepQuiz || [];
    },
    formStepItemOptions(state) {
      const options = [];

      if (!state.formStepQuiz) {
        return options;
      }

      state.formStepQuiz.form_steps.forEach((step) => {
        step.form_step_items.forEach((stepItem) => {
          options.push({
            label: lodash.find(stepItem.config.settings.controls, { name: 'label' }).value,
            value: stepItem.id,
            stepId: step.id,
          });
        });
      });

      return options;
    },
  },
  actions: {
    getFormStepQuiz(_, data) {
      return FormStepQuizApi.show(data);
    },
    saveFormStepQuiz(_, data) {
      return FormStepQuizApi.store(data);
    },
    setFormStepQuiz(context, data) {
      context.commit('setFormStepQuiz', data);
    },
    addFormStepQuiz(context, data) {
      context.commit('addFormStepQuiz', data);
    },
    deleteFormStepQuiz(context, data) {
      context.commit('deleteFormStepQuiz', data);
    },
    deleteFormStepQuizConditionTerm(context, data) {
      context.commit('deleteFormStepQuizConditionTerm', data);
    },
    deleteFormStepQuizConditionTermChoice(context, data) {
      context.commit('deleteFormStepQuizConditionTermChoice', data);
    },
    addFormStepQuizCondition(context, data) {
      context.commit('addFormStepQuizCondition', data);
    },
    updateFormStepQuizTermField(context, data) {
      context.commit('updateFormStepQuizTermField', data);
    },
    updateFormStepQuizPoints(context, data) {
      context.commit('updateFormStepQuizPoints', data);
    },
    updateFormStepQuizAction(context, data) {
      context.commit('updateFormStepQuizAction', data);
    },
  },
  mutations: {
    setFormStepQuiz(state, data) {
      state.formStepQuiz = data.result;
    },
    addFormStepQuiz(state, data) {
      const formStep = lodash.find(state.formStepQuiz.form_steps, { id: data.form_step_id });
      formStep.form_step_quizzes.push(data);
    },
    deleteFormStepQuiz(state, data) {
      const formStep = lodash.find(state.formStepQuiz.form_steps, { id: data.quiz.form_step_id });
      const formStepQuiz = formStep.form_step_quizzes[data.quizIndex];

      if (formStepQuiz.id) {
        formStepQuiz.deleted = true;
        return;
      }

      formStep.form_step_quizzes.splice(data.quizIndex, 1);
    },
    deleteFormStepQuizConditionTerm(state, data) {
      const formStep = lodash.find(state.formStepQuiz.form_steps, { id: data.quiz.form_step_id });
      const formStepQuiz = formStep.form_step_quizzes[data.quizIndex];

      formStepQuiz.action_config.condition.terms.splice(data.termIndex, 1);
    },
    deleteFormStepQuizConditionTermChoice(state, data) {
      const formStep = lodash.find(state.formStepQuiz.form_steps, { id: data.quiz.form_step_id });
      const formStepQuiz = formStep.form_step_quizzes[data.quizIndex];

      formStepQuiz.action_config.condition.terms[data.termIndex].choices
        .splice(data.termChoiceIndex, 1);
    },
    updateFormStepQuizPoints(state, data) {
      const formStep = lodash.find(state.formStepQuiz.form_steps, { id: data.quiz.form_step_id });
      const formStepQuiz = formStep.form_step_quizzes[data.quizIndex];
      formStepQuiz.action_config.points = data.points;
    },
    updateFormStepQuizAction(state, data) {
      const formStep = lodash.find(state.formStepQuiz.form_steps, { id: data.quiz.form_step_id });
      const formStepQuiz = formStep.form_step_quizzes[data.quizIndex];
      formStepQuiz.action = data.action;
    },
    addFormStepQuizCondition(state, data) {
      const formStep = lodash.find(state.formStepQuiz.form_steps, { id: data.quiz.form_step_id });
      const formStepQuiz = formStep.form_step_quizzes[data.quizIndex];
      formStepQuiz.action_config.condition.terms.push(data.term);
    },
    updateFormStepQuizTermField(state, data) {
      const formStep = lodash.find(state.formStepQuiz.form_steps, { id: data.quiz.form_step_id });
      const formStepQuiz = formStep.form_step_quizzes[data.quizIndex];
      const term = formStepQuiz.action_config.condition.terms[data.termIndex];

      if (data.fieldName === 'logicOperator') {
        formStepQuiz.action_config.condition.terms.forEach((t) => {
          // eslint-disable-next-line no-param-reassign
          t[data.fieldName] = data.fieldValue;
        });

        return;
      }

      term.value = null;
      term.choices = [];
      term[data.fieldName] = data.fieldValue;
    },
  },
};
