import formTemplateCategoryApi from '@/api/formTemplateCategory';

export default {
  namespaced: true,
  state: {
    categories: [],
  },
  getters: {
    formTemplateCategories(state) {
      return state.categories;
    },
  },
  actions: {
    getFormTemplateCategories(context, data) {
      return formTemplateCategoryApi.getFormTemplateCategories(data);
    },
    setFormTemplateCategories(context, data) {
      context.commit('setFormTemplateCategories', data);
    },
  },
  mutations: {
    setFormTemplateCategories(state, data) {
      state.categories = data;
    },
  },
};
