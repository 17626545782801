<template>
  <div class="form-list-view">
    <div class="form-list-view__header">
      <div class="container">
        <div class="form-list-view__header__content">
          <h3>Customers</h3>
        </div>
      </div>
    </div>
    <div class="form-list-view__body">
      <div class="container">
        <div class="form-list-view-table">
          <n-data-table
            remote
            :loading="loadingCustomers"
            ref="table"
            :columns="tableColumns"
            :data="tableData"
            :pagination="tablePagination"
            @update:page="handlePageChange"
            @update:page-size="handlePageSizeChange"
          />
        </div>
      </div>
    </div>
    <div class="form-list-view__footer">
    </div>
  </div>
</template>

<script>
import { h } from 'vue';
import dayjs from 'dayjs';
import { NButton, useDialog } from 'naive-ui';
// COMPOSABLES
import useCustomer from '@/composables/admin/useCustomer';
import useGlobals from '@/composables/useGlobals';
import useAuth from '@/composables/useAuth';

export default {
  setup() {
    const {
      routeNames,
    } = useGlobals();

    const {
      loadingCustomers,
      changingDefaultPlan,

      changeDefaultPlan,
      customers,
      customersPagination,
      loadCustomers,
    } = useCustomer();

    const {
      authUser,
    } = useAuth();

    const dialog = useDialog();

    return {
      customers,
      customersPagination,
      loadingCustomers,
      loadCustomers,
      changingDefaultPlan,
      changeDefaultPlan,

      authUser,

      routeNames,

      dialog,
    };
  },
  mounted() {
    this.loadCustomers();
  },
  methods: {
    handlePageChange(page) {
      this.loadCustomers({ params: { page } });
    },
    handlePageSizeChange(pageSize) {
      this.loadCustomers({ params: { page_size: pageSize } });
    },
    makeUltimate(row, ultimate) {
      this.dialog.warning({
        title: ultimate ? 'Upgrade to Ultimate plan' : 'Downgrade to Free plan',
        content: ultimate
          ? `Do you want to upgrade ${row.first_name} ${row.last_name} (${row.email}) to Ultimate plan?`
          : `Do you want to downgrade ${row.first_name} ${row.last_name} (${row.email}) to Free plan?`,
        positiveText: ultimate ? 'Upgrade' : 'Downgrade',
        onPositiveClick: () => {
          this.changeDefaultPlan({
            userId: row.id,
            plan: ultimate ? 'ultimate' : 'free',
          }, (success) => {
            if (!success) {
              return;
            }

            this.loadCustomers();
          });
        },
      });
    },
  },
  computed: {
    tableData() {
      return this.customers.map((form) => ({
        ...form,
        key: form.id,
        updated_at: dayjs(form.update_at).format('MM-DD-YYYY HH:mm:ss a'),
      }));
    },
    tablePagination() {
      return {
        page: this.customersPagination ? this.customersPagination.current_page : 1,
        pageSize: this.customersPagination ? Number(this.customersPagination.per_page) : 10,
        showSizePicker: true,
        pageSizes: [10, 50, 100],
        pageCount: this.customersPagination ? this.customersPagination.last_page : 0,
        itemCount: this.customersPagination ? this.customersPagination.total : 0,
      };
    },
    tableColumns() {
      const self = this;
      return [
        {
          title: 'First Name',
          render(row) {
            return h(
              'div',
              row.first_name,
            );
          },
        },
        {
          title: 'Last Name',
          render(row) {
            return h(
              'div',
              row.last_name,
            );
          },
        },
        {
          title: 'Email',
          render(row) {
            return h(
              'div',
              row.email,
            );
          },
        },
        {
          title: 'Plan',
          render(row) {
            return [
              h(
                'div',
                row.plan.title,
              ),
              {
                free: h(
                  NButton,
                  {
                    strong: true,
                    type: 'primary',
                    size: 'small',
                    onClick: () => self.makeUltimate(row, true),
                  },
                  { default: () => 'Upgrade To Ultimate' },
                ),
                ultimate: h(
                  NButton,
                  {
                    strong: true,
                    type: 'warning',
                    size: 'small',
                    onClick: () => self.makeUltimate(row, false),
                  },
                  { default: () => 'Downgrade To Free' },
                ),
              }[row.plan.slug] || null,
            ];
          },
        },
        {
          title: 'Subscription',
          render(row) {
            if (!row.plan.subscription) {
              return 'Nil';
            }

            return row.plan.subscription.paddle_status;
          },
        },
        {
          title: 'Forms',
          render(row) {
            return row.forms_count;
          },
        },
        {
          title: 'Created At',
          render(row) {
            return dayjs(row.created_at).format('D MMM YYYY hh:mm:ss A');
          },
        },
      ];
    },
  },
};
</script>
