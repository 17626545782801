import axios from '@/api/axios';

const index = (data = {}) => axios.get('/forms', { params: data?.params || {} });

const show = (data = {}) => axios.get(`/forms/${data.formId}`, { params: data?.params || {} });

const destroy = (formId) => axios.delete(`/forms/${formId}`);

const clone = (formId, data) => axios.post(`/forms/${formId}/clone`, data);

const getFieldMapping = (formId, data) => axios
  .get(`/forms/${formId}/field-mapping`, { params: data?.params || {} });

export default {
  index,
  destroy,
  clone,
  show,
  getFieldMapping,
};
