import axios from '@/api/axios';

const upload = (data = {}) => axios.post('/media/upload', data.data || {}, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  params: data?.params || {},
});

export default {
  upload,
};
