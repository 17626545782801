<template>
  <div class="form-integrations-cpt" v-if="form">
    <div class="form-integrations-cpt__header">
      <div class="container">
        <div class="form-integrations-cpt__header__content">
          <h3 v-if="form"><u>{{form.title}}</u> Integrations</h3>
        </div>
      </div>
    </div>
    <div class="form-integrations-cpt__body">
      <div class="container">
        <div class="form-integrations-table">
          <div class="form-integrations-table__header">
            <n-button type="primary" @click="addIntegration">
              <n-icon size="large"><add-outline /></n-icon> Integration</n-button>
          </div>
          <div class="form-integrations-table__body">
            <n-data-table
              remote
              :loading="loadingIntegrations"
              ref="table"
              :columns="tableColumns"
              :data="tableData"
              :pagination="tablePagination"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="form-integrations-cpt__footer">
    </div>
  </div>
</template>

<script>
import { ref, h } from 'vue';
import {
  AddOutline,
  CreateOutline,
} from '@vicons/ionicons5';
import { NButton, NIcon } from 'naive-ui';
// COMPOSABLES
import useForm from '@/composables/form/useForm';
import useAuth from '@/composables/useAuth';
import useGlobals from '@/composables/useGlobals';
import useFormIntegration from '@/composables/form/useFormIntegration';
// CONSTANTS
import formIntegrationTypes from '@/shared/constants/formIntegrationTypes';
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';

export default {
  setup() {
    const {
      routeNames,
    } = useGlobals();

    const {
      hasWebhookFeature,
    } = useAuth();

    const {
      loadFormBuilderState,
    } = useFormBuilder();

    const { getForm } = useForm();

    const { getFormIntegrations } = useFormIntegration();

    return {
      routeNames,

      hasWebhookFeature,

      loadingIntegrations: ref(false),

      form: ref(null),
      formIntegrations: ref([]),
      getForm,

      getFormIntegrations,

      loadFormBuilderState,
    };
  },
  components: {
    AddOutline,
  },
  mounted() {
    this.loadFormBuilderState(this.formId, () => {
      this.getForm({ formId: this.formId }, (success, result) => {
        if (!success) {
          return;
        }

        this.form = result.result;
      });
    });

    this.getFormIntegrations({ formId: this.formId }, (success, response) => {
      if (!success) {
        return;
      }

      this.formIntegrations = response.data.result;
    });
  },
  methods: {
    addIntegration() {
      this.$router.push({
        name: this.routeNames.form.integrations.select,
        params: { formId: this.form.id },
      });
    },
    renderTitle(row) {
      if (row.type === formIntegrationTypes.webhook) {
        return h(
          'div',
          {},
          [
            h('div', {}, row.payload.title),
            h('div', {}, row.payload.url),
          ],
        );
      }

      return row.payload.title;
    },
    hasIntegrationAccess(row) {
      if (row.type === 'webhook') {
        return this.hasWebhookFeature();
      }

      return false;
    },
  },
  computed: {
    tableData() {
      return this.formIntegrations;
    },
    tableColumns() {
      const self = this;

      return [
        {
          title: 'Title',
          render(row) {
            return h(
              'div',
              {},
              self.renderTitle(row),
            );
          },
        },
        {
          title: 'Type',
          render(row) {
            return h(
              'div',
              {},
              row.type.toUpperCase(),
            );
          },
        },
        {
          title: 'Status',
          render(row) {
            return h(
              NButton,
              {
                strong: true,
                secondary: true,
                type: row.payload.active && self.hasIntegrationAccess(row) ? 'success' : 'error',
              },
              { default: () => (row.payload.active && self.hasIntegrationAccess(row) ? 'Active' : 'Deactivated') },
            );
          },
        },
        {
          title: 'Actions',
          render(row) {
            const upgradePlanBtn = h(
              'div',
              { class: 'row-actions' },
              [
                h(
                  NButton,
                  {
                    type: 'warning',
                    size: 'small',
                    onClick: () => self.$router.push({
                      name: self.routeNames.plan.upgrade,
                    }),
                    title: 'Edit',
                    style: 'margin-right: 5px;',
                  },
                  { default: () => ['Upgrade Plan'] },
                ),
              ],
            );

            if (!self.hasIntegrationAccess(row)) {
              return upgradePlanBtn;
            }

            return h(
              'div',
              { class: 'row-actions' },
              [
                h(
                  NButton,
                  {
                    size: 'small',
                    onClick: () => self.$router.push({
                      name: self.routeNames.form.integrations.edit,
                      params: {
                        formId: self.formId,
                        integrationId: row.payload.id,
                        type: row.type,
                      },
                    }),
                    title: 'Edit',
                    style: 'margin-right: 5px;',
                  },
                  { default: () => ['Edit', h(NIcon, { size: 20 }, { default: () => h(CreateOutline) })] },
                ),
                h(
                  NButton,
                  {
                    size: 'small',
                    type: 'error',
                    onClick: () => self.showDeleteConfirmModal(row.id),
                    title: 'Delete',
                    style: 'margin-right: 5px;',
                  },
                  { default: () => ['Delete'] },
                ),
              ],
            );
          },
        },
      ];
    },
    tablePagination() {
      return false;
    },
    formId() {
      return this.$route.params.formId;
    },
  },
};
</script>
