import { computed } from 'vue';
import { useStore } from 'vuex';

export default function () {
  const store = useStore();

  const routeNames = computed(() => store.getters.routeNames);

  return {
    routeNames,
  };
}
