import { useStore } from 'vuex';

export default function () {
  const store = useStore();

  // METHODS
  const getFormIntegrations = (data, callback) => {
    store.dispatch('formIntegration/getFormIntegrations', data).then((response) => {
      if (callback) {
        callback(true, response);
      }
    }).catch((error) => {
      if (callback) {
        callback(false, error.response);
      }
    });
  };

  const saveFormIntegration = (data, callback) => {
    let action = 'formIntegration/saveFormIntegration';
    if (data.editMode) {
      action = 'formIntegration/updateFormIntegration';
    }

    store.dispatch(action, data).then((response) => {
      if (callback) {
        callback(true, response);
      }
    }).catch((error) => {
      if (callback) {
        callback(false, error.response);
      }
    });
  };

  const getFormIntegration = (data, callback) => {
    store.dispatch('formIntegration/getFormIntegration', data).then((response) => {
      if (callback) {
        callback(true, response);
      }
    }).catch((error) => {
      if (callback) {
        callback(false, error.response);
      }
    });
  };

  return {
    saveFormIntegration,
    getFormIntegrations,
    getFormIntegration,
  };
}
