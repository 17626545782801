import axios from '@/api/axios';

const getFormTemplates = (data = {}) => axios
  .get('/form-template-chooser', { params: data.params || {} });

const createFormFromTemplate = (data = {}) => axios
  .get(`/form-template-chooser/create-form/${data.formTemplateId}`, { params: data.params || {} });

export default {
  getFormTemplates,
  createFormFromTemplate,
};
