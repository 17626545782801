<template>
  <div class="formbuilder__settings-panel">
    <div class="formbuilder__settings-panel__header">
      <div v-if="!focusedEntity">Builder</div>
      <div class="formbuilder__settings-panel__header__focused-title" v-else>
        <n-icon @click="focusEntity(null)" size="20">
          <arrow-back-icon />
        </n-icon>
        <span>{{focusedEntityTitle(focusedEntity)}}</span>
      </div>
    </div>
    <div class="formbuilder__settings-panel__body">
      <focused-entity-tab v-if="focusedEntity" />
      <elements-tab v-else/>
    </div>
    <div class="formbuilder__settings-panel__footer">
      <div>
        <n-icon size="20" @click="() => focusFormGeneral()">
          <settings-icon />
        </n-icon>
      </div>
      <!-- <div>
        <n-icon size="20">
          <map-outline-icon />
        </n-icon>
      </div> -->
      <div>
         <n-button
          :disabled="!isEditView"
          @click="previewForm"
          size="small"
          style="width:90px;background:white">
            Preview&nbsp;
            <n-icon>
              <open-icon />
            </n-icon>
          </n-button>
      </div>
      <div>
        <n-button
          :disabled="isEmptyFormBuilder"
          :loading="savingFormBuilder"
          @click="() => save()"
          size="small"
          type="primary">Save</n-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { mapGetters } from 'vuex';
import {
  Settings as SettingsIcon,
  OpenOutline as OpenIcon,
  ArrowBackOutline as ArrowBackIcon,
} from '@vicons/ionicons5';

// COMPONENTS
import ElementsTab from '@/components/FormBuilder/SettingsPanel/Elements.vue';
import FocusedEntityTab from '@/components/FormBuilder/SettingsPanel/FocusedEntity/FocusedEntity.vue';

// COMPOSABLES
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';

// CONSTANTS
import focusedEntityTypes from '@/shared/constants/formbuilder/focusedEntityTypes';

export default {
  setup() {
    const {
      formBuilder,
      isEmptyFormBuilder,
      saveFormBuilder,
      savingFormBuilder,
      saveFormBuilderErrorMsg,
      focusEntity,
      loadFormBuilderState,
    } = useFormBuilder();

    return {
      isEmptyFormBuilder,
      formBuilder,
      saveFormBuilder,
      savingFormBuilder,
      saveFormBuilderErrorMsg,
      focusEntity,
      loadFormBuilderState,

      focusedEntityTypes: ref(focusedEntityTypes),
    };
  },
  components: {
    ElementsTab,
    FocusedEntityTab,

    SettingsIcon,
    OpenIcon,
    ArrowBackIcon,
  },
  methods: {
    previewForm() {
      const formId = this.formBuilder.form.id;

      let apiUrl = `${process.env.VUE_APP_FORM_DOMAIN_URL}/${formId}`;
      if (process.env.NODE_ENV === 'production') {
        window.open(apiUrl, '_blank');

        return;
      }

      apiUrl = encodeURIComponent('http://coreformapi.test');

      window.open(`http://localhost:8082/?formId=${formId}&apiUrl=${apiUrl}`);
    },
    onDragStart(e, element) {
      e.dataTransfer.setData('element', JSON.stringify(element));
    },
    focusedEntityTitle(entity) {
      if (entity.type === this.focusedEntityTypes.STEP_ITEM) {
        return `Edit ${entity.title}`;
      }

      if (entity.type === this.focusedEntityTypes.STEP_NAVIGATION) {
        return `Edit ${entity.title}`;
      }

      if (entity.type === this.focusedEntityTypes.FORM_GENERAL) {
        return `Edit ${entity.title}`;
      }

      return '';
    },
    focusFormGeneral() {
      this.focusEntity({
        title: 'Settings',
        type: focusedEntityTypes.FORM_GENERAL,
        value: null,
      });
    },
    save() {
      this.saveFormBuilder((data) => {
        if (!this.$route.params.id) {
          this.$router.push({
            name: this.routeNames.form.edit,
            params: { id: data.form.id },
          });
        } else {
          this.loadFormBuilderState(this.$route.params.id);
        }
      });
    },
  },
  computed: {
    ...mapGetters([
      'routeNames',
    ]),
    isEditView() {
      return this.$route.name === this.routeNames.form.edit;
    },
    focusedEntity() {
      return this.$store.getters['formBuilder/focusedEntity']('settingsPanel');
    },
  },
};
</script>
