import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import LoginApi from '@/api/login';
import SignupApi from '@/api/signup';
import ForgotPasswordApi from '@/api/forgotPassword';
import localStorageFields from '../../shared/constants/localStorageFields';

const loadAuthUser = () => {
  try {
    return JSON.parse(localStorage.getItem(localStorageFields.authUser));
  } catch (e) {
    console.log(e);
  }

  return null;
};

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem(localStorageFields.token) || null,
    authUser: loadAuthUser(),
    upgradePlanModal: {
      show: false,
    },
  },
  getters: {
    token(state) {
      return state.token;
    },
    authUser(state) {
      return state.authUser;
    },
    isLoggedIn(state) {
      if (!state.authUser || !state.token) {
        return false;
      }

      dayjs.extend(dayjsUtc);

      return state.authUser.exp > dayjs.utc().unix();
    },
    upgradePlanModal(state) {
      return state.upgradePlanModal;
    },
  },
  actions: {
    login(_, data) {
      return LoginApi.login(data);
    },
    refreshToken(_, data) {
      return LoginApi.refreshToken(data);
    },
    resendEmailVerification(_, data) {
      return SignupApi.resendEmailVerification(data);
    },
    sendPasswordLink(_, data) {
      return ForgotPasswordApi.sendPasswordLink(data);
    },
    resetPassword(_, data) {
      return ForgotPasswordApi.resetPassword(data);
    },
    loginSuccess({ commit }, data) {
      commit('loginSuccess', data);
    },
    logout({ commit }) {
      commit('logout');
    },
    toggleUpgradePlanModal({ commit }, data) {
      commit('showUpgradePlanModal', data);
    },
  },
  mutations: {
    loginSuccess(state, token) {
      state.token = token;
      state.authUser = jwtDecode(state.token);

      localStorage.setItem(localStorageFields.token, state.token);
      localStorage.setItem(localStorageFields.authUser, JSON.stringify(state.authUser));
    },
    logout(state) {
      localStorage.removeItem(localStorageFields.token);
      localStorage.removeItem(localStorageFields.authUser);

      state.token = null;
      state.authUser = null;
    },
    showUpgradePlanModal(state, data) {
      state.upgradePlanModal.show = data.show;
    },
  },
};
