<template>
  <n-modal
    v-model:show="showModal"
    :mask-closable="false"
    preset="dialog"
    title="Upgrade your current plan"
    negative-text="Close"
    positive-text="Upgrade"
    @positive-click="onPositiveClick"
    @negative-click="onNegativeClick"
    class="upgrade-plan-modal-cpt"
    :closable="false"
  >
    <n-card title="">
      Your current plan has either exceeded the limit of this feature or this feature isn't included
      in your current plan. Please go to plans page to upgrade your plan.
    </n-card>
  </n-modal>
</template>

<script>
import { ref } from 'vue';
import { mapGetters } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {
    return {
      showModal: ref(false),
    };
  },
  mounted() {
    this.showModal = this.show;
  },
  methods: {
    onNegativeClick() {
      this.$emit('show', false);
    },
    onPositiveClick() {
      this.$router.push({ name: this.routeNames.plan.upgrade });
    },
  },
  computed: {
    ...mapGetters(['routeNames']),
  },
  watch: {
    show(value) {
      this.showModal = value;
    },
  },
};
</script>
