import axios from '@/api/axios';

const login = (data) => axios.post('/login', data);
const refreshToken = (data = {}) => axios.get('/refresh-token', {
  params: data.params || {},
});

export default {
  login,
  refreshToken,
};
