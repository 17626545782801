<template>
  <div class="template-builder-create-view">
    <div class="template-builder-create-view__body">
      <div class="container">
        <div class="template-builder-create-view__body__content">
          <div class="spinner-wrap" v-if="loadingFormTemplate || !formTemplate">
            <n-spin size="large" />
          </div>
          <create-edit-form
            :formTemplate="formTemplate"
            :formTemplateIndustries="formTemplateIndustries"
            :formTemplateCategories="formTemplateCategories"
            @updated="onEdit"
            v-else/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { mapGetters } from 'vuex';
import CreateEditForm from './Form.vue';
import useFormTemplate from '@/composables/form/useFormTemplate';
import useFormTemplateIndustry from '@/composables/form/useFormTemplateIndustry';
import useFormTemplateCategory from '@/composables/form/useFormTemplateCategory';

export default {
  setup() {
    const {
      loadingFormTemplate,
      getFormTemplate,
    } = useFormTemplate();

    const {
      formTemplateIndustries,
      getFormTemplateIndustries,
      setFormTemplateIndustries,
    } = useFormTemplateIndustry();

    const {
      formTemplateCategories,
      getFormTemplateCategories,
      setFormTemplateCategories,
    } = useFormTemplateCategory();

    return {
      formTemplate: ref(null),
      formTemplateCategories,
      getFormTemplateCategories,
      setFormTemplateCategories,

      loadingFormTemplate,
      formTemplateIndustries,
      getFormTemplateIndustries,
      setFormTemplateIndustries,
      getFormTemplate,
    };
  },
  mounted() {
    this.getFormTemplateIndustries({}, (success, response) => {
      if (!success) {
        return;
      }

      this.setFormTemplateIndustries(response.data.result);
    });

    this.getFormTemplateCategories({}, (success, response) => {
      if (!success) {
        return;
      }

      this.setFormTemplateCategories(response.data.result);
    });

    this.getFormTemplate({ formTemplateId: this.$route.params.id }, (success, response) => {
      if (!success) {
        return;
      }

      this.formTemplate = response.data.result;
    });
  },
  methods: {
    onEdit() {
      this.$router.push({ name: this.routeNames.templateBuilder.index });
    },
  },
  components: {
    CreateEditForm,
  },
  computed: {
    ...mapGetters(['routeNames']),
  },
};
</script>
