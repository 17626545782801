<template>
  <n-modal
    v-model:show="showModal"
    :mask-closable="false"
    preset="dialog"
    title="Embed or share your form"
    negative-text="Close"
    @negative-click="onNegativeClick"
    class="embed-form-modal-cpt"
    :closable="false"
  >
    <n-card title="">
      <n-tabs type="line" animated>
        <n-tab-pane name="website-emebd" tab="Embed on Website">
          <n-space vertical :size="16">
            <n-code
              :code="code"
              language="javascript"
              :word-wrap="true"
            />
            <n-button @click="copyCode">{{copied ? 'Copied!' : 'Copy Code' }}</n-button>
          </n-space>
        </n-tab-pane>
        <n-tab-pane name="social-share" tab="Share on Social Sites">
          <div class="social-share">
            <a target="_blank" :href="'https://twitter.com/intent/tweet?text='+ encodeURIComponent(formLink)">
              <span class="fab fa-twitter"></span>
            </a>
            <a target="_blank" :href="'https://www.facebook.com/sharer/sharer.php?u='+ encodeURIComponent(formLink)">
              <span class="fab fa-facebook"></span>
            </a>
            <a target="_blank" :href="'https://www.linkedin.com/shareArticle?mini=true&url='+ encodeURIComponent(formLink)">
              <span class="fab fa-linkedin"></span>
            </a>
          </div>
        </n-tab-pane>
      </n-tabs>
    </n-card>
  </n-modal>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    formId: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup() {
    return {
      showModal: ref(false),
      copied: ref(false),
    };
  },
  mounted() {
    this.showModal = this.show;
  },
  methods: {
    onNegativeClick() {
      this.$emit('show', false);
    },
    copyCode() {
      window.navigator.clipboard.writeText(this.code);

      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 10000);
    },
  },
  computed: {
    code() {
      let script = `
<script
  id="coreform-embedder-${this.formId}"
  src="${process.env.VUE_APP_SCRIPT_DOMAIN_URL}/embed/${this.formId}">
`;
      script += '</';
      script += 'script>';

      return script;
    },
    formLink() {
      return `${process.env.VUE_APP_FORM_DOMAIN_URL}/${this.formId}`;
    },
  },
  watch: {
    show(value) {
      this.showModal = value;
    },
  },
};
</script>
