export default {
  lato: {
    label: 'Lato',
    name: '\'Lato\', sans-serif',
    link: 'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap',
  },
  opensans: {
    label: 'Open Sans',
    name: '\'Open Sans\', sans-serif',
    link: 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
  },
  roboto: {
    label: 'Roboto',
    name: '\'Roboto\', sans-serif',
    link: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
  },
  raleway: {
    label: 'Raleway',
    name: '\'Raleway\', sans-serif',
    link: 'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap',
  },
};
