<template>
  <div class="formbuilder flex">
    <settings-panel v-if="formBuilder" />
    <div class="formbuilder__content" @click="onContentClick">
      <div class="formbuilder__content__header">
        <n-breadcrumb>
          <n-breadcrumb-item @click="$router.push({name: routeNames.form.index})">
            <n-icon><arrow-back-outline /></n-icon> Back To Forms
          </n-breadcrumb-item>
        </n-breadcrumb>
        <div
          class="formbuilder__content__header__actions"
          v-if="$route.name !== routeNames.form.create">
          <n-button type="primary">
            Builder
          </n-button>
          <n-button
            v-if="enableLogic"
            type="primary"
            @click="goToLogicPage"
            ghost>
            Logic
          </n-button>
          <n-button
            v-if="enableQuiz"
            type="primary"
            @click="goToQuizPage"
            ghost>
            Quiz
          </n-button>
          <n-button
            type="primary"
            @click="goToResponesPage"
            ghost>
            Responses
          </n-button>
          <n-button type="primary" @click="goToIntegrationsPage" ghost>
            Integrate
          </n-button>
          <n-button type="primary" @click="showEmbedModal" ghost>
            Embed
          </n-button>
        </div>
      </div>
      <div class="formbuilder__content__body">
        <preview v-if="formBuilder"></preview>
      </div>
    </div>
    <div class="formbuilder__rightsidebar">
      <div class="formbuilder__rightsidebar__header">
        <div
          :class="{active: rightsidebarTab === 'appearance'}"
          @click="() => setActiveRightSidebarTab('appearance')"><strong>APPEARANCE</strong></div>
        <!-- <div
          :class="{active: rightsidebarTab === 'themes'}"
          @click="() => setActiveRightSidebarTab('themes')">Themes</div>
        <div
          :class="{active: rightsidebarTab === 'navigation'}"
          @click="() => setActiveRightSidebarTab('navigation')">Navigation</div> -->
      </div>
      <div class="formbuilder__rightsidebar__body">
        <appearance v-if="rightsidebarTab === 'appearance'" />
        <navigator v-if="rightsidebarTab === 'navigation'" />
      </div>
    </div>
    <!-- MODAS -->
    <embed-form-modal
      :show="embedModel.show"
      :form-id="embedModel.formId"
      @show="onEmbedModalShowChange"/>
  </div>
</template>

<script>
import { ref } from 'vue';
import {
  ArrowBackOutline,
} from '@vicons/ionicons5';
import _ from 'lodash';

// COMPOSABLES
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';
import useAuth from '@/composables/useAuth';

// COMPONENTS
import SettingsPanel from '@/components/FormBuilder/SettingsPanel/SettingsPanel.vue';
import Navigator from '@/components/FormBuilder/Navigator/Navigator.vue';
import Appearance from '@/components/FormBuilder/Appearance/Appearance.vue';
import Preview from '@/components/FormBuilder/Preview/Preview.vue';
import useGlobals from '../../composables/useGlobals';
// MODALS
import EmbedFormModal from '@/components/Form/EmbedFormModal.vue';

export default {
  setup() {
    const { routeNames } = useGlobals();
    const { formBuilder, focusEntity } = useFormBuilder();
    const {
      hasConditionalLogicFeature,
      hasQuizFeature,
      toggleUpgradePlanModal,
    } = useAuth();

    return {
      formBuilder,
      focusEntity,

      routeNames,

      embedModel: ref({
        show: false,
        formId: '',
      }),

      hasConditionalLogicFeature,
      hasQuizFeature,
      toggleUpgradePlanModal,
    };
  },
  data() {
    return {
      rightsidebarTab: 'appearance',
    };
  },
  components: {
    SettingsPanel,
    Navigator,
    Preview,
    Appearance,

    ArrowBackOutline,

    EmbedFormModal,
  },
  methods: {
    showEmbedModal() {
      this.embedModel.show = true;
      this.embedModel.formId = this.formBuilder.form.id;
    },
    onEmbedModalShowChange(value) {
      this.embedModel.show = value;
    },
    setActiveRightSidebarTab(tab) {
      this.rightsidebarTab = tab;
    },
    onContentClick() {
      this.focusEntity(null);
    },
    goToIntegrationsPage() {
      this.$router.push({
        name: this.routeNames.form.integrations.index,
        params: { formId: this.$route.params.id },
      });
    },
    goToResponesPage() {
      this.$router.push({
        name: this.routeNames.formresult.index,
        params: { formId: this.$route.params.id },
      });
    },
    goToLogicPage() {
      if (!this.hasConditionalLogicFeature()) {
        this.toggleUpgradePlanModal({ show: true });

        return;
      }

      this.$router.push({
        name: this.routeNames.formlogic.index,
        params: { formId: this.$route.params.id },
      });
    },
    goToQuizPage() {
      if (!this.hasQuizFeature()) {
        this.toggleUpgradePlanModal({ show: true });

        return;
      }

      this.$router.push({
        name: this.routeNames.formquiz.index,
        params: { formId: this.$route.params.id },
      });
    },
  },
  computed: {
    enableQuiz() {
      return _.find(this.formBuilder.general.settings.controls, { name: 'enable_quiz' })?.value;
    },
    enableLogic() {
      return _.find(this.formBuilder.general.settings.controls, { name: 'enable_conditional_logic' })?.value;
    },
  },
};
</script>
