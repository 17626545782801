<template>
  <div class="template-builder-view">
    <div class="template-builder-view__header">
      <div class="container">
        <div class="template-builder-view__header__content">
          <h3>Templates</h3>
          <n-button type="primary" @click="createTemplate">Create Template</n-button>
        </div>
      </div>
    </div>
    <div class="template-builder-view__body">
      <div class="container">
        <div class="template-builder-view-table">
          <n-data-table
            remote
            :loading="loadingFormTemplates"
            ref="table"
            :columns="tableColumns"
            :data="tableData"
            :pagination="tablePagination"
            @update:page="handlePageChange"
            @update:page-size="handlePageSizeChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { h } from 'vue';
import dayjs from 'dayjs';
import { NButton, NEmpty } from 'naive-ui';
import { mapGetters } from 'vuex';
// COMPOSABLES
import useGlobals from '@/composables/useGlobals';
import useFormTemplate from '@/composables/form/useFormTemplate';

export default {
  setup() {
    const { routeNames } = useGlobals();
    const {
      loadingFormTemplates,
      formTemplatesPagination,
      loadFormTemplates,
      formTemplates,
    } = useFormTemplate();

    return {
      routeNames,

      formTemplates,
      formTemplatesPagination,
      loadingFormTemplates,
      loadFormTemplates,
    };
  },
  mounted() {
    this.loadFormTemplates({});
  },
  methods: {
    createTemplate() {
      this.$router.push({ name: this.routeNames.templateBuilder.create });
    },
    handlePageChange(page) {
      this.loadFormTemplates({ params: { page } });
    },
    handlePageSizeChange(pageSize) {
      this.loadFormTemplates({ params: { page_size: pageSize } });
    },
  },
  computed: {
    ...mapGetters(['routeNames']),
    tableData() {
      return this.formTemplates.map((formTemplate) => ({
        ...formTemplate,
        key: formTemplate.id,
        updated_at: dayjs(formTemplate.update_at).format('MM-DD-YYYY HH:mm:ss a'),
      }));
    },
    tablePagination() {
      return {
        page: this.formTemplatesPagination ? this.formTemplatesPagination.current_page : 1,
        pageSize: this.formTemplatesPagination ? Number(this.formTemplatesPagination.per_page) : 10,
        showSizePicker: true,
        pageSizes: [10, 50, 100],
        pageCount: this.formTemplatesPagination ? this.formTemplatesPagination.last_page : 0,
        itemCount: this.formTemplatesPagination ? this.formTemplatesPagination.total : 0,
      };
    },
    tableColumns() {
      const self = this;
      return [
        {
          title: 'Title',
          key: 'title',
          render(row) {
            return h(
              'div',
              {
                class: 'theme-color cursor-pointer',
                onClick: () => {
                  self.$router.push({
                    name: self.routeNames.templateBuilder.edit,
                    params: { id: row.id },
                  });
                },
              },
              row.title,
            );
          },
        },
        {
          title: 'Form',
          render(row) {
            return h(
              'a',
              {
                class: 'theme-color',
                href: `/builder/${row.form.id}/edit`,
              },
              row.form.title,
            );
          },
        },
        {
          title: 'Thumbnail',
          render(row) {
            if (!row.thumbnail) {
              return h(
                NEmpty,
                {
                  description: 'No Image Found',
                },
              );
            }

            return h(
              'img',
              {
                style: {
                  width: '100px',
                  height: '100px',
                  objectFit: 'cover',
                  margin: 'auto',
                },
                src: row.thumbnail.thumbnail_url,
              },
            );
          },
        },
        {
          title: 'Categories',
          render(row) {
            return row.form_template_categories.map((ftc) => ftc.title).join(', ');
          },
        },
        {
          title: 'Industries',
          render(row) {
            return row.form_template_industries.map((fti) => fti.title).join(', ');
          },
        },
        {
          title: 'Published',
          render(row) {
            return parseInt(row.published, 10) ? 'Yes' : 'No';
          },
        },
        {
          title: 'Actions',
          render(row) {
            return [
              h(
                NButton,
                {
                  onClick: () => {
                    self.$router.push({
                      name: self.routeNames.templateBuilder.edit,
                      params: { id: row.id },
                    });
                  },
                },
                'Edit',
              ),
            ];
          },
        },
      ];
    },
  },
};
</script>
