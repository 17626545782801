<template>
  <div class="form-logic-view">
    <div class="form-logic-view__header">
      <div class="container">
        <div class="form-logic-view__header__content">
          <h3 v-if="form"><u>{{form.title}}</u> Logic</h3>
        </div>
      </div>
    </div>
    <div class="form-logic-view__body">
      <div class="container" v-if="form">
        <form-logic :form="form"/>
      </div>
    </div>
    <div class="form-logic-view__footer">
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

// COMPONENTS
import FormLogic from '@/components/Form/FormLogic/FormLogic.vue';

// COMPOSABLES
import useForm from '@/composables/form/useForm';
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';

export default {
  setup() {
    const { getForm } = useForm();

    const {
      loadFormBuilderState,
    } = useFormBuilder();

    return {
      form: ref(null),
      getForm,

      loadFormBuilderState,
    };
  },
  mounted() {
    this.loadFormBuilderState(this.formId, () => {
      this.getForm({ formId: this.formId }, (success, result) => {
        if (!success) {
          return;
        }

        this.form = result.result;
      });
    });
  },
  components: {
    FormLogic,
  },
  computed: {
    formId() {
      return this.$route.params.formId;
    },
  },
};
</script>
