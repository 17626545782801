import formTemplateChooserApi from '@/api/formTemplateChooser';

export default {
  namespaced: true,
  state: {
    templates: [],
    pagination: null,
    categories: [],
    industries: [],
    filters: {
      layout: 'multiple',
      type: 'Categories',
      categories: [],
      industries: [],
      search: null,
    },
  },
  getters: {
    templates(state) {
      return state.templates;
    },
    filters(state) {
      return state.filters;
    },
    categories(state) {
      return state.categories;
    },
    industries(state) {
      return state.industries;
    },
    pagination(state) {
      return state.pagination;
    },
  },
  actions: {
    getFormTemplates(context, data) {
      return formTemplateChooserApi.getFormTemplates(data);
    },
    setFormTemplates(context, data) {
      context.commit('setFormTemplates', data);
    },
    updateState(context, data) {
      context.commit('updateState', data);
    },
    clearFilters(context, data) {
      context.commit('clearFilters', data);
    },
    createFormFromTemplate(context, data) {
      return formTemplateChooserApi.createFormFromTemplate(data);
    },
  },
  mutations: {
    setFormTemplates(state, data) {
      state.templates = data.result;
      state.pagination = data.pagination;
    },
    updateState(state, data) {
      if (data.field.split('.').length > 1) {
        const parts = data.field.split('.');
        state[parts[0]][parts[1]] = data.value;

        return;
      }

      state[data.field] = data.value;
    },
    clearFilters(state) {
      state.filters = {
        layout: 'multiple',
        type: 'Categories',
        categories: [],
        industries: [],
        search: null,
      };
    },
  },
};
