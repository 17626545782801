import { computed } from 'vue';
import { useStore } from 'vuex';

export default function () {
  const store = useStore();

  // COMPUTED
  const formTemplateIndustries = computed(() => store.getters['formTemplateIndustry/formTemplateIndustries']);

  // METHODS
  const setFormTemplateIndustries = (data) => store.dispatch('formTemplateIndustry/setFormTemplateIndustries', data);

  const getFormTemplateIndustries = (data, callback) => {
    store.dispatch('formTemplateIndustry/getFormTemplateIndustries', data).then((response) => {
      console.log(response);
      if (callback) {
        callback(true, response);
      }
    }).catch((error) => {
      console.log(error);
      if (callback) {
        callback(false, error);
      }
    });
  };

  return {
    formTemplateIndustries,

    getFormTemplateIndustries,
    setFormTemplateIndustries,
  };
}
