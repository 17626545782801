import { useStore } from 'vuex';

export default function () {
  const store = useStore();

  // METHODS.
  const uploadMedia = (data, callback) => {
    store.dispatch('media/upload', data)
      .then((response) => {
        if (callback) {
          callback(true, response);
        }
      }).catch((error) => {
        console.log(error);

        if (callback) {
          callback(false, error.response);
        }
      });
  };

  return {
    uploadMedia,
  };
}
