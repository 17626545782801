import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export default function () {
  const store = useStore();

  // REFS
  const loadingFormTemplates = ref(false);
  const loadingFormTemplatesErrMsg = ref('');
  const creatingFormTemplate = ref(false);
  const updatingFormTemplate = ref(false);
  const loadingFormTemplate = ref(false);
  const loadingFormTemplateErrMsg = ref('');

  // COMPUTED
  const formTemplates = computed(() => store.getters['formTemplate/formTemplates']);
  const formTemplatesPagination = computed(() => store.getters['formTemplate/formTemplatesPagination']);

  // METHODS
  const loadFormTemplates = (data) => {
    loadingFormTemplates.value = true;

    store.dispatch('formTemplate/getFormTemplates', data).then((response) => {
      loadingFormTemplates.value = false;
      store.dispatch('formTemplate/setFormTemplates', response.data);
    }).catch((error) => {
      loadingFormTemplates.value = false;
      loadingFormTemplatesErrMsg.value = error.response.meta.error_message;
    });
  };

  const getFormTemplate = (data, callback) => {
    loadingFormTemplate.value = true;

    store.dispatch('formTemplate/getFormTemplate', data).then((response) => {
      loadingFormTemplate.value = false;
      if (callback) {
        callback(true, response);
      }
    }).catch((error) => {
      loadingFormTemplate.value = false;
      loadingFormTemplateErrMsg.value = error.response.meta.error_message;
      if (callback) {
        callback(true, error);
      }
    });
  };

  const storeFormTemplate = (data, callback) => {
    creatingFormTemplate.value = true;
    store.dispatch('formTemplate/storeFormTemplate', data).then((response) => {
      creatingFormTemplate.value = false;
      if (callback) {
        callback(true, response);
      }
    }).catch((error) => {
      creatingFormTemplate.value = false;
      console.log(error);
      if (callback) {
        callback(false, error);
      }
    });
  };

  const updateFormTemplate = (data, callback) => {
    updatingFormTemplate.value = true;
    store.dispatch('formTemplate/updateFormTemplate', data).then((response) => {
      updatingFormTemplate.value = false;
      if (callback) {
        callback(true, response);
      }
    }).catch((error) => {
      updatingFormTemplate.value = false;
      console.log(error);
      if (callback) {
        callback(false, error);
      }
    });
  };

  return {
    formTemplates,
    formTemplatesPagination,
    creatingFormTemplate,
    updatingFormTemplate,
    getFormTemplate,
    storeFormTemplate,
    loadFormTemplates,
    updateFormTemplate,
  };
}
