<template>
  <div class="form-list-view">
    <div class="form-list-view__header">
      <div class="container">
        <div class="form-list-view__header__content">
          <h3>Forms</h3>
          <div>
            <n-button type="primary" @click="chooseTemplate">Choose Template</n-button>
            <n-button type="default" @click="createForm">Create Blank Form</n-button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-list-view__body">
      <div class="container">
        <div class="form-list-view-table">
          <n-data-table
            remote
            :loading="loadingForms"
            ref="table"
            :columns="tableColumns"
            :data="tableData"
            :pagination="tablePagination"
            @update:page="handlePageChange"
            @update:page-size="handlePageSizeChange"
          />
        </div>
      </div>
    </div>
    <div class="form-list-view__footer">

    </div>
    <!-- MODALS -->
    <delete-form-model
      :deleteFormModel="deleteFormModel"
      @cancelDeleteForm="cancelDeleteForm"
      @deleteForm="deleteForm"
      v-if="deleteFormModel.show"
    />
    <clone-form-model
      :cloneFormModel="cloneFormModel"
      @cancelCloneForm="cancelCloneForm"
      @cloneForm="cloneForm"
      @updateFormField="updateCloneFormField"
      v-if="cloneFormModel.show"
    />
    <embed-form-modal
      :show="embedModel.show"
      :form-id="embedModel.formId"
      @show="onEmbedModalShowChange"/>
  </div>
</template>

<script>
import { h, ref } from 'vue';
import { NButton, NDropdown, NIcon } from 'naive-ui';
import dayjs from 'dayjs';
import {
  OpenOutline,
  EllipsisVerticalOutline,
} from '@vicons/ionicons5';

// COMPOSABLES
import useForm from '@/composables/form/useForm';
import useGlobals from '@/composables/useGlobals';
import useAuth from '@/composables/useAuth';

// MODALS
import DeleteFormModel from './modals/DeleteFormModel.vue';
import CloneFormModel from './modals/CloneFormModel.vue';
import EmbedFormModal from '@/components/Form/EmbedFormModal.vue';

export default {
  setup() {
    const {
      routeNames,
    } = useGlobals();

    const {
      hasPlanFeature,
      toggleUpgradePlanModal,
      hasFormLimitFeature,
    } = useAuth();

    const {
      loadingForms,
      forms,
      formsPagination,
      loadForms,
    } = useForm();

    return {
      forms,
      formsPagination,
      loadingForms,
      loadForms,

      hasPlanFeature,
      toggleUpgradePlanModal,
      hasFormLimitFeature,

      routeNames,

      deleteFormModel: ref({
        show: false,
        selected: null,
        deleting: false,
      }),
      cloneFormModel: ref({
        title: '',
        show: false,
        selected: null,
        cloning: false,
      }),
      embedModel: ref({
        show: false,
        formId: '',
      }),
    };
  },
  components: {
    DeleteFormModel,
    CloneFormModel,

    EmbedFormModal,
  },
  mounted() {
    this.loadForms();
  },
  methods: {
    chooseTemplate() {
      this.$router.push({
        name: this.routeNames.formTemplateChooser.index,
      });
    },
    onEmbedModalShowChange(value) {
      this.embedModel.show = value;
    },
    onRowActionClick(action, row) {
      switch (action) {
        case 'delete':
          this.deleteFormModel.show = true;
          this.deleteFormModel.selected = row;
          break;
        case 'clone':
          this.cloneFormModel.show = true;
          this.cloneFormModel.selected = row;
          break;
        default:
      }
    },
    createForm() {
      if (!this.hasFormLimitFeature(this.formsPagination.total)) {
        this.toggleUpgradePlanModal({ show: true });
        return;
      }

      this.$router.push({
        name: this.routeNames.form.create,
      });
    },
    handlePageChange(page) {
      this.loadForms({ params: { page } });
    },
    handlePageSizeChange(pageSize) {
      this.loadForms({ params: { page_size: pageSize } });
    },
    deleteForm() {
      this.deleteFormModel.deleting = true;
      this.$store.dispatch('form/deleteForm', { formId: this.deleteFormModel.selected.id })
        .then(() => {
          this.deleteFormModel.deleting = false;
          this.deleteFormModel.show = false;
          this.loadForms();
        }).catch(() => {
          this.deleteFormModel.deleting = false;
        });
    },
    cancelDeleteForm() {
      this.deleteFormModel.deleting = false;
      this.deleteFormModel.selected = null;
      this.deleteFormModel.show = false;
    },
    cloneForm() {
      this.cloneFormModel.cloning = true;
      this.$store.dispatch('form/cloneForm', {
        formId: this.cloneFormModel.selected.id,
        title: this.cloneFormModel.title,
      })
        .then(() => {
          this.cloneFormModel.cloning = false;
          this.cloneFormModel.show = false;
          this.loadForms();
        }).catch(() => {
          this.cloneFormModel.cloning = false;
        });
    },
    cancelCloneForm() {
      this.cloneFormModel.cloning = false;
      this.cloneFormModel.selected = null;
      this.cloneFormModel.show = false;
    },
    updateCloneFormField(field) {
      this.cloneFormModel[field.name] = field.value;
    },
    showEmbedModal(formId) {
      this.embedModel.show = true;
      this.embedModel.formId = formId;
    },
    previewForm(formId) {
      let apiUrl = `${process.env.VUE_APP_FORM_DOMAIN_URL}/${formId}`;
      if (process.env.NODE_ENV === 'production') {
        window.open(apiUrl, '_blank');

        return;
      }

      apiUrl = encodeURIComponent(process.env.VUE_APP_API_BASE_URL);

      window.open(
        `${process.env.VUE_APP_GENERATOR_DEV_URL}/?formId=${formId}&apiUrl=${apiUrl}`,
        '_blank',
      );
    },
  },
  computed: {
    tableData() {
      return this.forms.map((form) => ({
        ...form,
        key: form.id,
        updated_at: dayjs(form.update_at).format('MM-DD-YYYY HH:mm:ss a'),
      }));
    },
    tablePagination() {
      return {
        page: this.formsPagination ? this.formsPagination.current_page : 1,
        pageSize: this.formsPagination ? Number(this.formsPagination.per_page) : 10,
        showSizePicker: true,
        pageSizes: [10, 50, 100],
        pageCount: this.formsPagination ? this.formsPagination.last_page : 0,
        itemCount: this.formsPagination ? this.formsPagination.total : 0,
      };
    },
    tableColumns() {
      const self = this;

      return [
        {
          title: 'Title',
          render(row) {
            return h(
              'div',
              {
                class: row.blocked ? '' : 'theme-color cursor-pointer',
                onClick: () => (row.blocked ? null : self.$router.push(
                  { name: self.routeNames.form.edit, params: { id: row.id } },
                )),
              },
              row.title,
            );
          },
        },
        {
          title: 'Responses',
          render(row) {
            return h(
              'div',
              {
                class: row.blocked ? '' : 'theme-color cursor-pointer',
                onClick: () => (row.blocked ? null : self.$router.push(
                  { name: self.routeNames.formresult.index, params: { formId: row.id } },
                )),
              },
              `${row.form_leads_count} response`,
            );
          },
        },
        {
          title: 'Conversions',
          key: 'form_conversions_count',
        },
        {
          title: 'Conversion Rate',
          render(row) {
            return `${row.form_conversion_rate}%`;
          },
        },
        {
          title: 'Last Updated',
          key: 'updated_at',
        },
        {
          title: 'Actions',
          render(row) {
            const upgradePlanBtn = h(
              'div',
              { class: 'row-actions' },
              [
                h(
                  NButton,
                  {
                    type: 'warning',
                    size: 'small',
                    onClick: () => self.$router.push({
                      name: self.routeNames.plan.upgrade,
                    }),
                    title: 'Edit',
                    style: 'margin-right: 5px;',
                  },
                  { default: () => ['Upgrade Plan'] },
                ),
              ],
            );

            if (row.blocked) {
              return upgradePlanBtn;
            }

            return h(
              'div',
              { class: 'row-actions' },
              [
                h(
                  NButton,
                  {
                    size: 'small',
                    onClick: () => self.previewForm(row.id),
                    title: 'Preview',
                    style: 'margin-right: 5px;',
                  },
                  { default: () => ['Preview', h(NIcon, { size: 20 }, { default: () => h(OpenOutline) })] },
                ),
                h(
                  NButton,
                  {
                    size: 'small',
                    onClick: () => self.showEmbedModal(row.id),
                    title: 'Embed',
                    style: 'margin-right: 5px;',
                  },
                  { default: () => ['Embed'] },
                ),
                h(
                  NDropdown,
                  {
                    trigger: 'hover',
                    onSelect: (v) => self.onRowActionClick(v, row),
                    options: [
                      {
                        label: 'Clone',
                        key: 'clone',
                      },
                      {
                        label: 'Delete',
                        key: 'delete',
                      },
                    ],
                  },
                  {
                    default: () => h(
                      NButton,
                      {
                        size: 'small',
                        title: 'More',
                      },
                      {
                        default: () => [
                          h(NIcon, { size: 20 }, { default: () => h(EllipsisVerticalOutline) }),
                        ],
                      },
                    ),
                  },
                ),
              ],
            );
          },
        },
      ];
    },
  },
};
</script>
