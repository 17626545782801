import _ from 'lodash';
import FormBuilderApi from '@/api/formBuilder';
import focusedEntityTypes from '../../../shared/constants/formbuilder/focusedEntityTypes';

export default {
  namespaced: true,
  state: {
    settingsPanel: {
      focusedEntity: null,
    },
    appearancePanel: {
      focusedEntity: {
        type: focusedEntityTypes.FORM_THEME,
      },
    },
    formBuilder: null,
  },
  getters: {
    formBuilder(state) {
      return state.formBuilder;
    },
    maxStepItemId(state) {
      let max = 0;

      if (!state.formBuilder) {
        return max;
      }

      state.formBuilder.steps.forEach((step) => {
        step.items.forEach((item) => {
          if (item.id > max) {
            max = item.id;
          }
        });
      });

      return max;
    },
    maxStepId(state) {
      let max = 0;

      if (!state.formBuilder) {
        return max;
      }

      state.formBuilder.steps.forEach((step) => {
        if (step.id > max) {
          max = step.id;
        }
      });

      return max;
    },
    sortedSteps(state) {
      if (!state?.formBuilder?.steps) {
        return [];
      }

      return _.sortBy(state.formBuilder.steps, (s) => s.order);
    },
    settingsPanel(state) {
      return state.settingsPanel;
    },
    focusedEntity(state) {
      // eslint-disable-next-line func-names
      return function (panel) {
        return state[panel].focusedEntity;
      };
    },
  },
  actions: {
    getState(context, data = { formId: null }) {
      if (data.formId) {
        return FormBuilderApi.edit(data.formId);
      }

      return FormBuilderApi.create();
    },
    saveState(context, data) {
      const payload = { ...data };
      delete payload.elements;

      return FormBuilderApi.save(payload);
    },
    updateState(context, data) {
      const payload = { ...data };
      delete payload.elements;

      return FormBuilderApi.update(payload);
    },
    setState({ commit }, data) {
      commit('setState', data);
    },
    addStep({ commit }, data) {
      commit('addStep', data);
    },
    deleteStep({ commit }, data) {
      commit('deleteStep', data);
    },
    duplicateStep({ commit }, data) {
      commit('duplicateStep', data);
    },
    addStepItem({ commit }, data) {
      commit('addStepItem', data);
    },
    addStepItemOverItem({ commit }, data) {
      commit('addStepItemOverItem', data);
    },
    deleteStepItem({ commit }, data) {
      commit('deleteStepItem', data);
    },
    duplicateStepItem({ commit }, data) {
      commit('duplicateStepItem', data);
    },
    focusEntity({ commit }, data) {
      commit('focusEntity', data);
    },
    updateFocusedEntityControl({ commit }, data) {
      commit('updateFocusedEntityControl', data);
    },
  },
  mutations: {
    setState(state, data) {
      state.formBuilder = data;
    },
    addStep(state, data) {
      const sortedSteps = _.sortBy(state.formBuilder.steps, (s) => s.order);
      const atStepIndex = _.findIndex(sortedSteps, { order: data.atStep.order });

      let startIndex = atStepIndex;
      let { order } = data.atStep;
      if (!data.before) {
        startIndex = atStepIndex + 1;
        order += 1;
      }

      const stepData = {
        id: data.id,
        order,
        title: `Step ${data.id}`,
        items: [],
      };

      for (let i = startIndex; i < sortedSteps.length; i += 1) {
        order += 1;
        sortedSteps[i].order = order;
      }

      state.formBuilder.steps.push(stepData);
    },
    deleteStep(state, data) {
      _.remove(state.formBuilder.steps, { id: data.stepId });
    },
    duplicateStep(state, data) {
      const sortedSteps = _.sortBy(state.formBuilder.steps, (s) => s.order);
      const step = _.find(state.formBuilder.steps, { id: data.stepId });
      const stepIndex = _.findIndex(sortedSteps, { id: data.stepId });
      const clonedStep = _.cloneDeep(step);
      delete clonedStep.dbId;
      clonedStep.id = data.id;

      let order = step.order + 1;
      const startIndex = stepIndex + 1;
      clonedStep.order = order;
      for (let i = startIndex; i < sortedSteps.length; i += 1) {
        order += 1;
        sortedSteps[i].order = order;
      }

      let newItemId = data.maxStepItemId + 1;
      let newItemOrder = 0;
      for (let j = 0; j < clonedStep.items.length; j += 1) {
        clonedStep.items[j].id = newItemId;
        clonedStep.items[j].order = newItemOrder;

        newItemId += 1;
        newItemOrder += 1;
      }

      state.formBuilder.steps.push(clonedStep);
    },
    addStepItem(state, data) {
      const step = _.find(state.formBuilder.steps, { id: data.stepId });

      const item = { ...data.item, order: data.order };

      step.items.push(item);
    },
    addStepItemOverItem(state, data) {
      const step = _.find(state.formBuilder.steps, { id: data.stepId });
      let order = -1;
      let startIndex = -1;
      const sortedItems = _.sortBy(step.items, (i) => i.order);

      if (data.position === 'top') {
        order = data.overItem.order;
        startIndex = _.findIndex(sortedItems, { order: data.overItem.order });
      } else {
        order = data.overItem.order + 1;
        startIndex = _.findIndex(sortedItems, { order: data.overItem.order }) + 1;
      }

      const item = { ...data.item, order };

      for (let i = startIndex; i < sortedItems.length; i += 1) {
        order += 1;
        sortedItems[i].order = order;
      }

      step.items.push(item);
    },
    deleteStepItem(state, data) {
      const step = _.find(state.formBuilder.steps, { id: data.stepId });
      _.remove(step.items, { id: data.stepItemId });
    },
    duplicateStepItem(state, data) {
      const step = _.find(state.formBuilder.steps, { id: data.stepId });
      const sortedStepItems = _.sortBy(step.items, (i) => i.order);
      const stepItem = _.find(step.items, { id: data.stepItemId });
      const stepItemIndex = _.findIndex(sortedStepItems, { id: data.stepItemId });
      const clonedStepItem = _.cloneDeep(stepItem);
      delete clonedStepItem.dbId;
      clonedStepItem.id = data.id;

      let order = stepItem.order + 1;
      const startIndex = stepItemIndex + 1;
      clonedStepItem.order = order;
      for (let i = startIndex; i < sortedStepItems.length; i += 1) {
        order += 1;
        sortedStepItems[i].order = order;
      }

      step.items.push(clonedStepItem);
    },
    focusEntity(state, data) {
      state.settingsPanel.focusedEntity = data || null;
    },
    updateFocusedEntityControl(state, data) {
      if (state[data.panel].focusedEntity.type === focusedEntityTypes.STEP_ITEM) {
        let stepItem = null;
        const { steps } = state.formBuilder;

        for (let i = 0; i <= steps.length; i += 1) {
          stepItem = _.find(
            steps[i].items,
            { id: state[data.panel].focusedEntity.value },
          );

          if (stepItem) {
            break;
          }
        }

        const control = _.find(
          stepItem.settings.controls,
          { id: data.control.id },
        );

        control[data.field] = data.value;
      }

      if (state[data.panel].focusedEntity.type === focusedEntityTypes.STEP_NAVIGATION) {
        const control = _.find(
          state.formBuilder.stepNavigation.settings.controls,
          { id: data.control.id },
        );

        control[data.field] = data.value;
      }

      if (state[data.panel].focusedEntity.type === focusedEntityTypes.FORM_GENERAL) {
        const control = _.find(
          state.formBuilder.general.settings.controls,
          { id: data.control.id },
        );

        control[data.field] = data.value;
      }

      if (state[data.panel].focusedEntity.type === focusedEntityTypes.FORM_THEME) {
        const control = _.find(
          state.formBuilder.theme.settings.controls,
          { id: data.control.id },
        );

        if (control.name === 'theme') {
          const overrideThemeColor = _.find(
            state.formBuilder.theme.settings.controls,
            { name: 'overrideElementColors' },
          )?.value === true;

          if (overrideThemeColor) {
            const overrideControlNames = [
              'stepNavBackBtnHoverBgColor',
              'stepNavBackBtnHoverBorderColor',

              'stepNavContinueBtnBgColor',
              'stepNavContinueBtnBorderColor',
              'stepNavContinueBtnHoverBgColor',
              'stepNavContinueBtnHoverBorderColor',

              'stepNavSubmitBtnBgColor',
              'stepNavSubmitBtnBorderColor',
              'stepNavSubmitBtnHoverBgColor',
              'stepNavSubmitBtnHoverBorderColor',
            ];

            overrideControlNames.forEach((oc) => {
              const ovc = _.find(
                state.formBuilder.theme.settings.controls,
                { name: oc },
              );

              if (!ovc) {
                return;
              }

              ovc.value = data.value;
            });
          }
        }

        control[data.field] = data.value;
      }
    },
  },
};
