import { useStore } from 'vuex';
import _ from 'lodash';

export default function useFormStep() {
  // STORE
  const store = useStore();

  // METHODS
  const isLastStep = (stepId) => {
    const steps = store.getters['formBuilder/sortedSteps'];
    const index = _.findIndex(
      steps,
      { id: stepId },
    );

    return index === steps.length - 1;
  };

  const isFirstStep = (stepId) => {
    const steps = store.getters['formBuilder/sortedSteps'];
    const index = _.findIndex(
      steps,
      { id: stepId },
    );

    return index === 0;
  };

  const isMidStep = (stepId) => {
    const steps = store.getters['formBuilder/sortedSteps'];
    const index = _.findIndex(
      steps,
      { id: stepId },
    );

    if (index === 0) {
      return false;
    }

    if (index === steps.length - 1) {
      return false;
    }

    return true;
  };

  return {
    isLastStep,
    isFirstStep,
    isMidStep,
  };
}
