import { computed } from 'vue';
import { useStore } from 'vuex';

export default function () {
  // STORE
  const store = useStore();

  // COMPUTED
  const savingTheme = computed(() => store.getters['formTheme/savingTheme']);

  // METHODS
  const saveFormTheme = (data, callback) => {
    store.dispatch('formTheme/savingTheme', true);

    return store.dispatch('formTheme/saveTheme', data)
      .then((response) => {
        store.dispatch('formTheme/savingTheme', true);

        if (callback) {
          callback(true, response);
        }
      }).catch((error) => {
        store.dispatch('formTheme/savingTheme', true);

        if (callback) {
          callback(false, error);
        }
      });
  };

  return {
    // Computed
    savingTheme,
    // Methods
    saveFormTheme,
  };
}
