import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import elementTypes from '@/shared/constants/formbuilder/elementTypes';
import formLogicOperators from '@/shared/constants/formLogicOperators';

export default function () {
  const store = useStore();

  // REFS
  const loadingFormStepLogic = ref(false);
  const loadingFormStepLogicErrMsg = ref('');
  const savingFormStepLogic = ref(false);
  const savingFormStepLogicErrMsg = ref('');

  // COMPUTED
  const formStepLogic = computed(() => store.getters['formStepLogic/formStepLogic']);
  const formStepItemOptions = computed(() => store.getters['formStepLogic/formStepItemOptions']);

  // METHODS
  const loadFormStepLogic = (data, callback) => {
    loadingFormStepLogic.value = true;

    store.dispatch('formStepLogic/getFormStepLogic', data).then((response) => {
      loadingFormStepLogic.value = false;
      store.dispatch('formStepLogic/setFormStepLogic', response.data).then(() => {
        if (callback) {
          callback(true);
        }
      });
    }).catch((error) => {
      if (callback) {
        callback(false);
      }
      loadingFormStepLogic.value = false;
      loadingFormStepLogicErrMsg.value = error.response.data.meta.error_message;
    });
  };

  const saveFormStepLogic = (data, callback) => {
    savingFormStepLogic.value = true;

    store.dispatch('formStepLogic/saveFormStepLogic', data).then((response) => {
      savingFormStepLogic.value = false;
      if (callback) {
        callback(true, response);
      }
    }).catch((error) => {
      console.log(error);
      if (callback) {
        callback(false, error);
      }
      savingFormStepLogic.value = false;
      savingFormStepLogicErrMsg.value = error.response.data.meta.error_message;
    });
  };

  const addFormStepLogic = (logic) => store.dispatch('formStepLogic/addFormStepLogic', logic);
  const addFormStepLogicCondition = (condition) => store.dispatch('formStepLogic/addFormStepLogicCondition', condition);
  const updateFormStepLogicTermField = (term) => store.dispatch('formStepLogic/updateFormStepLogicTermField', term);
  const updateFormStepLogicStep = (data) => store.dispatch('formStepLogic/updateFormStepLogicStep', data);
  const deleteFormStepLogic = (data) => store.dispatch('formStepLogic/deleteFormStepLogic', data);
  const deleteFormStepLogicConditionTerm = (data) => store.dispatch(
    'formStepLogic/deleteFormStepLogicConditionTerm',
    data,
  );
  const deleteFormStepLogicConditionTermChoice = (data) => store.dispatch(
    'formStepLogic/deleteFormStepLogicConditionTermChoice',
    data,
  );
  const getFormStepItemTypeOperators = (itemType) => {
    if (!itemType) {
      return [];
    }

    const options = {
      0: {
        label: 'Equal',
        value: formLogicOperators.EQUAL,
      },
      1: {
        label: 'Not Equal',
        value: formLogicOperators.NOT_EQUAL,
      },
      2: {
        label: 'Contains',
        value: formLogicOperators.CONTAINS,
      },
      3: {
        label: 'Not Contains',
        value: formLogicOperators.NOT_CONTAINS,
      },
      4: {
        label: '>',
        value: formLogicOperators.GREATER_THAN,
      },
      5: {
        label: '>=',
        value: formLogicOperators.GREATER_OR_EQUAL,
      },
      6: {
        label: '<',
        value: formLogicOperators.LESS_THAN,
      },
      7: {
        label: '<=',
        value: formLogicOperators.LESS_OR_EQUAL,
      },
    };

    return {
      [elementTypes.SHORT_TEXT]: [0, 1, 2, 3].map((i) => options[i]),
      [elementTypes.LONG_TEXT]: [0, 1, 2, 3].map((i) => options[i]),
      [elementTypes.EMAIL_ADDRESS]: [0, 1, 2, 3].map((i) => options[i]),
      [elementTypes.PHONE_NUMBER]: [0, 1, 2, 3].map((i) => options[i]),
      [elementTypes.SINGLE_SELECT]: [],
      [elementTypes.MULTI_SELECT]: [],
      [elementTypes.DATETIME]: [0, 1, 4, 5, 6, 7].map((i) => options[i]),
      [elementTypes.RATING]: [0, 1, 4, 5, 6, 7].map((i) => options[i]),
    }[itemType];
  };

  return {
    loadingFormStepLogic,
    loadingFormStepLogicErrMsg,
    savingFormStepLogic,
    savingFormStepLogicErrMsg,

    formStepLogic,
    formStepItemOptions,

    loadFormStepLogic,
    saveFormStepLogic,
    getFormStepItemTypeOperators,
    addFormStepLogic,
    addFormStepLogicCondition,
    updateFormStepLogicTermField,
    updateFormStepLogicStep,
    deleteFormStepLogic,
    deleteFormStepLogicConditionTerm,
    deleteFormStepLogicConditionTermChoice,
  };
}
