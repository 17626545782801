<template>
  <n-form-item
    :show-require-mark="getControlValue('required', false)"
    :label="getControlValue('label')">
    <div
      class="formbuilder__preview__step-item__item__desc"
      v-if="getControlValue('description', '').length > 0">
      {{getControlValue('description')}}
    </div>
    <n-input
      type="text"
      :value="getControlValue('default')"
      :placeholder="getControlValue('placeholder')" />
  </n-form-item>
</template>

<script>
import _ from 'lodash';
import intlTelInput from 'intl-tel-input';
import Inputmask from 'inputmask';

export default {
  props: {
    stepItem: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    const self = this;

    setTimeout(() => {
      const input = document.querySelector(`#${this.stepItemHtmlId} input`);

      this.itiInstance = intlTelInput(
        input,
        {
          customPlaceholder(selectedCountryPlaceholder) {
            const mask = selectedCountryPlaceholder.replace(new RegExp('[0-9]', 'g'), '9');
            const im = new Inputmask(mask);
            im.mask(input);

            self.placeholder = selectedCountryPlaceholder;

            return selectedCountryPlaceholder;
          },
          utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/15.0.2/js/utils.js',
          separateDialCode: true,
        },
      );
    }, 100);
  },
  methods: {
    getControlValue(name, defaultValue = '') {
      return _.find(this.stepItem.settings.controls, { name })?.value || defaultValue;
    },
  },
  computed: {
    stepItemHtmlId() {
      return `formbuilder__preview__step-item${this.stepItem.id}`;
    },
  },
};
</script>
