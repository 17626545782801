<template>
  <div class="form-integration-create-cpt">
    <form-webhook v-if="integrationTypes.webhook === integrationType" />
  </div>
</template>

<script>
import formIntegrationTypes from '@/shared/constants/formIntegrationTypes';
import FormWebhook from './FormWebhook/FormWebhook.vue';

export default {
  components: {
    FormWebhook,
  },
  computed: {
    integrationType() {
      return this.$route.params.type;
    },
    integrationTypes() {
      return formIntegrationTypes;
    },
  },
};
</script>
