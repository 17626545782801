<template>
  <div class="dashboard-analytics-widget-cpt">
    <div class="dashboard-analytics-widget-cpt__date">
      <n-select
        :value="selectedDateFilterOption"
        :options="dateFilterOptions"
        @update:value="updateDateFilter"/>
    </div>
    <div class="dashboard-analytics-widget-cpt__tiles">
      <div class="dashboard-analytics-widget-cpt__tile">
        <div>Forms</div>
        <div>{{forms}}</div>
      </div>
      <div class="dashboard-analytics-widget-cpt__tile">
        <div>Visitors</div>
        <div>{{visitors}}</div>
      </div>
      <div class="dashboard-analytics-widget-cpt__tile">
        <div>Leads</div>
        <div>{{conversions}}</div>
      </div>
      <div class="dashboard-analytics-widget-cpt__tile">
        <div>Conversion Rate</div>
        <div>{{conversionRate}}</div>
      </div>
      <div class="dashboard-analytics-widget-cpt__tile">
        <div>Completion Time</div>
        <div>{{completionTime}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

// COMPOSABLES
import useDashboard from '@/composables/dashboard/useDashboard';

export default {
  setup() {
    const {
      overview,
      getOverview,
      setOverview,
    } = useDashboard();

    return {
      selectedDateFilterOption: ref('today'),

      overview,
      getOverview,
      setOverview,
    };
  },
  mounted() {
    this.loadAnalytics();
  },
  methods: {
    loadAnalytics() {
      this.getOverview({
        data: {
          filter: {
            selected_date_filter: this.selectedDateFilterOption,
          },
        },
      }).then((response) => this.setOverview(response.data.result));
    },
    updateDateFilter(value) {
      console.log(value);
      this.selectedDateFilterOption = value;
      this.loadAnalytics();
    },
  },
  computed: {
    forms() {
      if (!this.overview) {
        return 0;
      }

      return this.overview.forms;
    },
    visitors() {
      if (!this.overview) {
        return 0;
      }

      return this.overview.visitors;
    },
    conversions() {
      if (!this.overview) {
        return 0;
      }

      return this.overview.conversions;
    },
    conversionRate() {
      if (!this.overview) {
        return 0;
      }

      return `${this.overview.conversion_rate}%`;
    },
    completionTime() {
      if (!this.overview) {
        return 0;
      }

      return `${this.overview.completion_time} sec`;
    },
    dateFilterOptions() {
      return [
        {
          label: 'Today',
          value: 'today',
        },
        {
          label: 'This week',
          value: 'current_week',
        },
        {
          label: 'This Month',
          value: 'current_month',
        },
        {
          label: 'This Year',
          value: 'current_year',
        },
        {
          label: 'All Time',
          value: 'alltime',
        },
      ];
    },
  },
};
</script>
