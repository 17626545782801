import { useStore } from 'vuex';
import { computed } from 'vue';

export default function () {
  const store = useStore();

  // Computed
  const overview = computed(() => store.getters['dashboard/overview']);

  // Methods
  const getOverview = (data = {}) => store.dispatch('dashboard/getOverview', data);
  const setOverview = (data) => store.dispatch('dashboard/setOverview', data);

  return {
    overview,

    getOverview,
    setOverview,
  };
}
