<template>
  <!-- FOCUSED ENTITY > ELEMENT -->
  <div
    class="formbuilder__settings-panel__focused__element">
      <div class="formbuilder__settings-panel__focused__element__tabs">
        <div
          :class="{
            'formbuilder__settings-panel__focused__element__tab': true,
            'active': activeTab ? activeTab.id === tab.id: false
          }"
          v-for="(tab, tabIndex) in tabs"
          :key="tabIndex"
          @click="() => setSelectedTab(tab)">
          <div class="formbuilder__settings-panel__focused__element__tab__icon">
          </div>
          <div class="formbuilder__settings-panel__focused__element__tab__title">
            {{tab.title}}
          </div>
        </div>
      </div>
      <div class="formbuilder__settings-panel__focused__element__tab__content">
        <n-collapse :default-expanded-names="focusedEntity.section || activeTabSectionsIds[0]">
          <n-collapse-item
            :title="activeTabSectionsObjects[section].title"
            :name="section"
            v-for="(controls, section) in activeTabSections"
            :key="section">
            <div class="formbuilder__settings-panel__focused__element__controls">
              <div
                class="formbuilder__settings-panel__focused__element__control"
                v-for="control in controls"
                :key="control.id"
              >
                <div v-if="control.type === controlTypes.DIVIDER">
                  <n-divider />
                </div>
                <div v-if="control.type === controlTypes.TITLE">
                  <p><strong>{{control.value}}</strong></p><br>
                </div>
                <div v-if="control.type === controlTypes.TEXT">
                  <n-form-item
                    :label="control.title"
                    label-placement="left"
                    :label-width="control?.naive_ui?.label_width || 80">
                    <n-input
                      size="small"
                      :value="control.value"
                      @update:value="(v) => updateControlValue(v, control)"
                      @keydown.enter.prevent />
                  </n-form-item>
                </div>
                <div v-if="control.type === controlTypes.SWITCH">
                  <n-form-item
                    :label="control.title"
                    label-placement="left"
                    :label-width="control?.naive_ui?.label_width || 80">
                    <n-switch
                      size="small"
                      :value="control.value"
                      @update:value="(v) => updateControlValue(v, control)"
                    />
                  </n-form-item>
                </div>
                <div v-if="control.type === controlTypes.TEXTAREA">
                  <n-form-item
                    :label="control.title"
                    label-placement="left"
                    :label-width="control?.naive_ui?.label_width || 80">
                    <n-input
                      size="small"
                      :value="control.value"
                      @update:value="(v) => updateControlValue(v, control)"
                      type="textarea" />
                  </n-form-item>
                </div>
                <div v-if="control.type === controlTypes.DATETIME">
                  <n-form-item
                    :label="control.title"
                    label-placement="left"
                    :label-width="control?.naive_ui?.label_width || 80">
                    <n-date-picker
                      :value="control.value"
                      @update:value="(v) => updateControlValue(v, control)"
                      type="datetime"
                      clearable />
                  </n-form-item>
                </div>
                <div v-if="control.type === controlTypes.NUMBER">
                  <n-form-item
                    :label="control.title"
                    label-placement="left"
                    :label-width="control?.naive_ui?.label_width || 80">
                    <n-input-number
                      :min="control.min"
                      :max="control.max"
                      :value="control.value"
                      @update:value="(v) => updateControlValue(v, control)"
                      clearable />
                  </n-form-item>
                </div>
                <div v-if="control.type === controlTypes.SELECT">
                  <n-form-item
                    :label="control.title"
                    label-placement="left"
                    :label-width="control?.naive_ui?.label_width || 80">
                    <n-select
                      :value="control.value"
                      :options="control.options"
                      @update:value="(v) => updateControlValue(v, control)"
                      clearable />
                  </n-form-item>
                </div>
                <div v-if="control.type === controlTypes.COLORPICKER">
                  <n-form-item
                    :label="control.title"
                    label-placement="left"
                    :label-width="control?.naive_ui?.label_width || 80">
                    <n-color-picker
                      :modes="['rgb', 'hex']"
                      :value="control.value"
                      :options="control.options"
                      @update:value="(v) => updateControlValue(v, control)"
                      clearable />
                  </n-form-item>
                </div>
                <div v-if="control.type === controlTypes.LIST">
                  <list-control
                    :list-control="control"
                    :items="control.items"
                    @update-items="updateListControlItems($event, control)"
                    @update-item-control="updateListControlItem($event, control)"
                    @add-item="addListControlItem($event, control)"
                    @duplicate-item="duplicateListControlItem($event, control)"
                    @delete-item="deleteListControlItem($event, control)" />
                </div>
              </div>
            </div>
          </n-collapse-item>
        </n-collapse>
      </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import _ from 'lodash';

// COMPONENTS
import ListControl from '../../Controls/ListControl.vue';

// CONSTANTS
import focusedEntityTypes from '@/shared/constants/formbuilder/focusedEntityTypes';
import controlTypes from '@/shared/constants/formbuilder/controlTypes';

export default {
  props: {
    element: {
      type: Object,
      required: true,
    },
    panel: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      selectedTab: ref(null),
      controlTypes: ref(controlTypes),
      focusedEntityTypes: ref(focusedEntityTypes),
    };
  },
  components: {
    ListControl,
  },
  methods: {
    setSelectedTab(tab) {
      this.selectedTab = tab;
    },
    getControlValue(control) {
      return control.value;
    },
    updateControlValue(value, control) {
      this.$store.dispatch('formBuilder/updateFocusedEntityControl', {
        panel: this.panel,
        control,
        value,
        field: 'value',
      });
    },
    updateListControlItem({ value, control, item }, listControl) {
      const newControl = _.find(item.controls, { id: control.id });
      newControl.value = value;

      this.$store.dispatch('formBuilder/updateFocusedEntityControl', {
        panel: this.panel,
        control: listControl,
        value: listControl.items,
        field: 'items',
      });
    },
    updateListControlItems({ items }, listControl) {
      this.$store.dispatch('formBuilder/updateFocusedEntityControl', {
        panel: this.panel,
        control: listControl,
        value: items,
        field: 'items',
      });
    },
    addListControlItem({ newItem }, listControl) {
      this.$store.dispatch('formBuilder/updateFocusedEntityControl', {
        panel: this.panel,
        control: listControl,
        value: [...listControl.items, newItem],
        field: 'items',
      });
    },
    duplicateListControlItem({ item, newId, newPosition }, listControl) {
      const clonedItem = _.cloneDeep(item);
      clonedItem.id = newId;
      clonedItem.order = newPosition;

      const sortedItems = _.sortBy(listControl.items, (i) => i.order);
      const startIndex = _.findIndex(sortedItems, { position: clonedItem.order });
      let updatedPosition = newPosition + 1;

      if (startIndex >= 0) {
        for (let i = startIndex; i < sortedItems.length; i += 1) {
          sortedItems[i].order = updatedPosition;
          updatedPosition += 1;
        }
      }

      this.$store.dispatch('formBuilder/updateFocusedEntityControl', {
        panel: this.panel,
        control: listControl,
        value: [...listControl.items, clonedItem],
        field: 'items',
      });
    },
    deleteListControlItem({ item }, listControl) {
      const filteredItems = listControl.items
        .filter((i) => i.id !== item.id);
      this.$store.dispatch('formBuilder/updateFocusedEntityControl', {
        panel: this.panel,
        control: listControl,
        value: filteredItems,
        field: 'items',
      });
    },
  },
  computed: {
    focusedEntity() {
      return this.$store.getters['formBuilder/focusedEntity'](this.panel);
    },
    tabs() {
      if (!this.element?.settings) {
        return [];
      }

      return this.element.settings.tabs.filter((tab) => this.element.settings.controls
        .filter((c) => c.tab === tab.id && c.visible !== false)
        .length > 0);
    },
    defaultTab() {
      const [defaultTab] = this.tabs;

      return defaultTab;
    },
    activeTab() {
      return this.selectedTab || this.defaultTab;
    },
    activeTabControls() {
      if (!this.activeTab) {
        return [];
      }

      return this.element.settings.controls
        .filter((c) => c.tab === this.activeTab.id && c.visible !== false);
    },
    activeTabSections() {
      if (!this.activeTab) {
        return {};
      }

      const sections = {};

      for (let i = 0; i < this.activeTabControls.length; i += 1) {
        const control = this.activeTabControls[i];
        control.sectionObj = _.find(
          this.element.settings.sections,
          { id: control.section },
        );

        if (sections[control.section]) {
          sections[control.section].push(control);
        } else {
          sections[control.section] = [control];
        }
      }

      return sections;
    },
    activeTabSectionsIds() {
      return Object.keys(this.activeTabSections);
    },
    activeTabSectionsObjects() {
      const sections = {};

      this.activeTabSectionsIds
        .forEach((id) => {
          sections[id] = this.activeTabSections[id][0].sectionObj;
        });

      return sections;
    },
  },
  watch: {
    focusedEntity() {

    },
  },
};
</script>
