import axios from '@/api/axios';

const getCustomers = (data) => axios
  .get('/admin/customers', data?.params || {});

const changeDefaultPlan = (data) => axios
  .post(`/admin/change-default-plan/${data.userId}/${data.plan}`, data?.params || {});

export default {
  getCustomers,
  changeDefaultPlan,
};
