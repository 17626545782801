<template>
  <div class="email-verification-success-view">
    <n-result status="success" title="Success" description="Your email is verified successfully!">
      <template #footer>
        <n-button @click="$router.push({name: routeNames.login})">Log In</n-button>
      </template>
    </n-result>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['routeNames']),
  },
};
</script>
