<template>
  <div class="plan-view">
    <div class="plan-view__body">
      <div class="container">
        <div class="current-plan">
          <div>
            <div>
              <small>Current Plan: </small>
              <strong>{{authUserPlan.title}}</strong>
            </div>
            <div v-if="nextBillingDate && !isSubscriptionPaused">
              <small>Next Bill Date: </small>
              <strong>{{nextBillingDate}}</strong>
            </div>
            <div v-if="isSubscriptionCancelled">
              <small>Ends At: </small>
              <strong>{{endsAt}}</strong>
            </div>
            <div v-if="currentSubscriptionStatus">
              <small>Status: </small>
              <strong>{{currentSubscriptionStatus}}</strong>
            </div>
            <div v-if="authUserSubscription">
              <br>
              <n-button
                :disabled="this.subscription.subscribing"
                :loading="currentPlanActionRunning"
                :type="isSubscriptionPaused ? 'primary' : 'error'"
                @click="onCurrentPlanActionConfirm"
                v-if="!isSubscriptionCancelled">{{currentPlanActionText}}</n-button>
              <div
                style="color:red;margin: 10px 0;"
                v-if="currentPlanActionError.length > 0">
                <small>{{currentPlanActionError}}</small>
              </div>
            </div>
          </div>
        </div>
        <div v-if="subscription.status === 'failed'">
          <n-alert title="Subscription Error" type="error">
            Please contact our <a href="mailto:support@coreform.io">support@coreform.io</a>
            if you are facing problem with subscription or payment.
          </n-alert>
          <br>
        </div>
        <div v-if="subscription.status === 'success'">
          <n-alert title="Subscription Success" type="success">
            You have subscribed successfully!
          </n-alert>
          <br>
        </div>
        <div class="plans">
          <div
            class="plan"
            v-for="(plan, pi) in plansList"
            :key="pi"
            :class="{current: plan.current}">
            <div class="plan__header">
              <div class="plan__title">{{plan.title}}</div>
              <div class="plan__price">
                <span class="plan__price__value">{{plan.price}} USD</span>/mo
              </div>
            </div>
            <div class="plan__body">
              <div class="plan__features">
                <div
                  class="plan__feature"
                  v-for="(feature, fi) in plan.features" :key="fi">
                  <n-icon><CheckmarkOutline /></n-icon>
                  <span>{{feature.title}}</span>
                </div>
              </div>
            </div>
            <div class="plan__footer">
              <paddle-checkout-popup
                :closeCallback="closeCallback"
                :successCallback="successCallback"
                :disabled="(
                  subscription.subscribing && subscription.paddlePlanId !== plan.paddleId ||
                  currentPlanActionRunning
                )"
                :loading="subscription.subscribing && subscription.paddlePlanId === plan.paddleId"
                :paddle-plan-id="plan.paddleId"
                :user="authUser.user"
                @subscribing="onSubscribing"
                :beforeOpeningCheckout="beforeOpeningCheckout"
                v-if="!plan.current && plan.paddleId"/>
                <strong v-else>CURRENT PLAN</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import {
  CheckmarkOutline,
} from '@vicons/ionicons5';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { useDialog, useMessage } from 'naive-ui';

// COMPONENTS
import PaddleCheckoutPopup from '@/components/Paddle/PaddleCheckoutPopup.vue';

// COMPOSABLES
import useAuth from '@/composables/useAuth';
import useSubscription from '@/composables/useSubscription';

export default {
  setup() {
    const {
      authUserPlan,
      authUserSubscription,
      authUser,
      refreshToken,
    } = useAuth();

    const {
      unsubscribe,
      subscribe,
    } = useSubscription();

    const dialog = useDialog();
    const message = useMessage();

    return {
      currentPlanActionRunning: ref(false),
      currentPlanActionError: ref(''),

      dialog,
      message,

      authUserSubscription,

      unsubscribe,
      subscribe,

      subscription: ref({
        subscribing: false,
        planId: null,
        interval: null,
        intervalCounter: 0,
        intervalLimit: 15,
        status: null,
      }),

      authUserPlan,
      authUser,
      refreshToken,
    };
  },
  components: {
    CheckmarkOutline,
    PaddleCheckoutPopup,
  },
  methods: {
    beforeOpeningCheckout(options) {
      if (this.isSubscriptionCancelled || !this.authUserSubscription) {
        return true;
      }

      this.dialog.warning({
        title: 'Confirm',
        content: 'Are you sure you want to subscribe this plan?',
        positiveText: 'Sure',
        negativeText: 'Cancel',
        onPositiveClick: () => {
          this.onSwitchingToNewPlan(options);
        },
        onNegativeClick: () => {},
      });

      return false;
    },
    closeCallback() {
      this.subscription.subscribing = false;
      this.subscription.paddlePlanId = null;
    },
    successCallback() {
      this.pollingSubscriptionStatus(() => {
        this.subscription.status = 'success';
        this.subscription.subscribing = false;
        clearInterval(this.subscription.interval);
      });
    },
    onSubscribing(data) {
      this.subscription.subscribing = true;
      this.subscription.paddlePlanId = data.paddlePlanId;
      this.subscription.interval = null;
      this.subscription.intervalCounter = 0;
      this.subscription.intervalLimit = 15;
      this.subscription.status = null;
    },
    pollingSubscriptionStatus(callback = null) {
      this.subscription.interval = setInterval(() => {
        if (this.subscription.intervalCounter >= this.subscription.intervalLimit) {
          this.subscription.status = 'failed';
          this.subscription.subscribing = false;
          clearInterval(this.subscription.interval);
          return;
        }

        this.subscription.intervalCounter += 1;

        this.refreshToken((success) => {
          if (!success) {
            return;
          }

          if (
            callback
            && this.authUserPlan.provider_identifier === this.subscription.paddlePlanId
          ) {
            callback();
          }
        });
      }, 1000);
    },
    onSwitchingToNewPlan(options) {
      const callback = (success, response) => {
        this.subscription.subscribing = false;
        this.currentPlanActionRunning = false;
        if (!success) {
          this.message.error(response.data.meta.error_message);
          return;
        }

        this.refreshToken();
        this.message.success('Subscription updated successfully');
      };

      this.subscription.subscribing = true;
      this.subscribe({
        data: {
          subscription_id: this.authUserSubscription.paddle_subscription_id,
          plan_id: options.paddlePlanId,
        },
      }, (success, response) => {
        setTimeout(() => callback(success, response), 10000);
      });
    },
    onCurrentPlanActionConfirm() {
      let actionText = 'Unsubscribe';
      if (this.isSubscriptionPaused) {
        actionText = 'Resume Subscription';
      }

      this.dialog.warning({
        title: 'Confirm',
        content: `Are you sure you want to ${actionText}?`,
        positiveText: 'Sure',
        negativeText: 'Cancel',
        onPositiveClick: () => {
          this.onCurrentPlanAction();
        },
        onNegativeClick: () => {},
      });
    },
    onCurrentPlanAction() {
      const callback = (psuccess, presponse) => {
        console.log('timeout');
        this.currentPlanActionRunning = false;
        if (!psuccess) {
          this.currentPlanActionError = presponse.data.meta.error_message;
          return;
        }

        this.refreshToken();
      };

      if (this.isSubscriptionPaused) {
        this.currentPlanActionRunning = true;
        this.currentPlanActionError = '';
        this.subscribe({
          data: {
            subscription_id: this.authUserSubscription.paddle_subscription_id,
          },
        }, (psuccess, presponse) => {
          setTimeout(() => callback(psuccess, presponse), 10000);
        });
        return;
      }

      if (this.authUserSubscription.active) {
        this.currentPlanActionRunning = true;
        this.currentPlanActionError = '';
        this.unsubscribe({
          data: {
            subscription_id: this.authUserSubscription.paddle_subscription_id,
          },
        }, (psuccess, presponse) => {
          setTimeout(() => callback(psuccess, presponse), 10000);
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      'plans',
    ]),
    currentSubscriptionStatus() {
      if (!this.authUserSubscription) {
        return '';
      }

      if (this.isSubscriptionCancelled) {
        return 'Cancelled';
      }

      return this.isSubscriptionPaused ? 'Paused' : 'Active';
    },
    isSubscriptionPaused() {
      if (!this.authUserSubscription) {
        return false;
      }

      return this.authUserSubscription.active
        && this.authUserSubscription.paused;
    },
    isSubscriptionCancelled() {
      return this.authUserSubscription?.paddle_status === 'deleted';
    },
    currentPlanActionText() {
      if (this.isSubscriptionPaused) {
        return 'Resume Subscription';
      }

      return 'Unsubscribe';
    },
    nextBillingDate() {
      if (!this.authUserSubscription || this.isSubscriptionCancelled) {
        return null;
      }

      return dayjs(this.authUserSubscription.next_bill_date).format('D MMM YYYY');
    },
    endsAt() {
      if (!this.isSubscriptionCancelled) {
        return null;
      }

      return dayjs(this.authUserSubscription.ends_at).format('D MMM YYYY');
    },
    plansList() {
      const plans = [
        {
          title: 'Plus',
          paddleId: this.plans.paddlePlus.paddleId,
          price: this.plans.paddlePlus.price,
          slug: this.plans.paddlePlus.slug,
          description: '',
          features: [
            {
              title: '1000 responses/mo for each form included',
            },
            {
              title: '30 Forms',
            },
            {
              title: 'Conditional Logic',
            },
            {
              title: 'Quiz / Calculator',
            },
            {
              title: 'Zapier Integration',
            },
            {
              title: 'Webhook integration',
            },
            {
              title: 'Export Leads CSV/XLSX',
            },
            {
              title: 'All Question types',
            },
            {
              title: 'No CoreForm branding',
            },
          ],
        },
        {
          title: 'Business',
          paddleId: this.plans.paddleBusiness.paddleId,
          price: this.plans.paddleBusiness.price,
          slug: this.plans.paddleBusiness.slug,
          description: '',
          features: [
            {
              title: '3,000 responses/mo for each form included',
            },
            {
              title: '200 Form',
            },
            {
              title: 'Conditional Logic',
            },
            {
              title: 'Quiz / Calculator',
            },
            {
              title: 'Zapier Integration',
            },
            {
              title: 'Webhook integration',
            },
            {
              title: 'Export Leads CSV/XLSX',
            },
            {
              title: 'All Question types',
            },
            {
              title: 'No CoreForm branding',
            },
          ],
        },
      ];

      if (this.authUserPlan?.slug === 'free') {
        plans.unshift({
          title: 'FREE',
          slug: this.plans.free.slug,
          price: this.plans.free.price,
          description: '',
          features: [
            {
              title: '100 responses/mo included',
            },
            {
              title: '5 Forms',
            },
            {
              title: 'Basic Question types',
            },
            {
              title: 'Zapier Integration',
            },
          ],
        });
      }

      return plans
        .map((p) => ({ ...p, current: p.slug === this.authUserPlan?.slug }));
    },
  },
};
</script>
