import CustomerApi from '@/api/admin/customer';

export default {
  namespaced: true,
  state: {
    customers: [],
    customersPagination: null,
  },
  getters: {
    customers(state) {
      return state.customers || [];
    },
    customersPagination(state) {
      return state.customersPagination;
    },
  },
  actions: {
    getCustomers(_, data) {
      return CustomerApi.getCustomers(data);
    },
    changeDefaultPlan(_, data) {
      return CustomerApi.changeDefaultPlan(data);
    },
    setCustomers(context, data) {
      context.commit('setCustomers', data);
    },
  },
  mutations: {
    setCustomers(state, data) {
      state.customers = data.result;
      state.customersPagination = data.pagination;
    },
  },
};
