<template>
  <div class="form-create-view">
    <div class="form-create-view__loader" v-if="!formBuilder || loading">
        <n-spin size="large" />
    </div>
    <form-builder v-else />
  </div>
</template>

<script>
import { ref } from 'vue';

// COMPONENTS
import FormBuilder from '@/components/FormBuilder/Builder.vue';

// COMPOSABLES
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';

export default {
  setup() {
    const {
      loadFormBuilderState,
      formBuilder,
    } = useFormBuilder();

    return {
      loading: ref(false),
      formBuilder,
      loadFormBuilderState,
    };
  },
  components: {
    FormBuilder,
  },
  mounted() {
    this.loadFormBuilderState(this.$route.params.id, () => {
      this.loading = false;
    });
  },
};
</script>
