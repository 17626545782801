<template>
  <div class="formbuilder__preview__step__navigation" @click.stop="showNavigationSettings">
    <div class="formbuilder__preview__step__navigation__content">
      <div class="formbuilder__preview__step__navigation__back">
        <n-button
          size="large"
          @click="navigateBack"
          v-if="showBack">{{backText}}</n-button>
      </div>
      <div :class="continueClass">
        <n-button
          size="large"
          type="primary"
          @click="navigateContinue">{{ continueText }}</n-button>
      </div>
    </div>
    <div class="formbuilder__preview__step__navigation__footer">
      <div class="formbuilder__preview__step__navigation__footer__actions">
        <div class="formbuilder__preview__step__navigation__footer__action">
          <i class="fas fa-cog" @click.stop="showNavigationSettings"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

// COMPOSABLES
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';
import useFormStep from '@/composables/formbuilder/useFormStep';

// CONSTANTS
import focusedEntityTypes from '@/shared/constants/formbuilder/focusedEntityTypes';

export default {
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      isFirstStep,
      isLastStep,
      isMidStep,
    } = useFormStep();

    const {
      isMultiStep,
      focusEntity,
      formBuilder,
    } = useFormBuilder();

    return {
      isFirstStep,
      isLastStep,
      isMidStep,

      isMultiStep,
      focusEntity,

      formBuilder,
    };
  },
  methods: {
    navigateBack() {

    },
    navigateContinue() {

    },
    showNavigationSettings(e) {
      e.stopPropagation();

      this.focusEntity({
        title: 'Step Navigation',
        type: focusedEntityTypes.STEP_NAVIGATION,
        value: null,
      });
    },
    getControl(condition) {
      return _.find(
        this.stepNavigation.settings.controls,
        condition,
      );
    },
  },
  computed: {
    stepNavigation() {
      return this.formBuilder.stepNavigation;
    },
    stepId() {
      return this.step.id;
    },
    showBack() {
      const control = this.getControl({ name: 'hide_back' });

      if (control.value) {
        return false;
      }

      return this.isMidStep(this.stepId)
        || (this.isLastStep(this.stepId) && this.isMultiStep);
    },
    backText() {
      const control = this.getControl({ name: 'label_back' });

      return control.value;
    },
    continueText() {
      if (this.isLastStep(this.stepId)) {
        const control = this.getControl({ name: 'label_submit_continue' });

        return control.value;
      }

      const control = this.getControl({ name: 'label_continue' });

      return control.value;
    },
    continueClass() {
      if (!this.isLastStep(this.stepId)) {
        return 'formbuilder__preview__step__navigation__continue';
      }

      return 'formbuilder__preview__step__navigation__submit';
    },
  },
};
</script>
