<template>
  <div class="formbuilder__navigator">
    <div class="formbuilder__navigator__header">
    </div>
    <div class="formbuilder__navigator__content">
    </div>
    <div class="formbuilder__navigator__footer">
    </div>
  </div>
</template>
