import { createStore } from 'vuex';

import authModule from '@/store/modules/auth';
import userModule from '@/store/modules/user';
import formBuilderModule from '@/store/modules/formBuilder';
import formModule from '@/store/modules/form';
import formIntegrationModule from '@/store/modules/formIntegration';
import formThemeModule from '@/store/modules/formTheme';
import formLeadModule from '@/store/modules/formLead';
import formStepLogicModule from '@/store/modules/formStepLogic';
import formStepQuizModule from '@/store/modules/formStepQuiz';
import formTemplateIndustryModule from '@/store/modules/formTemplateIndustry';
import formTemplateCategoryModule from '@/store/modules/formTemplateCategory';
import formTemplateModule from '@/store/modules/formTemplate/index';
import dashboardModule from '@/store/modules/dashboard';
import subscriptionModule from '@/store/modules/subscription';
import mediaModule from '@/store/modules/media';
import formTemplateChooserModule from '@/store/modules/formTemplateChooser';
// Admin modules
import adminCustomerModule from '@/store/modules/admin/customer';

// CONSTANTS
import routeNames from '../shared/constants/routeNames';
import plans from '../shared/constants/plans';
import features from '../shared/constants/features';
import errorCodes from '../shared/constants/errorCodes';

export default createStore({
  state: {
    routeNames,
    plans,
    features,
    errorCodes,
  },
  mutations: {
  },
  actions: {
  },
  getters: {
    routeNames(state) {
      return state.routeNames;
    },
    plans(state) {
      return state.plans;
    },
    features(state) {
      return state.features;
    },
    errorCodes(state) {
      return state.errorCodes;
    },
  },
  modules: {
    auth: authModule,
    user: userModule,
    formBuilder: formBuilderModule,
    form: formModule,
    formIntegration: formIntegrationModule,
    formTheme: formThemeModule,
    formLead: formLeadModule,
    formStepLogic: formStepLogicModule,
    formStepQuiz: formStepQuizModule,
    dashboard: dashboardModule,
    subscription: subscriptionModule,
    adminCustomer: adminCustomerModule,
    formTemplateIndustry: formTemplateIndustryModule,
    formTemplateCategory: formTemplateCategoryModule,
    formTemplate: formTemplateModule,
    media: mediaModule,
    formTemplateChooser: formTemplateChooserModule,
  },
});
