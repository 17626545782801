import formTemplateIndustryApi from '@/api/formTemplateIndustry';

export default {
  namespaced: true,
  state: {
    industries: [],
  },
  getters: {
    formTemplateIndustries(state) {
      return state.industries;
    },
  },
  actions: {
    getFormTemplateIndustries(context, data) {
      return formTemplateIndustryApi.getFormTemplateIndustries(data);
    },
    setFormTemplateIndustries(context, data) {
      context.commit('setFormTemplateIndustries', data);
    },
  },
  mutations: {
    setFormTemplateIndustries(state, data) {
      state.industries = data;
    },
  },
};
