import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default function () {
  const store = useStore();

  // REFS
  const loadingTemplates = ref(false);
  const creatingForm = ref(false);

  // COMPUTED
  const formTemplates = computed(() => store.getters['formTemplateChooser/templates']);
  const formCategories = computed(() => store.getters['formTemplateChooser/categories']);
  const formIndustries = computed(() => store.getters['formTemplateChooser/industries']);
  const filters = computed(() => store.getters['formTemplateChooser/filters']);
  const pagination = computed(() => store.getters['formTemplateChooser/pagination']);

  // METHODS
  const setFormTemplates = (data) => store.dispatch('formTemplateChooser/setFormTemplates', data);
  const updateState = (data) => store.dispatch('formTemplateChooser/updateState', data);
  const clearFilters = (data) => store.dispatch('formTemplateChooser/clearFilters', data);

  const getFormTemplates = (data, callback) => {
    loadingTemplates.value = true;
    store.dispatch('formTemplateChooser/getFormTemplates', data).then((response) => {
      loadingTemplates.value = false;
      if (callback) {
        callback(true, response);
      }
    }).catch((error) => {
      loadingTemplates.value = false;
      console.log(error);
      if (callback) {
        callback(false, error);
      }
    });
  };

  const createFormFromTemplate = (data, callback) => {
    creatingForm.value = true;
    store.dispatch('formTemplateChooser/createFormFromTemplate', data).then((response) => {
      creatingForm.value = false;
      if (callback) {
        callback(true, response);
      }
    }).catch((error) => {
      creatingForm.value = false;
      console.log(error);
      if (callback) {
        callback(false, error);
      }
    });
  };

  return {
    formIndustries,
    formCategories,
    formTemplates,
    filters,
    pagination,
    loadingTemplates,

    updateState,
    getFormTemplates,
    setFormTemplates,
    clearFilters,
    createFormFromTemplate,
  };
}
