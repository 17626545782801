<template>
  <div class="address-step-item">
    <div class="default-skin">
        <n-form-item
          :show-require-mark="isRequired"
          :label="getControlValue('label')">
          <div
            class="formbuilder__preview__step-item__item__desc"
            v-if="getControlValue('description')?.length > 0">
              {{getControlValue('description')}}
          </div>
          <div
            style="width:100%;margin-bottom: 8px;"
            v-for="fieldItem in activeItems" :key="fieldItem.id">
            <n-select
              v-if="fieldItem.name === 'state'"
              v-model:value="model[fieldItem.name]"
              :placeholder="getItemControl(fieldItem, 'placeholder').value"
              :options="selectedCountryStatesOptions"
              filterable/>
            <n-select
              v-else-if="fieldItem.name === 'country'"
              v-model:value="model[fieldItem.name]"
              :placeholder="getItemControl(fieldItem, 'placeholder').value"
              :options="countriesOptions"
              @update:value="onCountryChange"
              filterable/>
            <n-input
              v-else
              type="text"
              v-model:value="model[fieldItem.name]"
              :placeholder="getItemControl(fieldItem, 'placeholder').value"/>
          </div>
        </n-form-item>
      </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { ref } from 'vue';
import countries from '@/shared/constants/countries';

export default {
  props: {
    stepItem: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      countries: ref(JSON.parse(countries)),
      model: ref({
        address_line_2: '',
        address_line_1: '',
        city: '',
        state: null,
        country: null,
        zip_code: '',
        landmark: '',
        longitude: '',
        latitude: '',
      }),
    };
  },
  methods: {
    getControl(name) {
      return _.find(this.stepItem.settings.controls, { name });
    },
    getControlValue(name, defaultValue = '') {
      return _.find(this.stepItem.settings.controls, { name })?.value || defaultValue;
    },
    getItemControl(item, controlName) {
      return _.find(item.controls, { name: controlName });
    },
    onCountryChange() {
      this.model.state = null;
    },
  },
  computed: {
    sortedItems() {
      const { items } = this.getControl('fields');

      return _.sortBy(items, (i) => i.order);
    },
    activeItems() {
      return this.sortedItems.filter((i) => this.getItemControl(i, 'active').value);
    },
    isRequired() {
      let required = false;

      this.activeItems.forEach((item) => {
        if (this.getItemControl(item, 'required').value) {
          required = true;
        }
      });

      return required;
    },
    countriesOptions() {
      return this.countries.map((c) => ({
        label: c.name,
        value: c.iso3,
      }));
    },
    selectedCountryStatesOptions() {
      const country = this.countries.find((c) => c.iso3 === this.model.country);

      return (country?.states || []).map((s) => ({
        label: s,
        value: s,
      }));
    },
  },
  watch: {
    selectSkinOptions() {
      this.model = '';
    },
  },
};
</script>
