import SubscriptionApi from '@/api/subscription';

const state = {};

const getters = {};

const actions = {
  unsubscribe(_, data) {
    return SubscriptionApi.unsubscribe(data);
  },
  subscribe(_, data) {
    return SubscriptionApi.subscribe(data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
