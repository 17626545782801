<template>
  <div class="form-template-chooser-cpt">
    <div class="form-template-chooser-cpt__content">
      <div class="form-template-chooser-cpt__content__left">
        <n-card
          title="FORM LAYOUT"
          :segmented="{
            content: true,
            footer: 'soft',
          }"
        >
          <n-tabs type="segment" :value="filters.layout" @update:value="onLayoutChange" animated>
            <n-tab-pane name="multiple" tab="Multi Step">
            </n-tab-pane>
            <n-tab-pane name="single" tab="Single Step">
            </n-tab-pane>
          </n-tabs>
        </n-card>
        <n-card
          title="FORM TYPE"
          :segmented="{
            content: true,
            footer: 'soft',
          }"
        >
          <n-tabs type="segment" :value="filters.type" @update:value="onTypeChange" animated>
            <n-tab-pane name="Categories" tab="Categories">
              <div>
                <n-button @click="clearAll">Clear All</n-button>
              </div>
              <div class="category-type" v-for="cat in formCategories" :key="cat.slug">
                <div>
                  <n-checkbox
                    :checked="filters.categories.indexOf(cat.id) >= 0"
                    @update:checked="(v) => onCategoryCheck(v, cat)">{{ cat.title }}</n-checkbox>
                </div>
                <span class="category-type__count">{{ cat.form_templates_count }}</span>
              </div>
            </n-tab-pane>
            <n-tab-pane name="Industries" tab="Industries">
              <div>
                <n-button @click="clearAll">Clear All</n-button>
              </div>
              <div class="industry-type" v-for="ind in formIndustries" :key="ind.slug">
                <div>
                  <n-checkbox
                    :checked="filters.industries.indexOf(ind.id) >= 0"
                    @update:checked="(v) => onIndustryCheck(v, ind)">{{ ind.title }}</n-checkbox>
                </div>
                <span class="industry-type__count">{{ ind.form_templates_count }}</span>
              </div>
            </n-tab-pane>
          </n-tabs>
        </n-card>
      </div>
      <div class="form-template-chooser-cpt__content__right">
        <h1>Choose Form Template</h1>
        <h2>Unlock limitless possibilities with customizable form
          templates tailored for every need.</h2>
        <div class="form-template-chooser-cpt__search">
          <n-input
            size="large"
            placeholder="Search Template"
            :value="filters.search"
            @update:value="onSearch"
            round>
            <template #prefix>
              <n-icon><SearchOutline /></n-icon>
            </template>
          </n-input>
          <n-button size="large" @click="startScratch" round secondary>Start From Blank</n-button>
        </div>
        <div class="form-template-chooser-cpt__templates">
          <div
            class="form-template-chooser-cpt__template"
            v-for="formTemplate in formTemplates"
            :key="formTemplate.id">
            <div class="form-template-chooser-cpt__template__img">
              <img :src="formTemplate.thumbnail?.thumbnail_url" v-if="formTemplate?.thumbnail"/>
              <n-empty description="No Image Found" v-else />
            </div>
            <div class="form-template-chooser-cpt__template__title">
              <p>{{ formTemplate.title }}</p>
            </div>
            <div class="form-template-chooser-cpt__template__actions">
              <div>
                <n-button
                  size="large"
                  @click="() => previewForm(formTemplate)"
                  ghost>View Template</n-button>
                <n-button
                  :loading="creatingForm"
                  size="large"
                  type="primary"
                  @click="() => useTemplate(formTemplate)">Use Template</n-button>
              </div>
            </div>
          </div>
        </div>
        <div class="form-template-chooser-cpt__templates__loadmore">
          <n-button
            size="large"
            type="primary"
            :loading="loadingTemplates"
            @click="loadMore"
            v-if="pagination && pagination.current_page < pagination.last_page"
            ghost>Load More</n-button>
        </div>
      </div>
    </div>
    <!-- MODALS -->
    <clone-form-model
      title="Enter Form Title"
      cloneBtnText="Create Form"
      :cloneFormModel="cloneFormModel"
      @cancelCloneForm="cancelCloneForm"
      @cloneForm="cloneForm"
      @updateFormField="updateCloneFormField"
      v-if="cloneFormModel.show"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import { mapGetters } from 'vuex';
import {
  SearchOutline,
} from '@vicons/ionicons5';
import useFormTemplateChooser from '@/composables/form/useFormTemplateChooser';
import useFormTemplateCategory from '@/composables/form/useFormTemplateCategory';
import useFormTemplateIndustry from '@/composables/form/useFormTemplateIndustry';
import CloneFormModel from '../../../views/Form/FormList/modals/CloneFormModel.vue';

export default {
  components: {
    SearchOutline,
    CloneFormModel,
  },
  setup() {
    const {
      pagination,
      loadingTemplates,
      creatingForm,
      formCategories,
      formIndustries,
      formTemplates,
      filters,
      getFormTemplates,
      setFormTemplates,
      updateState,
      clearFilters,
      createFormFromTemplate,
    } = useFormTemplateChooser();

    const {
      getFormTemplateCategories,
    } = useFormTemplateCategory();

    const {
      getFormTemplateIndustries,
    } = useFormTemplateIndustry();

    return {
      cloneFormModel: ref({
        title: '',
        show: false,
        selected: null,
        cloning: false,
      }),

      pagination,
      creatingForm,
      loadingTemplates,
      formCategories,
      formIndustries,
      formTemplates,
      filters,
      getFormTemplateCategories,
      getFormTemplateIndustries,
      getFormTemplates,
      setFormTemplates,
      updateState,
      clearFilters,
      createFormFromTemplate,
    };
  },
  mounted() {
    this.loadTemplates();

    this.getFormTemplateCategories({}, (success, response) => {
      if (!success) {
        return;
      }

      this.updateState({ field: 'categories', value: response.data.result });
    });

    this.getFormTemplateIndustries({}, (success, response) => {
      if (!success) {
        return;
      }

      this.updateState({ field: 'industries', value: response.data.result });
    });
  },
  methods: {
    onLayoutChange(value) {
      this.updateState({ field: 'filters.layout', value }).then(() => {
        this.loadTemplates();
      });
    },
    onTypeChange(value) {
      this.updateState({ field: 'filters.type', value });
    },
    loadTemplates(loadMore) {
      const params = {
        page: 1,
      };

      if (loadMore && this.pagination.current_page < this.pagination.last_page) {
        params.page = this.pagination.current_page + 1;
      }

      if (this.filters.layout) {
        params.steps = this.filters.layout;
      }

      if (this.filters.search) {
        params.s = this.filters.search;
      }

      if (this.filters.categories.length > 0) {
        params.categories = this.filters.categories.join(',');
      }

      if (this.filters.industries.length > 0) {
        params.industries = this.filters.industries.join(',');
      }

      this.getFormTemplates({ params }, (success, response) => {
        if (!success) {
          return;
        }
        if (loadMore) {
          this.setFormTemplates({
            ...response.data,
            result: this.formTemplates.concat(response.data.result),
          });
        } else {
          this.setFormTemplates(response.data);
        }
      });
    },
    onSearch(value) {
      this.updateState({ field: 'filters.search', value })
        .then(() => this.loadTemplates());
    },
    onCategoryCheck(value, cat) {
      if (value && this.filters.categories.indexOf(cat.id) === -1) {
        this.filters.categories.push(cat.id);
      }

      if (!value && this.filters.categories.indexOf(cat.id) >= 0) {
        this.filters.categories.splice(this.filters.categories.indexOf(cat.id), 1);
      }

      this.updateState({
        field: 'filters.categories',
        value: this.filters.categories,
      })
        .then(() => this.loadTemplates());
    },
    onIndustryCheck(value, ind) {
      if (value && this.filters.industries.indexOf(ind.id) === -1) {
        this.filters.industries.push(ind.id);
      }

      if (!value && this.filters.industries.indexOf(ind.id) >= 0) {
        this.filters.industries.splice(this.filters.industries.indexOf(ind.id), 1);
      }

      this.updateState({
        field: 'filters.industries',
        value: this.filters.industries,
      })
        .then(() => this.loadTemplates());
    },
    clearAll() {
      this.clearFilters()
        .then(() => this.loadTemplates());
    },
    loadMore() {
      this.loadTemplates(true);
    },
    startScratch() {
      this.$router.push({
        name: this.routeNames.form.create,
      });
    },
    previewForm(formTemplate) {
      const formId = formTemplate.form_id;

      let apiUrl = `${process.env.VUE_APP_FORM_DOMAIN_URL}/${formId}`;
      if (process.env.NODE_ENV === 'production') {
        window.open(apiUrl, '_blank');

        return;
      }

      apiUrl = encodeURIComponent('http://coreformapi.test');

      window.open(`http://localhost:8082/?formId=${formId}&apiUrl=${apiUrl}`);
    },
    useTemplate(formTemplate) {
      this.cloneFormModel.show = true;
      this.cloneFormModel.selected = formTemplate;
    },
    cloneForm() {
      this.cloneFormModel.cloning = true;
      this.createFormFromTemplate({
        formTemplateId: this.cloneFormModel.selected.id,
        params: {
          title: this.cloneFormModel.title,
        },
      }, (success, response) => {
        this.cloneFormModel.cloning = false;
        this.cloneFormModel.show = false;
        if (!success) {
          console.log(response);

          return;
        }

        this.$router.push({
          name: this.routeNames.form.edit,
          params: { id: response.data.result.id },
        });
      });
    },
    cancelCloneForm() {
      this.cloneFormModel.cloning = false;
      this.cloneFormModel.selected = null;
      this.cloneFormModel.show = false;
    },
    updateCloneFormField(field) {
      this.cloneFormModel[field.name] = field.value;
    },
  },
  computed: {
    ...mapGetters(['routeNames']),
  },
};
</script>
