<template>
  <div class="formbuilder__preview__thankyou-msg">
    <n-divider dashed>Thankyou Message</n-divider>
    <div
      style="font-size: 12px;margin-bottom: 10px;"
      v-if="formBuilder.form?.enable_quiz === 1">
      <div>
        <strong>Note:</strong>
        Insert
        <strong class="theme-color">[quiz_score]</strong>
        field in the message to show quiz score in the thankyou message.
      </div>
    </div>
    <div
    :class="{
      formbuilder__preview__step: true,
    }">
      <!-- PROGRESS BAR TOP -->
      <div class="formbuilder__preview__step__progress top-position"
        @click="onProgressBarFocus"
        v-if="isProgressBarOnTop() && isProgressBarActive()">
        <FormProgressBar :percentage="100" />
      </div>
      <div class="formbuilder__preview__step__header"></div>
      <quill-editor
        :content="editorContent"
        v-on:focused="onFocus($event)"
        @textChanged="onChange"
        :ref="refId"/>
      <!-- PROGRESS BAR BOTTOM -->
      <div class="formbuilder__preview__step__progress bottom-position"
        @click="onProgressBarFocus"
        v-if="!isProgressBarOnTop() && isProgressBarActive()">
        <FormProgressBar :percentage="100"/>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import _ from 'lodash';
import QuillEditor from '@/components/QuillEditor/QuillEditor.vue';

// COMPONENETS
import FormProgressBar from '@/components/FormBuilder/Preview/FormProgressBar.vue';

// COMPOSABLES
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';

// CONSTANTS
import focusedEntityTypes from '@/shared/constants/formbuilder/focusedEntityTypes';

export default {
  props: {
    endingsControl: {
      type: Object,
      required: true,
    },
    endingsControlItem: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { focusEntity, formBuilder } = useFormBuilder();

    return {
      editorContent: ref(''),

      focusEntity,
      formBuilder,
    };
  },
  components: {
    QuillEditor,

    FormProgressBar,
  },
  mounted() {
    const control = _.find(this.endingsControlItem.controls, { name: 'thankyou_message' });
    this.editorContent = control.value;
  },
  methods: {
    onChange() {
      this.editorContent = this.$refs[this.refId].editor.root.innerHTML;

      const newControl = _.find(this.endingsControlItem.controls, { name: 'thankyou_message' });
      newControl.value = this.editorContent;

      this.focusEntity({
        title: 'Settings',
        type: focusedEntityTypes.FORM_GENERAL,
        value: null,
        section: 'after_submit_actions',
      });
      this.$store.dispatch('formBuilder/updateFocusedEntityControl', {
        panel: 'settingsPanel',
        control: this.endingsControl,
        value: this.endingsControl.items,
        field: 'items',
      });
    },
    onFocus(e) {
      e.stopPropagation();

      this.focusEntity({
        title: 'Settings',
        type: focusedEntityTypes.FORM_GENERAL,
        value: null,
        section: 'after_submit_actions',
      });
    },
    onProgressBarFocus(e) {
      e.stopPropagation();

      this.focusEntity({
        title: 'Settings',
        type: focusedEntityTypes.FORM_GENERAL,
        value: null,
        section: 'progress_bar',
      });
    },
    isProgressBarOnTop() {
      return _.find(
        this.formBuilder.general.settings.controls,
        { name: 'progress_bar_position' },
      ).value === 'top';
    },
    isProgressBarActive() {
      return _.find(
        this.formBuilder.general.settings.controls,
        { name: 'progress_bar_active' },
      ).value;
    },
  },
  computed: {
    refId() {
      return `quillThankyouMsgRef${this.endingsControlItem.id}`;
    },
  },
};
</script>
