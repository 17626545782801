<template>
   <n-layout class="template-chooser-layout">
    <n-layout-header bordered>
      <div class="container">
        <div class="relative">
          <div class="text-theme">
            <router-link :to="{name: routeNames.login}">
              <img class="logo" src="/images/logo.png">
            </router-link>
          </div>
          <router-link
            class="absolute right-2 top-0 mt-2"
            v-if="$route.name === routeNames.register"
            :to="routeNames.login">
            <n-button type="primary" ghost>Login</n-button>
          </router-link>
          <router-link
            class="absolute right-2 top-0 mt-2"
            v-if="$route.name === routeNames.login" :to="routeNames.register">
            <n-button type="primary" ghost>Sign Up</n-button>
          </router-link>
        </div>
      </div>
    </n-layout-header>
    <n-layout-content>
      <div class="form-template-chooser-view">
        <div class="form-edit-view__loader" v-if="false">
            <n-spin size="large" />
        </div>
        <FormTemplateChooser v-else />
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script>
import { mapGetters } from 'vuex';
// COMPONENTS
import FormTemplateChooser from '@/components/Form/FormTemplateChooser/FormTemplateChooser.vue';

export default {
  setup() {
  },
  components: {
    FormTemplateChooser,
  },
  mounted() {
  },
  computed: {
    ...mapGetters(['routeNames']),
  },
};
</script>
