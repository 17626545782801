import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';

// CONSTANTS
import focusedEntityTypes from '@/shared/constants/formbuilder/focusedEntityTypes';

export default function useFormBuilder() {
  // REFS
  const saveFormBuilderErrorMsg = ref('');
  const savingFormBuilder = ref(false);

  // STORE
  const store = useStore();

  // COMPUTED
  const formBuilder = computed(() => store.getters['formBuilder/formBuilder']);
  const isMultiStep = computed(() => formBuilder.value && formBuilder.value.steps.length > 1);
  const settingsPanel = computed(() => store.getters['formBuilder/settingsPanel']);
  const isEmptyFormBuilder = computed(() => {
    let empty = true;
    if (!formBuilder?.value) {
      return empty;
    }

    Object.values(formBuilder.value.steps).forEach((s) => {
      if (s.items.length > 0) {
        empty = false;
      }
    });

    return empty;
  });

  // METHODS
  const focusEntity = (data) => {
    store.dispatch('formBuilder/focusEntity', data);
  };

  const getStepItem = (stepItemId) => {
    const steps = store.getters['formBuilder/formBuilder']?.steps;

    if (!steps) {
      return null;
    }

    let stepItem = null;
    for (let i = 0; i < steps.length; i += 1) {
      stepItem = _.find(steps[i].items, { id: stepItemId });

      if (stepItem) {
        break;
      }
    }

    return stepItem;
  };

  const addStepItem = (step, element, order) => {
    const clonedElement = _.cloneDeep(element);
    clonedElement.id = store.getters['formBuilder/maxStepItemId'] + 1;

    store.dispatch('formBuilder/addStepItem', {
      stepId: step.id,
      item: clonedElement,
      order,
    });

    focusEntity({
      title: clonedElement.title,
      type: focusedEntityTypes.STEP_ITEM,
      value: clonedElement.id,
    });
  };

  const addStepItemOverItem = (step, element, overItem, position) => {
    const clonedElement = _.cloneDeep(element);
    clonedElement.id = store.getters['formBuilder/maxStepItemId'] + 1;

    store.dispatch('formBuilder/addStepItemOverItem', {
      stepId: step.id,
      item: clonedElement,
      overItem,
      position,
    });

    focusEntity({
      title: clonedElement.title,
      type: focusedEntityTypes.STEP_ITEM,
      value: clonedElement.id,
    });
  };

  const deleteStepItem = (stepId, stepItemId) => {
    store.dispatch('formBuilder/deleteStepItem', {
      stepId,
      stepItemId,
    }).then(() => {
      focusEntity(null);
    });
  };

  const duplicateStepItem = (stepId, stepItemId) => {
    store.dispatch('formBuilder/duplicateStepItem', {
      id: store.getters['formBuilder/maxStepItemId'] + 1,
      stepId,
      stepItemId,
    }).then(() => {
      focusEntity(null);
    });
  };

  const loadFormBuilderState = (formId, callback) => {
    store.dispatch(
      'formBuilder/getState',
      { formId },
    ).then((response) => {
      if (callback) {
        callback(true, response);
      }
      store.dispatch('formBuilder/setState', _.cloneDeep(response.data.result));
    }).catch((error) => {
      console.log(error);
      if (callback) {
        callback(false, error);
      }
    });
  };

  const saveFormBuilder = (successCb = null, errorCb = null) => {
    savingFormBuilder.value = true;
    saveFormBuilderErrorMsg.value = '';

    let action = 'formBuilder/saveState';

    if (formBuilder.value.form) {
      action = 'formBuilder/updateState';
    }

    store.dispatch(
      action,
      formBuilder.value,
    ).then((response) => {
      savingFormBuilder.value = false;
      if (successCb) {
        successCb(response.data.result);
      }
    }).catch((error) => {
      savingFormBuilder.value = false;
      saveFormBuilderErrorMsg.value = error.response.meta.error_message;
      if (errorCb) {
        errorCb();
      }
    });
  };

  const computeControlCondition = (condition, options = {}) => {
    if (!condition?.terms) {
      return true;
    }

    const matchValue = (controlValue, value, operator) => {
      switch (operator) {
        case '===':
          return controlValue === value;
        case '!==':
          return controlValue !== value;
        case '>':
          return controlValue > value;
        case '>=':
          return controlValue > value;
        case '<':
          return controlValue < value;
        case '<=':
          return controlValue < value;
        case 'in':
          return controlValue.indexOf(value) >= 0;
        case '!in':
          return controlValue.indexOf(value) <= -1;
        default:
          return false;
      }
    };

    const results = [];
    condition.terms.forEach((term) => {
      let contextData = null;
      let controlName = null;

      if (term.context.indexOf('form_general') >= 0) {
        contextData = formBuilder.value.general;
        controlName = term.context.replace('form_general.', '');
      } else if (term.context.indexOf('form_step_navigation') >= 0) {
        contextData = formBuilder.value.stepNavigation;
        controlName = term.context.replace('form_step_navigation.', '');
      } else if (term.context.indexOf('form_theme') >= 0) {
        contextData = formBuilder.value.theme;
        controlName = term.context.replace('form_theme.', '');
      } else {
        return;
      }

      const control = _.find(contextData.settings.controls, { name: controlName });
      if (!control) {
        return;
      }

      if (control.type === 'list' && options.itemId !== undefined) {
        const listItem = _.find(control.items, { id: options.itemId });
        if (!listItem) {
          return;
        }

        const listItemControl = _.find(listItem.controls, { name: term.control });
        if (!listItemControl) {
          return;
        }

        results.push(matchValue(listItemControl.value, term.value, term.operator));
      } else {
        results.push(matchValue(control.value, term.value, term.operator));
      }
    });

    return condition.relation === '||'
      ? results.indexOf(true) >= 0 : results.indexOf(false) <= -1;
  };

  const getThemeControl = (name) => _.find(formBuilder.value.theme.settings.controls, { name });

  const getThemeControlValue = (name, defaultValue = '') => getThemeControl(name)?.value || defaultValue;

  return {
    savingFormBuilder,
    saveFormBuilderErrorMsg,

    formBuilder,
    isMultiStep,
    settingsPanel,
    isEmptyFormBuilder,

    getStepItem,
    addStepItem,
    addStepItemOverItem,
    deleteStepItem,
    duplicateStepItem,
    focusEntity,
    saveFormBuilder,
    getThemeControl,
    getThemeControlValue,
    computeControlCondition,
    loadFormBuilderState,
  };
}
