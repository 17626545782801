import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export default function () {
  const store = useStore();

  // REFS
  const loadingForms = ref(false);
  const loadingFormsErrMsg = ref('');

  // COMPUTED
  const forms = computed(() => store.getters['form/forms']);
  const formsPagination = computed(() => store.getters['form/formsPagination']);

  // METHODS
  const loadForms = (data) => {
    loadingForms.value = true;

    store.dispatch('form/getForms', data).then((response) => {
      loadingForms.value = false;
      store.dispatch('form/setForms', response.data);
    }).catch((error) => {
      loadingForms.value = false;
      loadingFormsErrMsg.value = error.response.meta.error_message;
    });
  };

  const getForm = (data, callback) => {
    store.dispatch('form/getForm', data).then((response) => {
      if (callback) {
        callback(true, response.data);
      }
    }).catch((error) => {
      if (callback) {
        callback(false, { errorMessage: error.response.meta.error_message });
      }
    });
  };

  const getFieldMapping = (data, callback) => {
    store.dispatch('form/getFieldMapping', data).then((response) => {
      if (callback) {
        callback(true, response);
      }
    }).catch((error) => {
      if (callback) {
        callback(false, error.response);
      }
    });
  };

  return {
    loadingForms,
    forms,
    formsPagination,

    loadForms,
    getForm,
    getFieldMapping,
  };
}
