import axios from 'axios';
import localStorageFields from '@/shared/constants/localStorageFields';

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  timeout: 0,
  maxRedirects: 5,
});

instance.interceptors.request.use((config) => {
  const newConfig = { ...config };

  newConfig.headers.Authorization = `Bearer ${localStorage.getItem(localStorageFields.token)}`;

  return config;
}, (error) => Promise.reject(error));

export default instance;
