<template>
  <div class="login-view mt-20">
    <n-form
      :model="model"
      ref="formRef"
      :rules="rules"
      class="login-form">
      <div class="login-form__header">
        <h3>Log In</h3>
      </div>
      <n-form-item path="email" label="Email">
        <n-input
          v-model:value="model.email"
          type="email"
          :show-require-mark="true"
          placeholder=""/>
      </n-form-item>
      <n-form-item path="password" label="Password">
        <n-input
          v-model:value="model.password"
          type="password"
          :show-require-mark="true"
          show-password-on="click"
          :clearable="true"
          placeholder=""/>
      </n-form-item>
      <div class="flex justify-center">
        <n-button
          size="large"
          @click="submit"
          type="primary">
          Login
        </n-button>
        &nbsp;
        &nbsp;
        <n-button
          v-if="emailVerificationPending"
          size="large"
          @click="$router.push({name: routeNames.emailVerification.resend})"
          type="warning">
          Resend Verification Link
        </n-button>
      </div>
      <div class="login-form__footer">
        <p class="text-center">
          <span class="text-gray-400 text-sm">Don't have an account?</span>
          <router-link
            :to="{name: routeNames.register}" class="text-theme text-sm ml-1">Sign up</router-link>
        </p>
        <p>
          <router-link
            :to="{name: routeNames.forgotPassword.sendPasswordLink}"
            class="text-theme text-sm ml-1">Forgot Password</router-link>
        </p>
      </div>
    </n-form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useMessage } from 'naive-ui';
import { mapGetters } from 'vuex';

export default {
  setup() {
    const formRef = ref(null);
    const rPasswordFormItemRef = ref(null);
    const message = useMessage();
    const modelRef = ref({
      email: null,
      password: null,
    });

    return {
      message,
      formRef,
      rPasswordFormItemRef,
      emailVerificationPending: ref(false),
      model: modelRef,
      rules: {
        email: [
          {
            required: true,
            trigger: ['input'],
            message: 'Email is required',
          },
          {
            type: 'email',
            trigger: ['input'],
            message: 'Email address is invalid',
          },
        ],
        password: [
          {
            required: true,
            message: 'Password is required',
            trigger: ['input'],
          },
        ],
      },
    };
  },
  methods: {
    submit(e) {
      e.preventDefault();
      this.$refs.formRef.validate((errors) => {
        if (errors) {
          return;
        }

        this.login();
      });
    },
    login() {
      this.$store.dispatch('auth/login', this.model).then((response) => {
        this.$store.dispatch('auth/loginSuccess', response.data.result.token)
          .then(() => {
            this.$router.push({ name: 'dashboard' });
          });
      }).catch(({ response }) => {
        if (response.data.meta.error_type === this.errorCodes.EMAIL_NOT_VERIFIED.value) {
          this.emailVerificationPending = true;
        }

        this.message.error(
          response.data.meta.error_message || 'Something went wrong!',
        );
      });
    },
  },
  computed: {
    ...mapGetters([
      'routeNames',
      'errorCodes',
    ]),
  },
};
</script>
