<template>
  <div class="cf-list-control-cpt">
    <!-- CONTROL HEADER -->
    <div class="cf-list-control-cpt__header">
      <strong v-if="listControl.title">{{listControl.title}}</strong>
    </div>
    <!-- CONTROL BODY -->
    <div class="cf-list-control-cpt__body">
      <div id="cf-list-items">
        <div
          :class="{
            'cf-list-control-cpt__item': true,
            active: activeItem && activeItem.id === item.id
          }"
          v-for="item in sortedItems"
          :key="item.id"
          :data-id="item.id">
          <!-- ITEM HEADER -->
          <div class="cf-list-control-cpt__item__header">
            <div
              class="cf-list-control-cpt__item__header__title"
              @click="() => setActiveItem(item)">
              {{item.title}}
            </div>
            <div
              v-if="showAddItemBtn"
              class="cf-list-control-cpt__item__header__dup"
              @click="() => duplicateItem(item)">
              <n-icon size="20">
                <copy-outline />
              </n-icon>
            </div>
            <div
              class="cf-list-control-cpt__item__header__remove"
              @click="() => deleteItem(item)"
              v-if="items.length > 1 && item.removable !== false">
              <n-icon size="20">
                <close-circle-outline />
              </n-icon>
            </div>
          </div>
          <!-- ITEM BODY -->
          <div class="cf-list-control-cpt__item__body">
            <div
              v-for="control in item.controls" :key="control.id">
              <div :class="{hide: control.visible === false || !evaluateCondition(control, item)}">
                <div
                  v-if="control.type === controlTypes.TEXT">
                  <n-form-item
                    :label="control.title"
                    label-placement="left">
                    <n-input
                      size="small"
                      :value="control.value"
                      @update:value="(v) => updateItemControl(v, control, item)"
                    />
                  </n-form-item>
                </div>
                <div
                  v-if="control.type === controlTypes.SWITCH">
                  <n-form-item
                    :label="control.title"
                    label-placement="left">
                    <n-switch
                      size="small"
                      :value="control.value"
                      @update:value="(v) => updateItemControl(v, control, item)"
                    />
                  </n-form-item>
                </div>
                <div
                  v-if="control.type === controlTypes.TEXTAREA">
                  <n-form-item
                    :label="control.title"
                    label-placement="left">
                    <n-input
                      size="small"
                      :value="control.value"
                      @update:value="(v) => updateItemControl(v, control, item)"
                      type="textarea" />
                  </n-form-item>
                </div>
                <div
                  v-if="control.type === controlTypes.DATETIME">
                  <n-form-item
                    :label="control.title"
                    label-placement="left">
                    <n-date-picker
                      :value="control.value"
                      @update:value="(v) => updateItemControl(v, control, item)"
                      type="datetime"
                      clearable />
                  </n-form-item>
                </div>
                <div
                  v-if="control.type === controlTypes.NUMBER">
                  <n-form-item
                    :label="control.title"
                    label-placement="left">
                    <n-input-number
                      :min="control.min"
                      :max="control.max"
                      :value="control.value"
                      @update:value="(v) => updateItemControl(v, control, item)"
                      clearable
                    />
                  </n-form-item>
                </div>
                <div
                  v-if="control.type === controlTypes.SELECT">
                  <n-form-item
                    :label="control.title"
                    label-placement="left">
                    <n-select
                      :value="control.value"
                      :options="control.options"
                      @update:value="(v) => updateItemControl(v, control, item)"
                      clearable
                    />
                  </n-form-item>
                </div>
              </div>
              <div :class="{
                hide: control.show_help_text !== true
                || !!(control?.help_text?.length === 0)
                || !evaluateCondition(control, item)
              }">
                <n-gradient-text type="error">
                  {{control.help_text}}
                </n-gradient-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CONTROL FOOTER -->
    <div class="cf-list-control-cpt__footer">
      <n-button
        @click="() => addItem()"
        type="primary"
        v-if="showAddItemBtn"
        ghost>+ Add Item</n-button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import _ from 'lodash';
import Sortable from 'sortablejs';

import {
  CloseCircleOutline,
  CopyOutline,
} from '@vicons/ionicons5';

// CONSTANTS
import controlTypes from '@/shared/constants/formbuilder/controlTypes';

// COMPOSABLES
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';

export default {
  props: {
    listControl: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    CloseCircleOutline,
    CopyOutline,
  },
  mounted() {
    this.activeItem = this.items.length > 0 ? this.items[0] : null;
    this.initSortable();
  },
  setup() {
    const { computeControlCondition } = useFormBuilder();

    return {
      sortable: ref(null),
      activeItem: ref(null),
      controlTypes: ref(controlTypes),

      computeControlCondition,
    };
  },
  methods: {
    initSortable() {
      if (this.sortable instanceof Sortable) {
        this.sortable.destroy();
      }

      const el = document.getElementById('cf-list-items');
      this.sortable = new Sortable(el, {
        handle: '.cf-list-control-cpt__item__header__title',
        animation: 150,
        onEnd: () => {
          const items = [];
          this.sortable.toArray().forEach((itemId, index) => {
            const item = _.find(this.items, { id: Number(itemId) });
            items.push({ ...item, order: index + 1 });
          });

          this.$emit('updateItems', { items });
        },
      });
    },
    setActiveItem(item) {
      this.activeItem = item;
    },
    addItem() {
      const newItem = _.cloneDeep(this.items[0]);
      newItem.id = this.maxItemId + 1;
      newItem.order = this.maxPosition + 1;

      const newControls = [];
      newItem.controls.forEach((c) => {
        newControls.push({ ...c, value: c.default });
      });
      newItem.controls = newControls;

      this.$emit('addItem', { newItem });

      setTimeout(() => {
        const addedItem = _.find(this.items, { id: newItem.id });
        this.setActiveItem(addedItem);
      }, 10);
    },
    deleteItem(item) {
      this.$emit('deleteItem', { item });

      setTimeout(() => {
        this.setActiveItem(this.items[this.items.length - 1]);
      }, 10);
    },
    duplicateItem(item) {
      const newId = this.maxItemId + 1;
      this.$emit('duplicateItem', {
        item,
        newId,
        newPosition: item.order + 1,
      });

      setTimeout(() => {
        const dupItem = _.find(this.items, { id: newId });
        this.setActiveItem(dupItem);
      }, 10);
    },
    updateItemControl(value, control, item) {
      this.$emit(
        'updateItemControl',
        {
          value,
          control,
          item,
        },
      );
    },
    evaluateCondition(control, item) {
      return this.computeControlCondition(control.condition, { itemId: item.id });
    },
  },
  computed: {
    sortedItems() {
      return _.sortBy(this.items, (i) => i.order);
    },
    maxItemId() {
      if (!this.items) {
        return -1;
      }

      let maxId = -1;

      this.items.forEach((i) => {
        if (i.id > maxId) {
          maxId = i.id;
        }
      });

      return maxId;
    },
    maxPosition() {
      if (this.sortedItems.length === 0) {
        return 0;
      }

      return this.sortedItems[this.sortedItems.length - 1].order;
    },
    showAddItemBtn() {
      if (!this.listControl.list_max_length) {
        return true;
      }

      return this.items.length < this.listControl.list_max_length;
    },
  },
  watch: {
    items() {
      this.initSortable();
    },
  },
};
</script>
