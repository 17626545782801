<template>
  <div class="zapier-view">
    <div class="container">
      <h2><strong>Authenticating Zapier</strong></h2><br>
      <p class="text-sm">In order to connect your Zappier account with CoreForm Account
        you will need to create a zap e.g.
        If you want to send slack message on submitting Form then you should create a
        Zap of CoreForm and Slack app in Zapier and enter your CoreForm email and
        password in order to fetch forms of your account in Zapier while zap creation.
      </p><br><br>

      <h2><strong>Integrate with Zapier</strong></h2>
      <p class="text-sm">In order to integrate your forms with Zapier
        please click the button below.</p><br>
      <a href="https://zapier.com/apps/coreform/integrations" target="_blank">
        <n-button type="primary">Go To Zapier Integration</n-button>
      </a>
    </div>
  </div>
</template>

<style lang="scss">
.zapier-view {
  p {
    font-size: 14px;
    line-height: 1.8;
  }
}
</style>
