import FormThemeApi from '@/api/formTheme';

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  actions: {
    createFormTheme(context, data) {
      return FormThemeApi.save(data.formId, { config: data.config });
    },
    savingTheme(context, saving) {
      context.commit('savingTheme', saving);
    },
  },
  mutations: {
    savingTheme(state, saving) {
      state.savingTheme = saving;
    },
  },
};
