import axios from '@/api/axios';

const show = (data = {}) => axios
  .get(`/form-step-logics/${data.formId}`, { params: data?.params || {} });

const store = (data = {}) => axios
  .post(`/form-step-logics/${data.formId}`, data.data || {}, { params: data?.params || {} });

export default {
  show,
  store,
};
