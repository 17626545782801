import axios from '@/api/axios';

const getFormTemplates = (data) => axios
  .get('/form-templates', {
    params: data?.params || {},
  });

const getFormTemplate = (data) => axios
  .get(`/form-templates/${data.formTemplateId}`, {
    params: data?.params || {},
  });

const storeFormTemplate = (data = {}) => axios
  .post('/form-templates', data.data || {}, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: data?.params || {},
  });

const updateFormTemplate = (data = {}) => axios
  .post(`/form-templates/${data.formTemplateId}`, data.data || {}, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: data?.params || {},
  });

export default {
  getFormTemplate,
  getFormTemplates,
  storeFormTemplate,
  updateFormTemplate,
};
