import axios from '@/api/axios';

const create = () => axios.get('/formbuilder/create');
const save = (data) => axios.post('/formbuilder/store', data);
const edit = (formId) => axios.get(`formbuilder/${formId}/edit`);
const update = (data) => axios.put(`formbuilder/${data.form.id}/update`, data);

export default {
  create,
  save,
  edit,
  update,
};
