<template>
  <div class="register-view mt-20">
    <n-card v-if="showSuccessMsg" style="max-width:500px;margin-left: auto;margin-right:auto">
      <n-result
        status="success"
        title="Success"
        description="Please check your email inbox for verification link">
        <template #footer>
          <n-button @click="$router.push({name: routeNames.login})">Log In</n-button>
        </template>
      </n-result>
    </n-card>
    <n-form
      v-else
      :model="model"
      ref="formRef"
      :rules="rules" class="md:w-1/3 mx-auto border border-gray-20 p-5 rounded">
      <n-form-item path="firstName" label="First Name">
        <n-input
          v-model:value="model.firstName"
          type="string"
          :show-require-mark="true"
          placeholder=""/>
      </n-form-item>
      <n-form-item path="lastName" label="Last Name">
        <n-input
          v-model:value="model.lastName"
          type="string"
          :show-require-mark="true"
          placeholder=""/>
      </n-form-item>
      <n-form-item path="email" label="Email">
        <n-input
          v-model:value="model.email"
          type="email"
          :show-require-mark="true"
          placeholder=""/>
      </n-form-item>
      <n-form-item path="password" label="Password">
        <n-input
          v-model:value="model.password"
          type="password"
          :show-require-mark="true"
          show-password-on="click"
          :clearable="true"
          placeholder=""/>
      </n-form-item>
      <div class="flex justify-center">
        <n-button
          size="large"
          @click="submit"
          type="primary">
          Sign Up
        </n-button>
      </div>
      <div>
        <p class="text-center">
          <span class="text-gray-400 text-sm">Already have an account?</span>
          <router-link :to="routeNames.login" class="text-theme text-sm ml-1">Login</router-link>
        </p>
      </div>
    </n-form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useMessage } from 'naive-ui';
import { mapGetters } from 'vuex';

export default {
  setup() {
    const formRef = ref(null);
    const rPasswordFormItemRef = ref(null);
    const message = useMessage();
    const modelRef = ref({
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      googleRecaptchaToken: null,
    });

    return {
      message,
      formRef,
      rPasswordFormItemRef,
      model: modelRef,
      showSuccessMsg: ref(false),
      rules: {
        firstName: [
          {
            type: 'string',
            required: true,
            trigger: ['input'],
            message: 'First Name is required',
          },
        ],
        lastName: [
          {
            type: 'string',
            required: true,
            trigger: ['input'],
            message: 'Last Name is required',
          },
        ],
        email: [
          {
            required: true,
            trigger: ['input'],
            message: '%s Email is required',
          },
          {
            type: 'email',
            trigger: ['input'],
            message: '%s Email address is invalid',
          },
        ],
        password: [
          {
            required: true,
            message: 'Password is required',
            trigger: ['input'],
          },
        ],
      },
    };
  },
  methods: {
    submit(e) {
      e.preventDefault();

      this.$refs.formRef.validate((errors) => {
        if (errors) {
          return;
        }

        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'webapp_signup_customer' })
            .then((token) => {
              this.model.googleRecaptchaToken = token;
              this.register();
            });
        });
      });
    },
    register() {
      const data = {
        first_name: this.model.firstName,
        last_name: this.model.lastName,
        email: this.model.email,
        password: this.model.password,
        googleRecaptchaToken: this.model.googleRecaptchaToken,
      };

      this.$store.dispatch('user/signup', data).then((response) => {
        if (response.data.result.email_verification) {
          this.showSuccessMsg = true;
        } else if (response.data.result.token) {
          this.$store.dispatch('auth/loginSuccess', response.data.result.token)
            .then(() => {
              this.$router.push({ name: 'dashboard' });
            });
        } else {
          this.$router.push({ name: this.routeNames.login });
        }
      }).catch((error) => {
        this.message.error(error.response.data.meta.error_message || 'Something went wrong!');
      });
    },
  },
  computed: {
    ...mapGetters([
      'routeNames',
    ]),
  },
};
</script>
