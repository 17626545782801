import FormTemplateApi from '@/api/formTemplate';

export default {
  namespaced: true,
  state: {
    formTemplates: [],
    formTemplatesPagination: null,
  },
  getters: {
    formTemplates(state) {
      return state.formTemplates;
    },
  },
  actions: {
    getFormTemplates(_, data) {
      return FormTemplateApi.getFormTemplates(data);
    },
    getFormTemplate(_, data) {
      return FormTemplateApi.getFormTemplate(data);
    },
    setFormTemplates(context, data) {
      context.commit('setFormTemplates', data);
    },
    storeFormTemplate(context, data) {
      return FormTemplateApi.storeFormTemplate(data);
    },
    updateFormTemplate(context, data) {
      return FormTemplateApi.updateFormTemplate(data);
    },
  },
  mutations: {
    setFormTemplates(state, data) {
      state.formTemplates = data.result || [];
      state.formTemplatesPagination = data.pagination || null;
    },
  },
};
