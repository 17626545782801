<template>
  <div class="form-integration-select-cpt">
    <div class="form-integration-select-cpt__body">
      <div class="container">
        <div class="form-integration-select-cpt__integrations">
          <div
            class="form-integration-select-cpt__integration-item"
            v-for="ig in integrations"
            :key="ig.type">
            <img :src="ig.img" :title="ig.title" />
            <n-button type="primary" @click="() => addIntegration(ig)">{{ig.title}}</n-button>
            <div class="theme-color text-center" v-if="ig.upgrade" style="margin-top: 5px;">
              <router-link :to="{name: routeNames.plan.upgrade}">
                <n-tag :bordered="false" type="warning">
                  Upgrade Plan
                </n-tag>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formIntegrationTypes from '@/shared/constants/formIntegrationTypes';

// COMPOSABLES
import useGlobals from '@/composables/useGlobals';
import useAuth from '@/composables/useAuth';

export default {
  setup() {
    const {
      routeNames,
    } = useGlobals();

    const {
      hasWebhookFeature,
      toggleUpgradePlanModal,
    } = useAuth();

    return {
      routeNames,

      hasWebhookFeature,
      toggleUpgradePlanModal,
    };
  },
  methods: {
    addIntegration(ig) {
      if (ig.upgrade) {
        this.toggleUpgradePlanModal({ show: true });
        return;
      }

      this.$router
        .push({
          name: this.routeNames.form.integrations.create,
          params: {
            formId: this.$route.params.formId,
            type: ig.type,
          },
        });
    },
  },
  computed: {
    integrations() {
      return [
        {
          title: 'Add a Webhook',
          img: '/images/webhook.png',
          type: this.integrationTypes.webhook,
          upgrade: !this.hasWebhookFeature(),
        },
      ];
    },
    integrationTypes() {
      return formIntegrationTypes;
    },
  },
};
</script>
