<template>
  <div class="paddle-checkout-popup">
    <n-button
      :disabled="disabled"
      :loading="loading"
      type="primary"
      @click="openCheckout">{{btnText}}</n-button>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    loading: {
      required: false,
      default: false,
      type: Boolean,
    },
    btnText: {
      required: false,
      default: 'Subscribe',
      type: String,
    },
    paddlePlanId: {
      required: true,
      default: 'Subscribe',
      type: String,
    },
    user: {
      required: true,
      default: null,
      type: Object,
    },
    successCallback: {
      required: false,
      default: null,
      type: Function,
    },
    closeCallback: {
      required: false,
      default: null,
      type: Function,
    },
    beforeOpeningCheckout: {
      required: false,
      default: null,
      type: Function,
    },
  },
  mounted() {
    if (process.env.VUE_APP_ENV !== 'production') {
      window.Paddle.Environment.set('sandbox');
    }

    window.Paddle.Setup({ vendor: Number(process.env.VUE_APP_PADDLE_VENDOR_ID) });
  },
  methods: {
    openCheckout() {
      if (this.beforeOpeningCheckout) {
        if (!this.beforeOpeningCheckout({
          paddlePlanId: this.paddlePlanId,
        })) {
          return;
        }
      }

      window.Paddle.Checkout.open({
        product: this.paddlePlanId,
        email: this.user.email,
        passthrough: {
          user_id: this.user.id,
        },
        successCallback: (...args) => {
          if (this.successCallback) {
            this.successCallback(args);
          }
        },
        closeCallback: (...args) => {
          if (this.closeCallback) {
            this.closeCallback(args);
          }
        },
      });

      this.$emit('subscribing', {
        paddlePlanId: this.paddlePlanId,
      });
    },
  },
};
</script>
