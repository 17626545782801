import MediaApi from '@/api/media';

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  actions: {
    upload(_, data) {
      return MediaApi.upload(data);
    },
  },
  mutations: {
  },
};
