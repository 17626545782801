<template>
  <div class="formbuilder__appearance">
    <div class="formbuilder__appearance__header">
    </div>
    <div class="formbuilder__appearance__content">
      <focused-entity-element :element="currentElement" panel="appearancePanel" />
    </div>
    <div class="formbuilder__appearance__footer">
      <!-- <div>
        <n-button
          @click="() => saveTheme()"
          size="small"
          type="primary">Save As Theme</n-button>
      </div> -->
    </div>
  </div>
</template>
<script>
// COMPOSABLES
import useFormBuilder from '@/composables/formbuilder/useFormBuilder';
import useFormTheme from '@/composables/form/useFormTheme';

// COMPONENTS
import FocusedEntityElement from '@/components/FormBuilder/FocusedEntity/Types/FocusedEntityElement.vue';

// CONSTANTS
import focusedEntityTypes from '@/shared/constants/formbuilder/focusedEntityTypes';

export default {
  setup() {
    const { formBuilder } = useFormBuilder();
    const { saveFormTheme } = useFormTheme();

    return {
      formBuilder,

      saveFormTheme,
    };
  },
  components: {
    FocusedEntityElement,
  },
  methods: {
    saveTheme() {
      this.saveFormTheme();
    },
  },
  computed: {
    focusedEntity() {
      return this.$store.getters['formBuilder/focusedEntity']('appearancePanel');
    },
    currentElement() {
      if (this.focusedEntity.type === focusedEntityTypes.FORM_THEME) {
        return this.formBuilder.theme;
      }

      return null;
    },
  },
};
</script>
