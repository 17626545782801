<template>
  <n-config-provider :theme-overrides="themeOverrides" :hljs="hljs">
    <n-message-provider>
      <n-dialog-provider>
        <router-view/>
        <!-- GLOBAL MODAL -->
        <upgrade-plan-modal
          :show="upgradePlanModal.show"
          @show="onUpgradePlanModalShowChange"/>
      </n-dialog-provider>
    </n-message-provider>
  </n-config-provider>
</template>

<script>
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';

// COMPOSABLES
import useAuth from '@/composables/useAuth';

// COMPONENTS
import UpgradePlanModal from '@/components/Upgrade/UpgradePlanModal.vue';

hljs.registerLanguage('javascript', javascript);

export default {
  setup() {
    const {
      refreshToken,
      isLoggedIn,
      upgradePlanModal,
      toggleUpgradePlanModal,
    } = useAuth();

    return {
      isLoggedIn,
      refreshToken,
      upgradePlanModal,
      toggleUpgradePlanModal,
    };
  },
  components: {
    UpgradePlanModal,
  },
  data() {
    return {
      themeOverrides: {
        common: {
          primaryColor: '#46c3c3',
          primaryColorHover: '#46c3c3',
          primaryColorPressed: '#46c3c3',
        },
      },
      hljs,
    };
  },
  mounted() {
    if (this.isLoggedIn) {
      this.refreshToken();
    }

    setInterval(() => {
      if (this.isLoggedIn) {
        this.refreshToken();
      }
    }, 1000 * 60);
  },
  methods: {
    onUpgradePlanModalShowChange(show) {
      this.toggleUpgradePlanModal({ show });
    },
  },
};
</script>

<style lang="scss">
@import './assets/stylesheets/main.scss';
</style>
