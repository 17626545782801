<template>
  <n-layout class="front-layout">
    <n-layout-header bordered>
      <div class="container">
        <div class="relative p-3">
          <div class="text-theme">
            <router-link :to="{name: routeNames.login}">
              <img class="logo" src="/images/logo.png">
            </router-link>
          </div>
          <router-link
            class="absolute right-2 top-0 mt-2"
            v-if="$route.name === routeNames.register"
            :to="routeNames.login">
            <n-button type="primary" ghost>Login</n-button>
          </router-link>
          <router-link
            class="absolute right-2 top-0 mt-2"
            v-if="$route.name === routeNames.login" :to="routeNames.register">
            <n-button type="primary" ghost>Sign Up</n-button>
          </router-link>
        </div>
      </div>
    </n-layout-header>
    <n-layout-content>
      <router-view></router-view>
    </n-layout-content>
    <n-layout-footer bordered>
      <div class="container">
        <div class="copyright">
          <div>©{{(new Date()).getFullYear()}} CoreForm. All Rights Reserved</div>
          <div>
              <a href="https://coreform.io" target="_blank">Site</a>&nbsp;&nbsp;
              <a href="https://coreform.io/terms-and-conditions" target="_blank">Terms Of Use</a>&nbsp;&nbsp;
              <a href="https://coreform.io/privacy-policy" target="_blank">Privacy Policy</a>&nbsp;&nbsp;
              <a href="https://coreform.io/refund-policy" target="_blank">Refund Policy</a>
          </div>
        </div>
      </div>
    </n-layout-footer>
  </n-layout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'routeNames',
    ]),
  },
};
</script>
