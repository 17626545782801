<template>
  <div class="zapier-view">
    <div class="container">
      <h2><strong>API Endpoints</strong></h2><br>
      <p class="text-sm">
        This page provides all the API Endpoints
        used for integrating with Zapier Integration.
      </p>
      <br><br>
      <div class="apiendpoint">
        <h4>Login (Use this endpoint for authentication)</h4><br>
        <p>URL: https://apI.coreform.io/api/login</p>
        <p>METHOD: POST </p>
        <p>Request Body: {"username": "test@example.com", password: "your-password"}</p>
        <p>Response Body:
          {
              "meta": {
                  "code": 200
              },
              "result": {
                  "token": "123"
              }
          }</p>
      </div>
      <div class="apiendpoint">
        <h4>Zapier Subscribe (Use this endpoint for subscribing Lead Created Trigger)</h4><br>
        <p>URL: https://apI.coreform.io/api/zapier/subscribe</p>
        <p>METHOD: POST </p>
        <p>Request Body: {"hook_url": "string", hook_type: "leads", "form_id": "string"}</p>
        <p>Response Body:
          {
            "zap_subscription_id": "string",
          }</p>
      </div>
      <div class="apiendpoint">
        <h4>Zapier Unsubscribe (Use this endpoint for subscribing Lead Created Trigger)</h4><br>
        <p>URL: https://apI.coreform.io/api/zapier/unsubscribe</p>
        <p>METHOD: POST </p>
        <p>Request Body:
          {
            "hook_url": "string",
            hook_type: "leads",
            "form_id": "string",
            "subscribe_data": "{\"zap_subscription_id\": \"string\"}"
          }
        </p>
        <p>Response Status: 200</p>
      </div>
      <div class="apiendpoint">
        <h4>List Of Forms in CoreForm Account
          (Use this endpoint for fetching forms for Form Trigger)</h4><br>
        <p>URL: https://apI.coreform.io/api/zapier/forms</p>
        <p>METHOD: GET </p>
        <p>Response Body:
          [
            {
              "id" : "123",
              "title": "Form title"
            }
          ]
        </p>
        <p>Response Status: 200</p>
      </div>
      <div class="apiendpoint">
        <h4>List Of Leads of a Form in CoreForm Account
          (Use this endpoint for fetching leads)</h4><br>
        <p>URL: https://apI.coreform.io/api/zapier/forms/{form id}/leads</p>
        <p>METHOD: GET </p>
        <p>Response Body:
          [
            {
              "id" : "123",
              "response": [{}],
              "created": "ISO datetime"
            }
          ]
        </p>
        <p>Response Status: 200</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.zapier-view {
  p {
    font-size: 14px;
    line-height: 1.8;
  }

  .apiendpoint {
    background: lightgrey;
    padding: 8px 15px;
    border-radius: 8px;
    margin-top: 15px;
  }
}
</style>
