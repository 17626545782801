export default {
  EQUAL: 'eq',
  NOT_EQUAL: 'not_eq',
  CONTAINS: 'contains',
  NOT_CONTAINS: 'not_contains',
  GREATER_THAN: 'gt',
  GREATER_OR_EQUAL: 'gte',
  LESS_THAN: 'lt',
  LESS_OR_EQUAL: 'lte',
};
