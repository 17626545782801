import { useStore } from 'vuex';

export default function () {
  const store = useStore();

  // METHODS.
  const unsubscribe = (data, callback) => {
    store.dispatch('subscription/unsubscribe', data)
      .then((response) => {
        if (callback) {
          callback(true, response);
        }
      }).catch((error) => {
        console.log(error);

        if (callback) {
          callback(false, error.response);
        }
      });
  };

  const subscribe = (data, callback) => {
    store.dispatch('subscription/subscribe', data)
      .then((response) => {
        if (callback) {
          callback(true, response);
        }
      }).catch((error) => {
        console.log(error);

        if (callback) {
          callback(false, error.response);
        }
      });
  };

  return {
    unsubscribe,
    subscribe,
  };
}
