import UserApi from '@/api/signup';

const state = {};

const getters = {};

const actions = {
  signup(_, data) {
    return UserApi.signup(data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
