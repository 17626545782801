<template>
  <div class="single-select-step-item">
    <n-form-item
      :show-require-mark="getControlValue('required', false)"
      :label="getControlValue('label')">
      <div
        class="formbuilder__preview__step-item__item__desc"
        v-if="getControlValue('description', '').length > 0">
        {{getControlValue('description')}}
      </div>
      <div v-if="skin.value === 'select'" style="width:100%;">
        <n-select v-model:value="model" :options="selectSkinOptions" clearable />
      </div>
      <div style="width:100%;" v-else>
        <n-radio-group v-model:value="model" name="radiogroup">
          <n-space vertical>
            <n-radio
              v-for="item in sortedItems"
              :key="item.id"
              :value="getItemControl(item, 'label').value">
              {{ getItemControl(item, 'label').value }}
            </n-radio>
          </n-space>
        </n-radio-group>
      </div>
    </n-form-item>
  </div>
</template>

<script>
import _ from 'lodash';
import { ref } from 'vue';

export default {
  props: {
    stepItem: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      model: ref(''),
    };
  },
  methods: {
    getControl(name) {
      return _.find(this.stepItem.settings.controls, { name });
    },
    getControlValue(name, defaultValue = '') {
      return _.find(this.stepItem.settings.controls, { name })?.value || defaultValue;
    },
    getItemControl(item, controlName) {
      return _.find(item.controls, { name: controlName });
    },
  },
  computed: {
    sortedItems() {
      const { items } = this.getControl('choices');

      return _.sortBy(items, (i) => i.order);
    },
    skin() {
      return _.find(this.stepItem.settings.controls, { name: 'skin' });
    },
    selectSkinOptions() {
      return this.sortedItems.map((item) => ({
        label: this.getItemControl(item, 'label').value,
        value: item.id,
      }));
    },
  },
  watch: {
    selectSkinOptions() {
      this.model = '';
    },
  },
};
</script>
